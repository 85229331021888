//
// Widgets / Tasks
// --------------------------------------------------

@widget-tasks-vpadding: 8px;


.widget-tasks {
  .task {
    padding: @widget-tasks-vpadding 0;
    position: relative;
    background: #fff;
    .clearfix();

    .action-checkbox,
    .task-title {
      display: block;
      line-height: 21px;
    }

    .action-checkbox {
      float: left;
      width: 20px;

      > label,
      > input {
        display: inline-block;
        margin: 0;
        position: relative;
      }

      > label,
      > input {
        vertical-align: middle;
      }

      > input,
      > label {
        top: -1px;
      }

    }

    .task-title {
      color: #444;
      text-decoration: none;
      float: none;
      overflow: hidden;

      span {
        display: inline-block;
        color: #aaa;
        margin-left: 5px;
        font-size: 12px;
        font-style: italic;
      }
    }

    &.completed a {
      color: #999;
      text-decoration: line-through;

      span {
        display: none;
      }
    }

    .label {
      display: block;
      position: absolute;
      right: 0;
    }

    .task-sort-icon {
      color: #bbb;
      cursor: move;
      display: block;
      float: left;
      position: relative;
      margin: -@widget-tasks-vpadding 6px -@widget-tasks-vpadding 0;
      padding: @widget-tasks-vpadding 12px;
      line-height: 21px;
    }
  }

  .task + .task {
    border-top: 1px solid;
    border-top-color: @table-border-color;
  }

  .task.ui-sortable-helper {
    background: #f1f6f7;
  }
}

.panel.widget-tasks .task,
.panel .widget-tasks .task {
  padding-left: 20px;
  padding-right: 20px;
  margin: 0 -20px;

  .label {
    right: @panel-content-padding;
  }

  .task-sort-icon {
    margin-left: -11px;
  }
}
