
label.checkbox {
  span.lbl {
    line-height: 18px;
    cursor: pointer;
  }
}

@input-height-xsmall: (floor(@font-size-small * @line-height-small) + (@padding-small-vertical * 2));

.input-xs {
  .input-size(@input-height-xsmall; @padding-small-vertical; @padding-small-horizontal; @font-size-small; @line-height-small; @border-radius-small);
}

select.input-xs {
  padding: 3px 5px 5px;
}