//
// Themes / Default / Demo
// --------------------------------------------------

#main-navbar .navbar-brand div,
.demo-logo {
  background: @primary-color;
}

#main-navbar .navbar-header a {
  text-transform: uppercase;
  font-size: 11px;
  font-weight: 600;
}
