//
// Pages / FAQ
//
// --------------------------------------------------

.page-faq {
  .accordion-toggle {
    color: @text-color;
  }

  .form-faq {
    margin-top: 18px;
  }
}