//
// Themes / Purple Hills / Main menu
// --------------------------------------------------

#main-menu-bg {
    // Body/Menu background
    background-color: @menu-color;
}

#main-menu {
    padding-top: 30px;

    .menu-logo {
        background: darken(@header-color, 5%);
        padding: 10px 0;
    }

    .navigation a,
    .mmc-dropdown-open-ul a {
        color: #b0b8be;
        position: relative;
        .transition(all .2s);

        &:after {
            content: "";
            top: 0;
            bottom: 0;
            width: 1px;
            position: absolute;
            right: auto;
            background: darken(@header-color, 5%);
            display: none;
            left: 0;
        }

        &:hover {
            color: #fff;

            &:after {
                display: block !important;
            }
        }
    }

    .navigation {
        .box-shadow(0 1px 0 #303a4a);

        li {
            border-top: 1px solid #2c3235;
            border-bottom: 1px solid #383e42;

            ul > li {
                border-bottom: none;
            }

            a {
                &:hover {
                    background: #2c3235;
                }
            }

            // Active nav item
            &.active {
                border-top: none;
                background: #242424;
                background: rgba(0, 0, 0, 0.15);
                .box-shadow(~"0px 1px 6px inset rgba(0, 0, 0, 0.25) !important");

                & > a,
                & > a:hover {
                    color: #75c6f3 !important;
                }
            }
        }
    }

    li.active > a {
        background: darken(@menu-color, 5%);
        color: #fff;

        & .menu-icon {
            color: @header-color;
        }

        &:after {
            display: block;
        }
    }

    .mm-dropdown.active {
        & > a {
            background: none;
        }

        & > a:after {
            display: none;
        }
    }

    .mm-dropdown > ul,
    .mmc-dropdown-open-ul {
        background: lighten(@menu-color, 3%);
    }

    .mmc-dropdown-open-ul .mmc-title {
        background: darken(@header-color, 5%);
        color: #fff;
    }

    .mmc-dropdown-open {
        > a {
            background: darken(@header-color, 5%) !important;

            &,
            .menu-icon {
                color: #fff !important;
            }

            &:hover:after {
                display: none !important;
            }
        }
    }

    // Main menu content blocks
    .menu-content {
        border-color: lighten(@menu-color, 5%);

        .btn-outline {
            border-color: lighten(@menu-color, 5%);
            color: lighten(@menu-color, 40%);

            &:hover {
                color: #fff;
            }
        }
    }
}

// Dropdown highlights
//

// Highlight opened dropdown
.mmc-dropdown-open .mm-dropdown.open > a,
&.mme #main-menu .mm-dropdown.open > a {
    color: #fff;
}

@media (min-width: @screen-tablet) {
    // Highlight expanded submenus
    #main-menu .mm-dropdown.open > a {
        color: #fff;
    }

    // Unhighlight expanded submenus in collapsed mode
    &.mmc #main-menu .navigation > .mm-dropdown.open > a {
        color: lighten(@menu-color, 40%);
    }
}

&.right-to-left {
    #main-menu .navigation a:after {
        left: auto;
        right: 0;
    }
}