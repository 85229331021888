//
// Pages / Timeline
//
// --------------------------------------------------


// ************************************************************************* //
//**   Small screens (mobile first)                                        **//

@tl-left-mobile: 30px; // Do not forget to change value for RTL-mode
@tl-left: 100px; // Do not forget to change value for RTL-mode
@tl-header-width: 140px;

// Timeline
//

.timeline {
  position: relative;
  padding-bottom: 30px;
  margin-bottom: 20px;

  &:before,
  &:after {
    content: "";
    display: block;
    position: absolute;
    left: @tl-left-mobile;
    bottom: 0;
  }

  &:before {
    width: 4px;
    top: 0;
    margin-left: -2px;
  }

  &:after {
    width: 12px;
    height: 12px;
    margin-left: -6px;
    border-radius: 999999px;
  }
}


// Header
//

.tl-header {
  position: relative;
  width: @tl-header-width;
  padding: 8px 0;
  text-align: center;
  left: 0;
  margin-left: 0;
  margin-top: 40px;
  margin-bottom: 40px;
  font-size: 14px;
  border-radius: @border-radius-base;
}

.tl-header.now {
  margin-top: 0;
}


// Entry
//

.tl-entry {
  margin-left: @tl-left-mobile;
  margin-right: 0;
  padding-left: 36px;
  padding-right: 0;
  position: relative;
  margin-bottom: 40px;
  .clearfix();

  &:before {
    content: "";
    display: block;
    position: absolute;
    width: 6px;
    height: 6px;
    left: -3px;
    right: auto;
    border-radius: 999999px;
    top: 20px;
  }
}


// Body
//

.tl-body {
  position: relative;
  padding: 15px;
  margin: 0;

  &:before {
    content: "";
    display: block;
    width: 0px;
    height: 0px;
    border-style: solid;
    border-width: 8px 9px 8px 0;
    position: absolute;
    left: -9px;
    right: auto;
    top: 15px;
  }

  &:after {
    content: "";
    display: block;
    width: 0px;
    height: 0px;
    border-style: solid;
    border-width: 7px 8px 7px 0;
    border-color: transparent #fff transparent transparent;
    position: absolute;
    left: -8px;
    right: auto;
    top: 16px;
  }
}


// Date/Time
//

.tl-time {
  position: absolute;
  left: auto;
  right: auto;
  margin-left: 0;
  margin-right: 0;
  text-align: right;
  white-space: nowrap;
  color: #888;
  top: -22px;
  font-size: 13px;
}


// Icon
//

.tl-icon {
  display: block;
  position: absolute;
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 999999px;
  left: -20px;
  right: auto;
  top: 4px;
  text-align: center;
  font-size: 14px;
  overflow: hidden;
}

.tl-icon img {
  height: 100%;
  display: block;
}


// Wide element
.tl-wide {
  margin: 0 -15px;
}


// ************************************************************************* //
//**   Desktops                                                            **//

@media (min-width: @screen-tablet) {
  
  // Default timeline
  //

  .timeline:before,
  .timeline:after {
    left: @tl-left;
  }
  .tl-header {
    left: @tl-left;
    margin-left: -1 * ceil(@tl-header-width / 2);
  }
  .tl-entry {
    margin-left: @tl-left;
    margin-bottom: 20px;
  }
  .tl-time {
    left: auto;
    right: 100%;
    margin-left: 0;
    margin-right: 32px;
    top: 14px;
  }


  // Centered timeline
  //

  .timeline.centered {
    &:before,
    &:after {
      left: 50%;
      right: auto;
    }
    .tl-header {
      left: 50%;
      right: auto;
    }
    .tl-entry {
      margin-left: 50%;
      margin-right: 0;
    }
    .tl-entry.left {
      margin-left: 0;
      margin-right: 50%;
      padding-left: 0;
      padding-right: 36px;
    }

    .tl-entry.left:before {
      left: auto;
      right: -3px;
    }
    .tl-entry.left .tl-body:before {
      border-width: 8px 0 8px 9px;
      left: auto;
      right: -9px;
    }
    .tl-entry.left .tl-body:after {
      border-width: 7px 0 7px 8px;
      border-color: transparent transparent transparent #fff;
      left: auto;
      right: -8px;
    }
    .tl-entry.left .tl-time {
      left: 100%;
      right: auto;
      margin-left: 32px;
      margin-right: 0;
    }
    .tl-entry.left .tl-icon {
      left: auto;
      right: -20px;
    }
  }
}