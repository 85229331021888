& {
  background-color: @body-bg;
  font-family: @font-family-base;
  font-size: @font-size-base;
  color: @text-color;
  background-color: transparent;
  -webkit-font-smoothing: antialiased;
  line-height: @line-height-base;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  font-weight: 400;
  color: #4B4D4F;
  text-rendering: optimizelegibility;
  font-family: @font-family-heading
}

h4, h5, h6 {
  line-height: 18px
}

h1 {
  font-size: 24px;
  line-height: normal
}

h2 {
  font-size: 20px;
  line-height: normal
}

h3 {
  line-height: normal;
  font-size: 18px
}

h4 {
  font-size: 14px
}

h5 {
  font-size: 12px
}

h6 {
  font-size: 11px;
  color: #999999;
  text-transform: uppercase
}
