//
// Widgets / Threads
// --------------------------------------------------

.widget-threads {
  .thread {
    padding: 0 0 14px 0;
    
    
    & + .thread {
      padding-top: 13px;
      border-top: 1px solid;
      border-top-color: @table-border-color;
    }

    &:last-child {
      padding-bottom: 0;
    }
  }

  .thread-avatar {
    display: block;
    float: left;
    height: 32px;
    margin-top: 2px;
    width: 32px;
    border-radius: 999999px;
  }

  .thread-body {
    margin-left: 42px;
  }

  .thread-time {
    float: right;
    font-size: 12px;
    color: #aaa;
  }

  .thread-info {
    color: #aaa;
    font-size: 12px;

    a {
      color: @text-color;

      &:hover {
        color: #888;
      }
    }
  }
}

.panel.widget-threads,
.panel .widget-threads {
  .thread {
    margin: 0 -@panel-content-padding;
    padding-left: @panel-content-padding;
    padding-right: @panel-content-padding;
  }
}
