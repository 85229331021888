//
// Tables
// --------------------------------------------------

// Tables
//

.reload {
  .table {
    display: none;
  }
}

.table {
  // Cells
  thead,
  tbody,
  tfoot {
    > tr > th,
    > tr > td {
      border-top-color: @table-border-color;
      color: #6c7680;

      a {
        color: #6c7680;
        font-weight: 600;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    > tr > td {
      padding-top: 10px;
      padding-bottom: 10px;
    }

    > tr:first-child > td {
      border-top: none;
    }

    > tr > td:first-child {
      padding-left: 20px;
    }

    .px-single {
      vertical-align: middle;
      display: block;
      padding-top: 2px;
    }

    > tr > td:last-child {
      padding-right: 20px;
    }

    > tr > th.clean {
      font-weight: 600;
      .pixel-gradient(#f4f4f4, #efefef, #f7f7f7);
      color: #9b9b9b;
      font-size: 11px;
      line-height: 20px;
    }

    > tr > th {
      font-weight: 600;
      background: #f9f9f9;
      color: #999999;
      font-size: 11px;
      line-height: 20px;
      text-shadow: 0 2px 2px #ffffff;
    }
  }

  // Account for multiple tbody instances
  tbody + tbody {
    border: 1px solid @table-border-color;
  }

  thead > tr {
    -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    border: none;
  }
}

.table-hover {
  > tbody > tr:hover {
    > td,
    > th {
      background-color: #f2fbed;
    }
  }
}

.table-bordered {
  border-color: @table-border-color;
  border-top: none;

  > thead,
  > tbody,
  > tfoot {
    > tr > th,
    > tr > td {
      border-color: @table-border-color;
    }

    > tr > th {
      border-top: none;
      border-bottom: 1px solid @table-border-color;
    }

    > tr > th:first-child,
    > tr > td:first-child {
      border-left: none;
    }

    > tr:first-child td {
      //box-shadow: inset 0px 5px 8px -16px rgba(220, 220, 220, 1);

    }

    > tr > td:first-child {
      padding-left: 10px;
    }

    > tr > th:last-child,
    > tr > td:last-child {
      border-right: none;
    }

    > tr:last-child {
      border-bottom: none;
    }
    > tr:last-child > td {
      border-bottom: none;
    }
  }

  > tbody > td {
    -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box
  }

  > tfoot > th, > tfoot > td, > tfoot > tr {
    border: none;
    padding: 0;
  }
}

.table-input {
  > thead,
  > tbody,
  > tfoot {
    > tr > td {
      padding-top: 0px;
      padding-bottom: 0px;
    }

    > tr > td:first-child {
      padding-left: 0px;
    }
  }
}

.table.loading {
  position: relative;

  tbody > tr > td {
    opacity: 0.5;
  }

}

.ng-table th {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.ng-table th.sortable {
  cursor: pointer;
  text-align: left;
}

.ng-table th.sortable div {
  padding-right: 18px;
  position: relative;
}

.ng-table th.sortable div:after,
.ng-table th.sortable div:before {
  content: "";
  border-width: 0 4px 4px;
  border-style: solid;
  border-color: #000 transparent;
  visibility: visible;
  right: 8px;
  top: 50%;
  position: absolute;
  opacity: .3;
  margin-top: -4px;
}

.ng-table th.sortable div:before {
  margin-top: 2px;
  border-bottom: none;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid #000;
}

.ng-table th.sortable div:hover:after,
.ng-table th.sortable div:hover:before {
  opacity: 1;
  visibility: visible;
}

.ng-table th.sortable.sort-desc,
.ng-table th.sortable.sort-asc {
  background-color: darken(#f4f4f4, 1%);
  text-shadow: 0 1px 1px rgba(255, 255, 255, 0.75);
}

.ng-table th.sortable.sort-desc div:after,
.ng-table th.sortable.sort-asc div:after {
  margin-top: -2px;
}

.ng-table th.sortable.sort-desc div:before,
.ng-table th.sortable.sort-asc div:before {
  visibility: hidden;
}

.ng-table th.sortable.sort-asc div:after,
.ng-table th.sortable.sort-asc div:hover:after {
  visibility: visible;
  filter: alpha(opacity=60);
  -khtml-opacity: 0.6;
  -moz-opacity: 0.6;
  opacity: 0.6;
}

.ng-table th.sortable.sort-desc div:after {
  border-bottom: none;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid #000;
  visibility: visible;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  filter: alpha(opacity=60);
  -khtml-opacity: 0.6;
  -moz-opacity: 0.6;
  opacity: 0.6;
}



.indicator {
  background: none;
  vertical-align: middle;

  &::before {
    content: '';
    display: inline-block;
    height: 8px;
    width: 8px;
    border-radius: 8px;
    margin: 0 4px 0 0px;
    background: #b0b0b0;
  }

  &.default::before, &.draft::before, &.unpaid::before, &.new::before {
    background: #b0b0b0;
  }

  &.fulfilled::before, &.success::before, &.approved::before, &.invoiced::before, &.paid::before, &.delivered:before, &.submited:before, &.completed:before, &.finished:before {
    background: @success-color;
  }

  &.warning::before, &.partial::before, &.production:before, &.packed:before, &.ready:before {
    background: @warning-color;
  }

  &.danger::before, &.rejected::before, &.canceled::before, &.returned:before {
    background: @danger-color;
  }

  &.primary::before, &.active::before, &.published::before, &.picking:before, &.shipping:before, &.shipped:before, &.onprogress:before {
    background: @primary-color;
  }
}

.indicator-right {
  &:extend(.theme-fortunix .indicator);

  &::after {
    &:extend(.theme-fortunix .indicator::before);
    margin: 0 0 0 4px;
  }

  &.default::after {
    &:extend(.theme-fortunix .indicator.default::before);
  }

  &.success::after {
    &:extend(.theme-fortunix .indicator.success::before);
  }

  &.warning::after {
    &:extend(.theme-fortunix .indicator.warning::before);
  }

  &.danger::after {
    &:extend(.theme-fortunix .indicator.danger::before);
  }

  &.primary::after {
    &:extend(.theme-fortunix .indicator.primary::before);
  }
}



