.widget-support-tickets .ticket {
  padding: 0 0 14px 0;
}
.widget-support-tickets .ticket + .ticket {
  padding-top: 13px;
  border-top: 1px solid;
  border-top-color: #e4e4e4;
}
.widget-support-tickets .ticket:last-child {
  padding-bottom: 0;
}
.widget-support-tickets a.ticket-title {
  display: block;
  font-size: 14px;
  padding: 0 90px 0 0;
  line-height: 20px;
}
.widget-support-tickets a.ticket-title i {
  display: inline-block;
  font-size: 11px;
  margin: 0 5px 0 0;
  top: -1px;
  position: relative;
  text-decoration: none;
}
.widget-support-tickets a.ticket-title span {
  display: inline-block;
  color: #aaa;
  padding-left: 4px;
  text-decoration: none !important;
}
.widget-support-tickets .ticket-label {
  display: block;
  float: right;
  font-size: 10px;
  line-height: 18px;
  margin: 2px 0 0 0;
  padding: 0 5px;
  position: relative;
}
.widget-support-tickets .ticket-info {
  color: #aaa;
  font-size: 12px;
}
.widget-support-tickets .ticket-info a {
  color: #555;
}
.widget-support-tickets .ticket-info a:hover {
  color: #888;
}
.panel.widget-support-tickets .ticket,
.panel .widget-support-tickets .ticket {
  margin: 0 -20px;
  padding-left: 20px;
  padding-right: 20px;
}
.widget-comments .comment {
  padding: 0 0 10px 0;
}
.widget-comments .comment + .comment {
  padding-top: 10px;
  border-top: 1px solid;
  border-top-color: #e4e4e4;
}
.widget-comments .comment:last-child {
  padding-bottom: 0;
}
.widget-comments .comment-avatar {
  display: block;
  float: left;
  height: 32px;
  margin-top: 5px;
  width: 32px;
  border-radius: 99999px;
}
.widget-comments .comment-body {
  margin-left: 45px;
}
.widget-comments .comment-by,
.widget-comments .actions {
  color: #aaa;
  display: block;
}
.widget-comments .comment-by {
  font-style: italic;
}
.widget-comments .comment-by a {
  font-style: normal;
}
.widget-comments .comment-text {
  padding: 4px 0;
}
.widget-comments .comment-actions i {
  display: inline-block;
  margin-right: 5px;
  text-decoration: none;
}
.widget-comments .comment-actions,
.widget-comments .comment-actions a {
  color: #aaa;
  font-size: 12px;
}
.widget-comments .comment-actions a {
  margin-right: 10px;
  display: inline-block;
}
.widget-comments .comment-actions a:hover {
  color: #555;
}
.panel.widget-comments .comment,
.panel .widget-comments .comment {
  margin: 0 -20px;
  padding-left: 20px;
  padding-right: 20px;
}
.widget-article-comments .comment {
  padding-top: 13px;
}
.widget-article-comments .comment:first-child {
  padding-top: 0;
}
.widget-article-comments .comment > .comment {
  margin-left: 26px !important;
}
.widget-article-comments .comment-avatar {
  border-radius: 999999px;
  display: block;
  float: left;
  height: 32px;
  position: relative;
  width: 32px;
}
.widget-article-comments .comment-heading {
  font-size: 12px;
  padding-bottom: 2px;
}
.widget-article-comments .comment-heading span {
  color: #aaa;
  display: inline-block;
  font-size: 11px;
  margin-left: 5px;
}
.widget-article-comments .comment-body {
  float: none;
  margin-left: 46px;
  position: relative;
}
.widget-article-comments .comment-body:before {
  border-color: transparent #e4e4e4 transparent transparent;
  border-style: solid;
  border-width: 6px 6px 6px 0;
  content: "";
  display: block;
  height: 0px;
  left: -6px;
  position: absolute;
  top: 11px;
  width: 0px;
}
.widget-article-comments .comment-text {
  border: 1px solid #e4e4e4;
  border-radius: 2px;
  padding: 7px 12px 8px 12px;
}
.widget-article-comments .comment-footer {
  display: block;
  font-size: 12px;
  padding: 4px 12px 0 12px;
}
.widget-article-comments .comment-footer,
.widget-article-comments .comment-footer a {
  color: #aaa;
}
.widget-article-comments .comment-footer a:hover {
  color: #555;
}
.widget-article-comments .comment-footer i {
  font-size: 13px;
}
.panel.widget-article-comments .comment,
.panel .widget-article-comments .comment {
  margin: 0 -20px;
  padding-left: 20px;
  padding-right: 20px;
}
.widget-threads .thread {
  padding: 0 0 14px 0;
}
.widget-threads .thread + .thread {
  padding-top: 13px;
  border-top: 1px solid;
  border-top-color: #e4e4e4;
}
.widget-threads .thread:last-child {
  padding-bottom: 0;
}
.widget-threads .thread-avatar {
  display: block;
  float: left;
  height: 32px;
  margin-top: 2px;
  width: 32px;
  border-radius: 999999px;
}
.widget-threads .thread-body {
  margin-left: 42px;
}
.widget-threads .thread-time {
  float: right;
  font-size: 12px;
  color: #aaa;
}
.widget-threads .thread-info {
  color: #aaa;
  font-size: 12px;
}
.widget-threads .thread-info a {
  color: #555;
}
.widget-threads .thread-info a:hover {
  color: #888;
}
.panel.widget-threads .thread,
.panel .widget-threads .thread {
  margin: 0 -20px;
  padding-left: 20px;
  padding-right: 20px;
}
.widget-chat .message + .message {
  padding-top: 15px;
}
.widget-chat .message-avatar {
  display: block;
  float: left;
  height: 40px;
  width: 40px;
  border-radius: 2px;
}
.widget-chat .message-body {
  margin-left: 55px;
}
.widget-chat .message-heading {
  font-size: 12px;
  color: #aaa;
}
.widget-chat .message-text {
  background: #f4f4f4;
  margin-top: 4px;
  padding: 10px 15px 12px 15px;
  border-radius: 2px;
  position: relative;
}
.widget-chat .message-text:before {
  border-color: transparent #f4f4f4 transparent transparent;
  border-style: solid;
  border-width: 6px 6px 6px 0;
  content: "";
  display: block;
  height: 0px;
  left: -6px;
  position: absolute;
  top: 6px;
  width: 0px;
}
.widget-chat .message.right .message-avatar {
  float: right;
}
.widget-chat .message.right .message-body {
  margin-left: 0;
  margin-right: 55px;
}
.widget-chat .message.right .message-text:before {
  border-color: transparent transparent transparent #f4f4f4;
  border-width: 6px 0 6px 6px;
  left: auto;
  right: -6px;
}
.widget-chat .chat-controls {
  width: 100%;
  position: relative;
}
.widget-chat .chat-controls .chat-controls-input {
  margin-right: 90px;
}
.widget-chat .chat-controls .chat-controls-btn {
  position: absolute;
  right: 20px;
  width: 80px;
  top: 10px;
}
.panel.widget-chat .message,
.panel .widget-chat .message {
  margin: 0 -20px;
  padding-left: 20px;
  padding-right: 20px;
}
.widget-profile .panel-heading {
  padding-top: 18px;
  padding-bottom: 16px;
  overflow: hidden;
  position: relative;
}
.widget-profile .panel-heading:before,
.widget-profile .panel-heading:after {
  content: " ";
  display: table;
}
.widget-profile .panel-heading:after {
  clear: both;
}
.widget-profile .widget-profile-avatar {
  width: 52px;
  height: 52px;
  border-radius: 999999px;
  float: left;
  margin-right: 12px;
}
.widget-profile .widget-profile-header {
  float: none;
  overflow: hidden;
  position: relative;
  margin-top: 3px;
}
.widget-profile .widget-profile-header span {
  font-size: 18px;
  font-weight: 300;
  display: inline-block;
}
.widget-profile .widget-profile-bg-icon {
  bottom: -23px;
  width: 100%;
  right: 5px;
  text-align: right;
  position: absolute;
}
.widget-profile .widget-profile-bg-icon > * {
  font-size: 100px;
  color: rgba(255, 255, 255, 0.3);
}
.widget-profile .widget-profile-counters {
  text-align: center;
}
.widget-profile .widget-profile-counters > div {
  padding: 15px 0;
  font-size: 11px;
}
.widget-profile .widget-profile-counters > div span {
  font-size: 18px;
  font-weight: 700;
  color: #555;
}
.widget-profile .widget-profile-counters > div + div {
  border-left: 1px solid #e4e4e4;
}
.widget-profile .widget-profile-input {
  border: none;
  border-radius: 0;
}
.widget-profile .widget-profile-text {
  padding: 10px 15px;
}
.widget-profile .widget-profile-counters + .widget-profile-input,
.widget-profile .widget-profile-counters + .widget-profile-text,
.widget-profile .widget-profile-input + .widget-profile-counters,
.widget-profile .widget-profile-input + .widget-profile-text,
.widget-profile .widget-profile-text + .widget-profile-input,
.widget-profile .widget-profile-text + .widget-profile-counters {
  border-top: 1px solid #e4e4e4 !important;
}
.panel-dark .widget-profile .widget-profile-header,
.panel-dark.widget-profile .widget-profile-header {
  color: #fff;
  color: rgba(255, 255, 255, 0.8);
}
.panel-dark .widget-profile .widget-profile-header a,
.panel-dark.widget-profile .widget-profile-header a {
  color: #fff;
  color: rgba(255, 255, 255, 0.8);
}
.panel-dark .widget-profile .widget-profile-header a:hover,
.panel-dark.widget-profile .widget-profile-header a:hover {
  color: #fff;
}
.panel-dark .widget-profile .widget-profile-header span,
.panel-dark.widget-profile .widget-profile-header span {
  color: #fff;
}
.widget-profile.widget-profile-centered {
  text-align: center;
}
.widget-profile.widget-profile-centered .widget-profile-avatar {
  width: 70px;
  height: 70px;
  float: none;
  margin: 0 auto 8px auto;
  display: block;
}
.ie8 .widget-profile-bg-icon {
  display: none !important;
}
.widget-messages .message {
  padding: 9px 0;
  position: relative;
}
.widget-messages .message:before,
.widget-messages .message:after {
  content: " ";
  display: table;
}
.widget-messages .message:after {
  clear: both;
}
.widget-messages .message > * {
  line-height: 19px;
}
.widget-messages .message .action-checkbox,
.widget-messages .message .from {
  display: block;
  float: left;
}
.widget-messages .message .action-checkbox {
  width: 20px;
}
.widget-messages .message .action-checkbox > label,
.widget-messages .message .action-checkbox > input {
  display: inline-block;
  margin: 0;
  position: relative;
}
.widget-messages .message .action-checkbox > label,
.widget-messages .message .action-checkbox > input {
  vertical-align: middle;
}
.widget-messages .message .action-checkbox > input,
.widget-messages .message .action-checkbox > label {
  top: -1px;
}
.widget-messages .message .from {
  min-width: 115px;
  max-width: 180px;
  width: 25%;
  padding-left: 5px;
  overflow: hidden;
  color: #555;
}
.widget-messages .message .from:hover {
  color: #888;
}
.widget-messages .message .title {
  display: block;
  float: none;
  overflow: hidden;
  padding-right: 60px;
}
.widget-messages .message .date {
  display: block;
  position: absolute;
  color: #888;
  width: 50px;
  right: 0;
  text-align: right;
  top: 9px;
}
.widget-messages .message .message-title-icon {
  font-weight: 400 !important;
  color: #555;
  display: inline-block;
  margin-right: 7px;
  font-size: 14px;
  text-decoration: none !important;
}
.widget-messages .message.unread .title {
  font-weight: 600;
}
.widget-messages .message + .message {
  border-top: 1px solid;
  border-top-color: #e4e4e4;
}
.panel.widget-messages .message,
.panel .widget-messages .message {
  margin: 0 -20px;
  padding-left: 20px;
  padding-right: 20px;
}
.panel.widget-messages .message .date,
.panel .widget-messages .message .date {
  right: 20px;
}
.widget-tasks .task {
  padding: 8px 0;
  position: relative;
  background: #fff;
}
.widget-tasks .task:before,
.widget-tasks .task:after {
  content: " ";
  display: table;
}
.widget-tasks .task:after {
  clear: both;
}
.widget-tasks .task .action-checkbox,
.widget-tasks .task .task-title {
  display: block;
  line-height: 21px;
}
.widget-tasks .task .action-checkbox {
  float: left;
  width: 20px;
}
.widget-tasks .task .action-checkbox > label,
.widget-tasks .task .action-checkbox > input {
  display: inline-block;
  margin: 0;
  position: relative;
}
.widget-tasks .task .action-checkbox > label,
.widget-tasks .task .action-checkbox > input {
  vertical-align: middle;
}
.widget-tasks .task .action-checkbox > input,
.widget-tasks .task .action-checkbox > label {
  top: -1px;
}
.widget-tasks .task .task-title {
  color: #444;
  text-decoration: none;
  float: none;
  overflow: hidden;
}
.widget-tasks .task .task-title span {
  display: inline-block;
  color: #aaa;
  margin-left: 5px;
  font-size: 12px;
  font-style: italic;
}
.widget-tasks .task.completed a {
  color: #999;
  text-decoration: line-through;
}
.widget-tasks .task.completed a span {
  display: none;
}
.widget-tasks .task .label {
  display: block;
  position: absolute;
  right: 0;
}
.widget-tasks .task .task-sort-icon {
  color: #bbb;
  cursor: move;
  display: block;
  float: left;
  position: relative;
  margin: -8px 6px -8px 0;
  padding: 8px 12px;
  line-height: 21px;
}
.widget-tasks .task + .task {
  border-top: 1px solid;
  border-top-color: #e4e4e4;
}
.widget-tasks .task.ui-sortable-helper {
  background: #f1f6f7;
}
.panel.widget-tasks .task,
.panel .widget-tasks .task {
  padding-left: 20px;
  padding-right: 20px;
  margin: 0 -20px;
}
.panel.widget-tasks .task .label,
.panel .widget-tasks .task .label {
  right: 20px;
}
.panel.widget-tasks .task .task-sort-icon,
.panel .widget-tasks .task .task-sort-icon {
  margin-left: -11px;
}
.widget-rating,
.widget-rating li {
  padding: 0;
  margin: 0;
  list-style: none;
  display: inline-block;
}
.widget-rating a,
.widget-rating li a {
  display: block;
  color: #bbb;
  text-decoration: none;
  text-align: center;
  font-size: 15px;
}
.widget-rating a:before,
.widget-rating li a:before {
  content: "\f005";
  font-family: FontAwesome;
}
.widget-maps {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.widget-maps img {
  max-width: none !important;
}
.widget-maps label {
  display: inline !important;
  width: auto !important;
}
.widget-maps .gmnoprint {
  line-height: normal !important;
}
.widget-notifications .notification {
  position: relative;
  padding: 7px 12px;
}
.widget-notifications .notification + .notification {
  border-top: 1px solid #e4e4e4;
}
.widget-notifications .notifications-list {
  overflow: hidden;
  position: relative;
}
.widget-notifications .notification-title,
.widget-notifications .notification-description,
.widget-notifications .notification-ago {
  display: block;
  margin-right: 35px;
}
.widget-notifications .notification-title {
  font-size: 11px;
  font-weight: 700;
  padding-bottom: 1px;
}
.widget-notifications .notification-description {
  font-size: 11px;
  color: #666;
}
.widget-notifications .notification-ago {
  font-size: 11px;
  font-style: italic;
  color: #bbb;
}
.widget-notifications .notification-icon {
  width: 26px;
  height: 26px;
  line-height: 26px;
  text-align: center;
  border-radius: 2px;
  position: absolute;
  top: 50%;
  right: 0;
  margin: -13px 12px 0 0;
  font-size: 14px;
}
.widget-notifications .notifications-link {
  text-align: center;
  padding: 8px 0 !important;
  border-top: 1px solid #e4e4e4;
  display: block;
  text-decoration: none !important;
  font-size: 10px;
  font-weight: 800;
  color: #999;
  line-height: 18px !important;
  height: auto !important;
}
.widget-notifications .notifications-link:hover {
  background: #f4f4f4;
  color: #555;
}
.widget-messages-alt .message {
  position: relative;
  padding: 10px 12px;
}
.widget-messages-alt .message + .message {
  border-top: 1px solid #e4e4e4;
}
.widget-messages-alt .messages-list {
  overflow: hidden;
  position: relative;
}
.widget-messages-alt .message-avatar {
  display: block;
  width: 32px;
  height: 32px;
  position: absolute;
  border-radius: 999999px;
  top: 50%;
  margin-top: -16px;
  left: 12px;
}
.widget-messages-alt .message-subject,
.widget-messages-alt .message-description,
.widget-messages-alt .message-subject a,
.widget-messages-alt .message-description a {
  line-height: 17px !important;
}
.widget-messages-alt .message-subject {
  display: block;
  padding-left: 42px;
  margin-bottom: 2px;
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.widget-messages-alt .message-description {
  display: block;
  padding-left: 42px;
  font-size: 11px;
  color: #aaa;
  height: auto !important;
}
.widget-messages-alt .message-description a {
  color: #555;
}
.widget-messages-alt .message-description a:hover {
  color: #888;
}
.widget-messages-alt .messages-link {
  text-align: center;
  padding: 8px 0 !important;
  border-top: 1px solid #e4e4e4;
  display: block;
  text-decoration: none !important;
  font-size: 10px;
  font-weight: 800;
  color: #999;
  line-height: 18px !important;
  height: auto !important;
}
.widget-messages-alt .messages-link:hover {
  background: #f4f4f4;
  color: #555;
}
.widget-followers .follower {
  padding: 0 0 10px 0;
  position: relative;
}
.widget-followers .follower + .follower {
  border-top: 1px solid;
  border-top-color: #e4e4e4;
  padding-top: 10px;
}
.widget-followers .follower:last-child {
  padding-bottom: 0;
}
.widget-followers .follower-avatar {
  border-radius: 99999px;
  display: block;
  float: left;
  height: 32px;
  margin-right: 10px;
  margin-top: 2px;
  width: 32px;
}
.widget-followers .follower-controls {
  float: right;
  margin-top: 4px;
}
.widget-followers .follower-controls .btn > i {
  font-size: 14px;
  position: relative;
  top: 1px;
}
.widget-followers .body {
  line-height: 17px;
}
.widget-followers .follower-name {
  color: #333;
  font-size: 13px;
}
.widget-followers .follower-username {
  color: #aaa;
  font-size: 12px;
}
.panel.widget-followers .follower,
.panel .widget-followers .follower {
  margin: 0 -20px;
  padding-left: 20px;
  padding-right: 20px;
}
