.theme-default {
  background: #f6f6f6;
}
.theme-default .page-header {
  background: #f6f6f6;
  border-color: #e9e9e9;
}
.theme-default .table,
.theme-default .panel,
.theme-default hr {
  border-color: #e2e2e2;
}
.theme-default .bordered {
  border-color: #e2e2e2;
}
.theme-default .bordered[class*='bg-'],
.theme-default [class*='bg-'] .border-t {
  border-color: #fff;
  border-color: rgba(0, 0, 0, 0.2);
}
.theme-default .panel.panel-transparent > .panel-heading {
  border-bottom-color: #e2e2e2 !important;
}
.theme-default .breadcrumb-page {
  background: #f6f6f6;
  border-bottom: 1px solid #e9e9e9;
  padding-bottom: 6px;
  padding-top: 6px;
}
.theme-default #main-navbar {
  -webkit-box-shadow: 0 2px 2px rgba(0,0,0,0.05),0 1px 0 rgba(0,0,0,0.05);
  box-shadow: 0 2px 2px rgba(0,0,0,0.05),0 1px 0 rgba(0,0,0,0.05);
}
.theme-default #main-navbar .navbar-header {
  background: #fff;
}
.theme-default #main-navbar .navbar-header a {
  color: #555;
}
.theme-default #main-navbar .navbar-brand {
  color: #555;
}
.theme-default #main-navbar .navbar-toggle,
.theme-default #main-navbar #main-menu-toggle {
  color: #555;
}
.theme-default #main-navbar #main-menu-toggle {
  background: #f8f8f8;
}
.theme-default #main-navbar #main-menu-toggle .fa:before {
  color: #555;
}
.theme-default #main-navbar .navbar-collapse {
  border-top: 1px solid #f1f1f1;
  background: #fff;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.theme-default #main-navbar .dropdown > a,
.theme-default #main-navbar .dropdown-menu > li > a,
.theme-default #main-navbar .nav > li > a {
  color: #555;
}
.theme-default #main-navbar li + li,
.theme-default #main-navbar .nav + .nav,
.theme-default #main-navbar .nav + .right {
  border-top: 1px solid #f1f1f1;
}
.theme-default #main-navbar .dropdown.open > a {
  border-bottom: 1px solid #e4e4e4;
  border-top: 1px solid #dfdfdf;
  margin-top: -1px;
  background: #f6f6f6;
  color: #555;
}
.theme-default #main-navbar form.navbar-form {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.theme-default #main-navbar form.navbar-form .form-control {
  background: #f8f8f8;
  border: none;
  color: #999;
}
.theme-default #main-navbar form.navbar-form .form-control:focus {
  color: #555;
  -webkit-box-shadow: none;
  box-shadow: none;
}
@media (min-width: 768px) {
  .theme-default #main-navbar .navbar-header {
    background: #fff;
  }
  .theme-default #main-navbar .navbar-collapse {
    background: #fff;
    border: none;
  }
  .theme-default #main-navbar .navbar-collapse > div > .navbar-nav {
    border-left: 1px solid #f1f1f1;
  }
  .theme-default #main-navbar .right > .navbar-nav > li {
    border-left: 1px solid #f1f1f1;
    border-right: none;
  }
  .theme-default #main-navbar .navbar-nav > li {
    border-right: 1px solid #f1f1f1;
  }
  .theme-default #main-navbar .navbar-nav > li > a {
    color: #555;
  }
  .theme-default #main-navbar .navbar-nav > li > a:hover,
  .theme-default #main-navbar .navbar-nav > li.active > a,
  .theme-default #main-navbar .navbar-nav > li.dropdown.open > a {
    background: #f8f8f8;
    border-top: none !important;
    border-bottom: none !important;
  }
  .theme-default #main-navbar li + li,
  .theme-default #main-navbar .nav + .nav,
  .theme-default #main-navbar .nav + .right {
    border-top: none;
  }
  .theme-default #main-navbar .dropdown.open > a {
    margin: 0;
    border-top: none;
  }
  .theme-default #main-navbar .dropdown-menu {
    background: #fff;
    padding: 5px 0;
  }
  .theme-default #main-navbar .dropdown-menu .divider {
    background-color: #f1f1f1;
  }
  .theme-default #main-navbar .dropdown-menu > li > a {
    color: #555;
  }
  .theme-default #main-navbar .dropdown-menu > li > a:hover {
    background: #f1f1f1;
  }
  .theme-default #main-navbar .widget-notifications a {
    color: !important;
  }
  .theme-default #main-navbar .widget-notifications .notification,
  .theme-default #main-navbar .widget-notifications .notifications-link {
    border-color: #f1f1f1;
    -webkit-box-shadow: 0 1px 0 rgba(255, 255, 255, 0.05) inset;
    box-shadow: 0 1px 0 rgba(255, 255, 255, 0.05) inset;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
  }
  .theme-default #main-navbar .widget-notifications .notification-description {
    color: #999;
  }
  .theme-default #main-navbar .widget-notifications .notification-ago {
    color: #bbb;
  }
  .theme-default #main-navbar .widget-notifications .notifications-link:hover {
    background: #f1f1f1;
  }
  .theme-default #main-navbar .widget-messages-alt a {
    color: !important;
  }
  .theme-default #main-navbar .widget-messages-alt .message,
  .theme-default #main-navbar .widget-messages-alt .messages-link {
    border-color: #f1f1f1;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
  }
  .theme-default #main-navbar .widget-messages-alt .message-subject {
    color: #555 !important;
  }
  .theme-default #main-navbar .widget-messages-alt .message-description {
    color: #aaa;
  }
  .theme-default #main-navbar .widget-messages-alt .message-description a {
    color: #888;
  }
  .theme-default #main-navbar .widget-messages-alt .messages-link:hover {
    background: #f1f1f1;
  }
  .theme-default.main-menu-right #main-navbar .navbar-header,
  .theme-default.mmc #main-navbar .navbar-header {
    background: #fff;
  }
  .theme-default.main-menu-right #main-navbar .navbar-header {
    border-right: 1px solid #f1f1f1;
  }
}
@media (min-width: 768px) {
  .theme-default.right-to-left #main-navbar .right > .navbar-nav > li {
    border-right: 1px solid #f1f1f1;
    border-left: none;
  }
}
.theme-default #main-menu-bg {
  background-color: #23272d;
}
.theme-default #main-menu .navigation a,
.theme-default #main-menu .mmc-dropdown-open-ul a {
  color: #808b9c;
  position: relative;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}
.theme-default #main-menu .navigation a:after,
.theme-default #main-menu .mmc-dropdown-open-ul a:after {
  content: "";
  top: 0;
  bottom: 0;
  width: 4px;
  position: absolute;
  right: auto;
  background: #1a7ab9;
  display: none;
  left: 0;
}
.theme-default #main-menu .navigation a:hover,
.theme-default #main-menu .mmc-dropdown-open-ul a:hover {
  color: #fff;
}
.theme-default #main-menu .navigation a:hover:after,
.theme-default #main-menu .mmc-dropdown-open-ul a:hover:after {
  display: block !important;
}
.theme-default #main-menu li.active > a {
  background: #181b1f;
  color: #fff;
}
.theme-default #main-menu li.active > a .menu-icon {
  color: #1d89cf;
}
.theme-default #main-menu li.active > a:after {
  display: block;
}
.theme-default #main-menu .mm-dropdown.active > a {
  background: none;
}
.theme-default #main-menu .mm-dropdown.active > a:after {
  display: none;
}
.theme-default #main-menu .mm-dropdown > ul,
.theme-default #main-menu .mmc-dropdown-open-ul {
  background: #2a2e36;
}
.theme-default #main-menu .mmc-dropdown-open-ul .mmc-title {
  background: #181b1f;
  color: #fff;
}
.theme-default #main-menu .mmc-dropdown-open > a {
  background: #181b1f !important;
}
.theme-default #main-menu .mmc-dropdown-open > a,
.theme-default #main-menu .mmc-dropdown-open > a .menu-icon {
  color: #fff !important;
}
.theme-default #main-menu .mmc-dropdown-open > a:hover:after {
  display: none !important;
}
.theme-default #main-menu .menu-content {
  border-color: #2e333b;
}
.theme-default #main-menu .menu-content .btn-outline {
  border-color: #2e333b;
  color: #808b9c;
}
.theme-default #main-menu .menu-content .btn-outline:hover {
  color: #fff;
}
.theme-default .mmc-dropdown-open .mm-dropdown.open > a,
.theme-default.mme #main-menu .mm-dropdown.open > a {
  color: #fff;
}
@media (min-width: 768px) {
  .theme-default #main-menu .mm-dropdown.open > a {
    color: #fff;
  }
  .theme-default.mmc #main-menu .navigation > .mm-dropdown.open > a {
    color: #808b9c;
  }
}
.theme-default.right-to-left #main-menu .navigation a:after {
  left: auto;
  right: 0;
}
.theme-default #main-navbar .navbar-brand div,
.theme-default .demo-logo {
  background: #1d89cf;
}
.theme-default #main-navbar .navbar-header a {
  text-transform: uppercase;
  font-size: 11px;
  font-weight: 600;
}
.theme-default.page-signin .signin-info,
.theme-default.page-signup .signup-header {
  background: #1d89cf;
  background: rgba(29, 137, 207, 0.8);
}
.theme-default .timeline:before,
.theme-default .timeline:after,
.theme-default .tl-header,
.theme-default .tl-icon {
  background: #dddddd;
}
.theme-default .tl-header,
.theme-default .tl-icon {
  box-shadow: 0 0 0 4px #f6f6f6;
}
.theme-default .tl-entry:before {
  background: #1d89cf;
  box-shadow: 0 0 0 3px #f6f6f6;
}
.theme-default .tl-body:before {
  border-color: transparent #e2e2e2 transparent transparent;
}
@media (min-width: 768px) {
  .theme-default .timeline.centered .tl-entry.left .tl-body:before {
    border-color: transparent transparent transparent #e2e2e2;
  }
  .theme-default.right-to-left .timeline.centered .tl-entry.left .tl-body:before {
    border-color: transparent #e2e2e2 transparent transparent;
  }
}
.theme-default.right-to-left .tl-body:before {
  border-color: transparent transparent transparent #e2e2e2;
}
.theme-default.page-mail .mail-nav,
.theme-default.page-mail .mail-nav:before {
  background: #f6f6f6;
}
.theme-default.page-mail .mail-nav .sections li.active .label,
.theme-default.page-mail .mail-nav .sections li.active .badge {
  background: #1d89cf;
}
.theme-default.page-mail .mail-nav,
.theme-default.page-mail .mail-nav .compose-btn,
.theme-default.page-mail .mail-nav .sections li.active,
.theme-default.page-mail .mail-nav:before {
  border-color: #e2e2e2;
}
.theme-default.page-mail .mail-nav .navigation.open .sections li.active {
  background: #ececec;
}
.theme-default.page-mail .mail-nav li.divider {
  background: #e2e2e2;
}
.theme-default.page-mail .mail-nav .sections li.active a {
  color: #444444;
}
.theme-default.page-mail .mail-nav .sections li.active:after {
  color: #aaaaaa;
}
.theme-default.page-mail .mail-nav .sections a {
  color: #6a6a6a;
}
.theme-default.page-mail .mail-nav-header {
  color: #aaaaaa;
}
.theme-default.page-mail .mail-nav .add-more a {
  color: #aaaaaa;
}
@media (min-width: 992px) {
  .theme-default.page-mail .mail-nav .navigation .sections li.active {
    background: #ececec;
  }
  .theme-default.page-mail .mail-nav .sections a:hover {
    color: #444444;
  }
}
.theme-default .nav-icon-btn.nav-icon-btn-primary .label {
  background: #1d89cf;
}
.theme-default .btn-primary {
  color: #fff;
}
.theme-default .btn-primary,
.theme-default .btn-primary:focus {
  border-color: #1a7ab9;
  border-bottom-color: #16689e;
  background: #1d89cf;
  background-image: -webkit-linear-gradient(top, #1e8cd3 0%, #1a7ab9 100%);
  background-image: linear-gradient(to bottom, #1e8cd3 0%, #1a7ab9 100%);
  background-repeat: repeat-x;
}
.theme-default .btn-primary:hover {
  color: #fff;
  border-color: #1871ab;
  border-bottom-color: #12547e;
  background: #1a7ab9 !important;
  background-image: -webkit-linear-gradient(top, #1c83c6 0%, #1871ab 100%) !important;
  background-image: linear-gradient(to bottom, #1c83c6 0%, #1871ab 100%) !important;
  background-repeat: repeat-x;
}
.theme-default .btn-primary:active,
.theme-default .btn-primary.active,
.open .theme-default .btn-primary.dropdown-toggle {
  background: none;
  background: #1a7dbd !important;
  border-color: #146090;
  border-bottom-color: #146090 !important;
  color: #fff;
  -webkit-box-shadow: 0 0 12px rgba(0, 0, 0, 0.1) inset;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.1) inset;
}
.theme-default .btn-primary.btn-flat {
  background: #1b80c2;
}
.theme-default .btn-primary.btn-flat:hover,
.theme-default .btn-primary.btn-flat:active,
.theme-default .btn-primary.btn-flat.active,
.open .theme-default .btn-primary.btn-flat.dropdown-toggle {
  background: #1b80c2 !important;
  color: #fff;
}
.theme-default .btn-primary.btn-outline:hover,
.theme-default .btn-primary.btn-outline.btn-flat:hover {
  color: #fff;
  border-color: #1871ab;
  border-bottom-color: #12547e;
}
.theme-default .btn-primary.btn-outline:active,
.theme-default .btn-primary.btn-outline.btn-flat:active {
  border-color: #146090;
}
.btn-group .theme-default .btn-primary,
.btn-group .theme-default .btn-primary:focus,
.input-group-btn .theme-default .btn-primary,
.input-group-btn .theme-default .btn-primary:focus {
  border-left-color: #1871ab;
  border-right-color: #1871ab;
}
.btn-group .theme-default .btn-primary:hover,
.btn-group.open .theme-default .btn-primary.dropdown-toggle,
.input-group-btn .theme-default .btn-primary:hover,
.input-group-btn.open .theme-default .btn-primary.dropdown-toggle {
  border-color: #1871ab;
  border-bottom-color: #12547e;
}
.btn-group .theme-default .btn-primary:active,
.btn-group .theme-default .btn-primary.active,
.input-group-btn .theme-default .btn-primary:active,
.input-group-btn .theme-default .btn-primary.active {
  border-left-color: #146090;
  border-right-color: #146090;
}
.theme-default .open .btn.btn-primary.dropdown-toggle {
  color: #fff;
  border-bottom-color: #146090 !important;
  background: none;
  background: #1a7dbd !important;
}
.theme-default .progress-bar {
  background: #1d89cf;
  border-color: #1977b4;
}
.theme-default .progress-striped .progress-bar {
  background-color: #1d89cf;
  background-image: -webkit-gradient(linear, 0 100%, 100% 0, color-stop(0.25, rgba(255, 255, 255, 0.1)), color-stop(0.25, transparent), color-stop(0.5, transparent), color-stop(0.5, rgba(255, 255, 255, 0.1)), color-stop(0.75, rgba(255, 255, 255, 0.1)), color-stop(0.75, transparent), to(transparent));
  background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.1) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.1) 50%, rgba(255, 255, 255, 0.1) 75%, transparent 75%, transparent);
  background-image: -moz-linear-gradient(45deg, rgba(255, 255, 255, 0.1) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.1) 50%, rgba(255, 255, 255, 0.1) 75%, transparent 75%, transparent);
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.1) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.1) 50%, rgba(255, 255, 255, 0.1) 75%, transparent 75%, transparent);
  background-size: 10px 10px;
}
.theme-default .label.label-primary {
  background: #1d89cf;
}
.theme-default .label.label-primary[href]:hover,
.theme-default .label.label-primary[href]:focus {
  background-color: #176ba2;
}
.theme-default .label.label-primary.label-tag {
  border: 1px solid #1d89cf;
}
.theme-default .label.label-primary.label-tag:before {
  border-color: transparent #1d89cf transparent transparent;
}
.theme-default .label.label-primary.label-tag[href]:hover,
.theme-default .label.label-primary.label-tag[href]:focus {
  border-color: #176ba2;
}
.theme-default .label.label-primary.label-tag[href]:hover:before,
.theme-default .label.label-primary.label-tag[href]:focus:before {
  border-color: transparent #176ba2 transparent transparent;
}
.right-to-left .theme-default .label.label-primary.label-tag:before {
  border-color: transparent transparent transparent #1d89cf;
}
.right-to-left .theme-default .label.label-primary.label-tag[href]:hover:before,
.right-to-left .theme-default .label.label-primary.label-tag[href]:focus:before {
  border-color: transparent transparent transparent #176ba2;
}
.ie8 .right-to-left .theme-default .label.label-primary.label-tag:before,
.ie9 .right-to-left .theme-default .label.label-primary.label-tag:before {
  border-color: transparent #1d89cf transparent transparent;
}
.ie8 .right-to-left .theme-default .label.label-primary.label-tag[href]:hover:before,
.ie8 .right-to-left .theme-default .label.label-primary.label-tag[href]:focus:before,
.ie9 .right-to-left .theme-default .label.label-primary.label-tag[href]:hover:before,
.ie9 .right-to-left .theme-default .label.label-primary.label-tag[href]:focus:before {
  border-color: transparent #176ba2 transparent transparent;
}
.theme-default.right-to-left .label.label-primary.label-tag:before {
  border-color: transparent transparent transparent #1d89cf;
}
.theme-default.right-to-left .label.label-primary.label-tag:hover:before,
.theme-default.right-to-left .label.label-primary.label-tag:focus:before {
  border-color: transparent transparent transparent #176ba2;
}
.ie8 .theme-default.right-to-left .label.label-primary.label-tag:before,
.ie9 .theme-default.right-to-left .label.label-primary.label-tag:before {
  border-color: transparent #1d89cf transparent transparent;
}
.ie8 .theme-default.right-to-left .label.label-primary.label-tag:hover:before,
.ie8 .theme-default.right-to-left .label.label-primary.label-tag:focus:before,
.ie9 .theme-default.right-to-left .label.label-primary.label-tag:hover:before,
.ie9 .theme-default.right-to-left .label.label-primary.label-tag:focus:before {
  border-color: transparent #176ba2 transparent transparent;
}
.theme-default .badge.badge-primary {
  background: #1d89cf;
  border: 1px solid #1d89cf;
}
.theme-default .badge.badge-primary[href]:hover,
.theme-default .badge.badge-primary[href]:focus {
  background-color: #176ba2;
  border-color: #176ba2;
}
.theme-default .badge.badge-primary {
  background: #1d89cf;
  border: 1px solid #1d89cf;
}
.theme-default .badge.badge-primary[href]:hover,
.theme-default .badge.badge-primary[href]:focus {
  background-color: #176ba2;
  border-color: #176ba2;
}
.theme-default .table-primary table {
  border-top-color: #23638c !important;
}
.theme-default .table-primary thead,
.theme-default .table-primary thead tr,
.theme-default .table-primary thead th,
.theme-default .table-primary .table-header {
  border-color: #23638c !important;
  color: #fff;
}
.theme-default .table-primary thead tr,
.theme-default .table-primary thead th {
  background: #2175ac;
}
.theme-default .table-primary .table-header {
  background: #1d89cf;
}
.theme-default .nav-tabs > li.active > a,
.theme-default .nav-tabs > li.active > a:hover,
.theme-default .nav-tabs > li.active > a:focus {
  background: #1d89cf;
  border-bottom: 2px solid #1a7ab9;
}
.theme-default .nav-tabs .nav-tabs.nav-justified > .active > a {
  border-bottom-color: #1a7ab9;
}
.theme-default .nav-pills > li.active > a,
.theme-default .nav-pills > li.active > a:hover,
.theme-default .nav-pills > li.active > a:focus {
  background: #1d89cf;
}
.theme-default .dropdown-menu > li > a:hover,
.theme-default .dropdown-menu > li.active > a {
  background: #1d89cf;
}
.theme-default .dropdown-menu > li > a:hover {
  color: #fff;
}
.theme-default .btn-group .btn-primary,
.theme-default .btn-group .btn-primary:focus,
.theme-default .input-group-btn .btn-primary,
.theme-default .input-group-btn .btn-primary:focus {
  border-left-color: #1871ab;
  border-right-color: #1871ab;
}
.theme-default .btn-group .btn-primary:hover,
.theme-default .btn-group.open .btn-primary.dropdown-toggle,
.theme-default .input-group-btn .btn-primary:hover,
.theme-default .input-group-btn.open .btn-primary.dropdown-toggle {
  border-color: #146090;
}
.theme-default .btn-group .btn-primary:active,
.theme-default .btn-group .btn-primary.active,
.theme-default .input-group-btn .btn-primary:active,
.theme-default .input-group-btn .btn-primary.active {
  border-left-color: #146090;
  border-right-color: #146090;
}
.theme-default .pagination > li > a:hover {
  border-color: #1d89cf;
}
.theme-default .pagination > li.active > a,
.theme-default .pagination > li.active > a:hover,
.theme-default .pagination > li.active > a:focus,
.theme-default .pagination > li.active > span {
  background: #1d89cf;
  border-color: #1d89cf;
}
.theme-default .pager li > a:hover {
  border-color: #1d89cf;
}
.theme-default a.list-group-item.active {
  background: #1d89cf;
  border-color: #1d89cf;
}
.theme-default a.list-group-item.active .list-group-item-text {
  color: #c2e2f6;
}
.theme-default .form-control:focus,
.theme-default .form-control.focus {
  border-color: #1d89cf;
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.gt-ie8 .theme-default input.px:focus + .lbl:before,
.gt-ie8 .theme-default input.px + .lbl:hover:before {
  border-color: #1d89cf;
}
.gt-ie8 .theme-default input[type="checkbox"].px + .lbl:after {
  color: #1d89cf;
}
.gt-ie8 .theme-default input[type="radio"].px + .lbl:after {
  background: #1d89cf;
}
.theme-default .text-primary,
.theme-default .text-primary:hover,
.theme-default .text-primary:active,
.theme-default .text-primary:focus,
.theme-default .text-primary a,
.theme-default .text-primary a:focus {
  color: #1d89cf;
}
.theme-default .text-primary a:hover,
.theme-default .text-primary a:active {
  color: #145d8c;
}
.theme-default .bg-primary {
  background: #1d89cf !important;
}
.theme-default .bg-primary[href]:hover {
  background: #227fbb !important;
}
.theme-default .bg-primary.darken {
  background: #227fbb !important;
}
.theme-default .bg-primary.darken[href]:hover {
  background: #1f76ae !important;
}
.theme-default .bg-primary.darker {
  background: #1f76ae !important;
}
.theme-default .bg-primary.darker[href]:hover {
  background: #1d6da1 !important;
}
.theme-default .bg-primary,
.theme-default .bg-primary:hover,
.theme-default .bg-primary:active,
.theme-default .bg-primary:focus,
.theme-default .bg-primary a,
.theme-default .bg-primary a:focus {
  color: #fff;
}
.theme-default .bg-primary a:hover,
.theme-default .bg-primary a:active {
  color: #d9d9d9;
}
.theme-default .bg-primary[href]:hover,
.theme-default .bg-primary [href]:hover {
  color: #fff;
}
.theme-default .bg-primary * {
  border-color: #2e9be2;
}
.theme-default .ui-slider-handle.ui-state-active:before {
  background: #1d89cf;
}
.theme-default .ui-slider-range {
  background: #1d89cf;
}
.theme-default .ui-autocomplete > li > a:hover,
.theme-default .ui-autocompletev > li.active > a {
  background: #1d89cf;
}
.theme-default .ui-autocomplete > li > a:hover {
  color: #fff;
}
.theme-default .ui-autocomplete .ui-state-focus {
  background: #1d89cf;
  color: #fff;
}
.theme-default .ui-menu .ui-menu-item a:hover,
.theme-default .ui-menu .ui-menu-item a.ui-state-focus,
.theme-default .ui-menu .ui-menu-item a.ui-state-active {
  background: #1d89cf;
}
.theme-default .ui-progressbar-value {
  background: #1d89cf;
  border-color: #1977b4;
  background-color: #1d89cf;
  background-image: -webkit-gradient(linear, 0 100%, 100% 0, color-stop(0.25, rgba(255, 255, 255, 0.1)), color-stop(0.25, transparent), color-stop(0.5, transparent), color-stop(0.5, rgba(255, 255, 255, 0.1)), color-stop(0.75, rgba(255, 255, 255, 0.1)), color-stop(0.75, transparent), to(transparent));
  background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.1) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.1) 50%, rgba(255, 255, 255, 0.1) 75%, transparent 75%, transparent);
  background-image: -moz-linear-gradient(45deg, rgba(255, 255, 255, 0.1) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.1) 50%, rgba(255, 255, 255, 0.1) 75%, transparent 75%, transparent);
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.1) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.1) 50%, rgba(255, 255, 255, 0.1) 75%, transparent 75%, transparent);
  background-size: 10px 10px;
}
.theme-default .ui-tabs-nav > .ui-tabs-active > a,
.theme-default .ui-tabs-nav > .ui-tabs-active > a:hover,
.theme-default .ui-tabs-nav > .ui-tabs-active > a:focus {
  color: #fff;
  background: #1d89cf;
  border-bottom: 2px solid #1a7ab9;
}
.theme-default .ui-datepicker .ui-state-active {
  background: #1d89cf !important;
}
.theme-default .ui-datepicker-title {
  background: #1d89cf;
}
.theme-default .ui-datepicker th {
  background: #2f76a4;
}
.theme-default .ui-datepicker-buttonpane button.ui-priority-primary {
  color: #fff;
}
.theme-default .ui-datepicker-buttonpane button.ui-priority-primary,
.theme-default .ui-datepicker-buttonpane button.ui-priority-primary:focus {
  border-color: #1a7ab9;
  border-bottom-color: #16689e;
  background: #1d89cf;
  background-image: -webkit-linear-gradient(top, #1e8cd3 0%, #1a7ab9 100%);
  background-image: linear-gradient(to bottom, #1e8cd3 0%, #1a7ab9 100%);
  background-repeat: repeat-x;
}
.theme-default .ui-datepicker-buttonpane button.ui-priority-primary:hover {
  color: #fff;
  border-color: #1871ab;
  border-bottom-color: #12547e;
  background: #1a7ab9 !important;
  background-image: -webkit-linear-gradient(top, #1c83c6 0%, #1871ab 100%) !important;
  background-image: linear-gradient(to bottom, #1c83c6 0%, #1871ab 100%) !important;
  background-repeat: repeat-x;
}
.theme-default .ui-datepicker-buttonpane button.ui-priority-primary:active,
.theme-default .ui-datepicker-buttonpane button.ui-priority-primary.active,
.open .theme-default .ui-datepicker-buttonpane button.ui-priority-primary.dropdown-toggle {
  background: none;
  background: #1a7dbd !important;
  border-color: #146090;
  border-bottom-color: #146090 !important;
  color: #fff;
  -webkit-box-shadow: 0 0 12px rgba(0, 0, 0, 0.1) inset;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.1) inset;
}
.theme-default .ui-datepicker-buttonpane button.ui-priority-primary.btn-flat {
  background: #1b80c2;
}
.theme-default .ui-datepicker-buttonpane button.ui-priority-primary.btn-flat:hover,
.theme-default .ui-datepicker-buttonpane button.ui-priority-primary.btn-flat:active,
.theme-default .ui-datepicker-buttonpane button.ui-priority-primary.btn-flat.active,
.open .theme-default .ui-datepicker-buttonpane button.ui-priority-primary.btn-flat.dropdown-toggle {
  background: #1b80c2 !important;
  color: #fff;
}
.theme-default .ui-datepicker-buttonpane button.ui-priority-primary.btn-outline:hover,
.theme-default .ui-datepicker-buttonpane button.ui-priority-primary.btn-outline.btn-flat:hover {
  color: #fff;
  border-color: #1871ab;
  border-bottom-color: #12547e;
}
.theme-default .ui-datepicker-buttonpane button.ui-priority-primary.btn-outline:active,
.theme-default .ui-datepicker-buttonpane button.ui-priority-primary.btn-outline.btn-flat:active {
  border-color: #146090;
}
.btn-group .theme-default .ui-datepicker-buttonpane button.ui-priority-primary,
.btn-group .theme-default .ui-datepicker-buttonpane button.ui-priority-primary:focus,
.input-group-btn .theme-default .ui-datepicker-buttonpane button.ui-priority-primary,
.input-group-btn .theme-default .ui-datepicker-buttonpane button.ui-priority-primary:focus {
  border-left-color: #1871ab;
  border-right-color: #1871ab;
}
.btn-group .theme-default .ui-datepicker-buttonpane button.ui-priority-primary:hover,
.btn-group.open .theme-default .ui-datepicker-buttonpane button.ui-priority-primary.dropdown-toggle,
.input-group-btn .theme-default .ui-datepicker-buttonpane button.ui-priority-primary:hover,
.input-group-btn.open .theme-default .ui-datepicker-buttonpane button.ui-priority-primary.dropdown-toggle {
  border-color: #1871ab;
  border-bottom-color: #12547e;
}
.btn-group .theme-default .ui-datepicker-buttonpane button.ui-priority-primary:active,
.btn-group .theme-default .ui-datepicker-buttonpane button.ui-priority-primary.active,
.input-group-btn .theme-default .ui-datepicker-buttonpane button.ui-priority-primary:active,
.input-group-btn .theme-default .ui-datepicker-buttonpane button.ui-priority-primary.active {
  border-left-color: #146090;
  border-right-color: #146090;
}
.theme-default .select2-drop-active,
.theme-default .select2-container-multi.select2-container-active .select2-choices,
.theme-default .select2-container-multi.select2-dropdown-open .select2-choices {
  border: 1px solid #1d89cf;
}
.theme-default .select2-drop.select2-drop-above.select2-drop-active {
  border-top: 1px solid #1d89cf;
}
.theme-default .select2-container-active .select2-choice,
.theme-default .select2-container-active .select2-choices {
  border: 1px solid #1d89cf;
}
.theme-default .select2-dropdown-open .select2-drop-above .select2-choice,
.theme-default .select2-dropdown-open .select2-drop-above .select2-choices {
  border: 1px solid #1d89cf;
  border-top-color: #aaa;
}
.theme-default .select2-results .select2-highlighted {
  background: #1d89cf;
}
.theme-default .select2-primary .select2-container-multi .select2-choices .select2-search-choice {
  background: #1d89cf;
}
.theme-default .select2-primary .select2-container-multi .select2-choices .select2-search-choice-focus {
  background: #176ba2;
}
.theme-default .select2-primary .select2-container-multi.select2-container-disabled .select2-choices .select2-search-choice {
  background: #1d89cf !important;
}
.theme-default .switcher-primary .switcher-state-on {
  background: #1d89cf !important;
}
.theme-default .pixel-file-input:hover {
  border-color: #1d89cf;
}
.theme-default .datepicker table tr td.selected,
.theme-default .datepicker table tr td.selected:hover,
.theme-default .datepicker table tr td.selected.disabled,
.theme-default .datepicker table tr td.selected.disabled:hover {
  background: #1d89cf;
}
.theme-default .datepicker table tr td.active,
.theme-default .datepicker table tr td.active:hover,
.theme-default .datepicker table tr td.active.disabled,
.theme-default .datepicker table tr td.active.disabled:hover {
  background: #1d89cf;
}
.theme-default .datepicker table tr td span.active,
.theme-default .datepicker table tr td span.active:hover,
.theme-default .datepicker table tr td span.active.disabled,
.theme-default .datepicker table tr td span.active.disabled:hover {
  background: #1d89cf;
}
.theme-default .datepicker thead tr {
  background: #2f76a4;
}
.theme-default .datepicker thead tr:first-child {
  background: #1d89cf !important;
  border-bottom-color: #30607f !important;
}
.theme-default .datepicker thead th.prev,
.theme-default .datepicker thead th.next {
  background: #1d89cf;
}
.theme-default .datepicker thead th.prev {
  border-color: #30607f;
}
.theme-default .datepicker thead th.next {
  border-color: #30607f;
}
.theme-default .datepicker > .datepicker-days thead tr:first-child th.cw + .prev {
  border-color: #30607f;
}
.theme-default .datepicker > .datepicker-days thead tr:last-child th.cw + th {
  border-color: #30607f;
}
.theme-default .bootstrap-timepicker-widget table td a:hover {
  background: #1d89cf;
  border-color: #1d89cf;
}
.theme-default .pagination a.dp-today,
.theme-default .pagination a.dp-today:hover {
  background: #3ba1e4;
  border-color: #2597e0;
}
.theme-default .pagination a.dp-today:hover {
  border-color: #1d89cf !important;
}
.theme-default .pagination a.dp-selected {
  background: #1d89cf !important;
  border-color: #1d89cf !important;
}
.theme-default .tt-suggestion.tt-is-under-cursor {
  background: #1d89cf;
}
.theme-default .table-primary .dataTables_wrapper .DT-per-page {
  border-color: #23638c !important;
}
.theme-default .dropzone-box.dz-drag-hover {
  border-color: #1d89cf;
}
.theme-default .dropzone-box:hover .fa.fa-cloud-upload {
  background: #1d89cf;
}
.theme-default .md-editor.active {
  border-color: #1d89cf;
}
.theme-default .widget-rating .active a {
  color: #1d89cf;
}
.theme-fortunix {
  background: #f7f7f7;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 13px;
  color: #6a6c6f;
  margin: 0;
  padding: 0;
  height: 100%;
}
.theme-fortunix #flash-message {
  margin: 0;
  padding: 0;
}
.theme-fortunix #flash-message .alert.alert-page {
  margin: -18px -18px;
  display: block;
  height: 72px;
}
.theme-fortunix #content-wrapper {
  min-height: 100vh;
  padding-top: 18px;
}
.theme-fortunix #content-wrapper.light {
  background: #FFF;
}
.theme-fortunix .page-header {
  padding: 10px 20px 10px;
  margin-bottom: 0;
  display: block;
}
.theme-fortunix .page-header h1 {
  font-size: 16px;
}
.theme-fortunix .page-navs .nav-tabs {
  display: block;
  margin: 0 -18px;
  padding: 0 20px;
  background: #fff;
  border-top: none;
  border-bottom: 1px solid #c4c4c4;
}
.theme-fortunix .page-navs .nav-tabs > li {
  margin-right: 20px;
  min-height: 40px;
}
.theme-fortunix .page-navs .nav-tabs > li.table-action {
  margin: 0;
  padding: 0;
  padding-top: 8px;
  float: right;
}
.theme-fortunix .page-navs .nav-tabs > li > a {
  text-transform: uppercase;
  font-size: 11px;
  font-weight: 600;
  padding: 0;
  line-height: 40px;
  letter-spacing: 1.2px;
  border-bottom-color: transparent;
}
.theme-fortunix .page-navs .nav-tabs > li.active > a {
  font-weight: 800;
  border-bottom-color: transparent;
}
.theme-fortunix .page-navs .nav-tabs > li.active > a:hover {
  border-bottom-color: transparent;
}
.theme-fortunix .page-body.no-padding {
  margin: 0 -18px 18px -18px;
}
.theme-fortunix .page-tables {
  margin: 0 -18px 18px -18px;
}
.theme-fortunix .table,
.theme-fortunix .panel,
.theme-fortunix hr {
  border-color: #c4c4c4;
}
.theme-fortunix .panel {
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
}
.theme-fortunix .panel-heading {
  background: #f4f4f4;
  background-image: -webkit-linear-gradient(top, #efefef 0%, f7f7f7 100%);
  background-image: linear-gradient(to bottom, #efefef 0%, f7f7f7 100%);
  background-repeat: repeat-x;
  border-bottom: 1px solid #c4c4c4;
}
.theme-fortunix .panel-footer {
  border-color: #c4c4c4;
}
.theme-fortunix .bordered {
  border-color: #c4c4c4;
}
.theme-fortunix .bordered[class*='bg-'],
.theme-fortunix [class*='bg-'] .border-t {
  border-color: #fff;
  border-color: rgba(0, 0, 0, 0.2);
}
.theme-fortunix .panel.panel-transparent > .panel-heading {
  border-bottom-color: #c4c4c4 !important;
}
.theme-fortunix .breadcrumb-page {
  background: #fff;
  border-bottom: 1px solid #c4c4c4;
  padding-bottom: 6px;
  padding-top: 6px;
}
@media (max-width: 480px) {
  .theme-fortunix .page-header {
    padding-top: 20px;
    border-top-width: 1px;
  }
}
@media (min-width: 480px) and (max-width: 768px) {
  .theme-fortunix .page-header {
    padding-top: 20px;
    border-top-width: 1px;
  }
}
.theme-fortunix #main-navbar {
  border-top: 4px solid #333;
  border-bottom: 1px solid #023a65;
}
.theme-fortunix #main-navbar .navbar-header {
  background: #0072c6;
}
.theme-fortunix #main-navbar .navbar-header a {
  color: #fff;
}
.theme-fortunix #main-navbar .navbar-brand {
  color: #fff;
}
.theme-fortunix #main-navbar .navbar-toggle,
.theme-fortunix #main-navbar #main-menu-toggle {
  color: #fff;
}
.theme-fortunix #main-navbar #main-menu-toggle {
  background: #0072c6;
}
.theme-fortunix #main-navbar #main-menu-toggle .fa:before {
  color: #fff;
}
.theme-fortunix #main-navbar .navbar-collapse {
  border-top: 1px solid #0084e5;
  background: #0072c6;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.theme-fortunix #main-navbar .dropdown > a,
.theme-fortunix #main-navbar .dropdown-menu > li > a,
.theme-fortunix #main-navbar .nav > li > a {
  color: #c6e7ff;
}
.theme-fortunix #main-navbar .dropdown > a:hover,
.theme-fortunix #main-navbar .dropdown-menu > li > a:hover,
.theme-fortunix #main-navbar .nav > li > a:hover {
  color: #FFF;
}
.theme-fortunix #main-navbar li + li,
.theme-fortunix #main-navbar .nav + .nav,
.theme-fortunix #main-navbar .nav + .right {
  border-top: 1px solid #0084e5;
}
.theme-fortunix #main-navbar .dropdown.open > a {
  background: #0084e5;
  border-bottom: 1px solid #008ff9;
  border-top: 1px solid #149bff;
  margin-top: -1px;
  color: #fff;
}
.theme-fortunix #main-navbar form.navbar-form {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.theme-fortunix #main-navbar form.navbar-form .form-control {
  background: #fff;
  background: rgba(255, 255, 255, 0.05);
  border: none;
  color: #999;
}
.theme-fortunix #main-navbar form.navbar-form .form-control:focus {
  background: #fff;
  color: #555;
  -webkit-box-shadow: none;
  box-shadow: none;
}
@media (min-width: 768px) {
  .theme-fortunix #main-navbar #main-menu-toggle {
    background: #0072c6;
  }
  .theme-fortunix #main-navbar .navbar-header {
    background: #0081e0;
  }
  .theme-fortunix #main-navbar .navbar-collapse {
    background: #0072c6;
    border: none;
  }
  .theme-fortunix #main-navbar .navbar-collapse > div > .navbar-nav {
    border-left: 1px solid #0066b2;
    -webkit-box-shadow: 1px 0 0 rgba(255, 255, 255, 0.03) inset;
    box-shadow: 1px 0 0 rgba(255, 255, 255, 0.03) inset;
  }
  .theme-fortunix #main-navbar .right > .navbar-nav > li {
    border-left: 1px solid #0066b2;
    border-right: none;
    -webkit-box-shadow: 1px 0 0 rgba(255, 255, 255, 0.03) inset;
    box-shadow: 1px 0 0 rgba(255, 255, 255, 0.03) inset;
  }
  .theme-fortunix #main-navbar .navbar-nav > li {
    border-right: 1px solid #0066b2;
    -webkit-box-shadow: 1px 0 0 rgba(255, 255, 255, 0.03);
    box-shadow: 1px 0 0 rgba(255, 255, 255, 0.03);
  }
  .theme-fortunix #main-navbar .navbar-nav > li > a {
    color: #c6e7ff;
  }
  .theme-fortunix #main-navbar .navbar-nav > li > a:hover,
  .theme-fortunix #main-navbar .navbar-nav > li.active > a,
  .theme-fortunix #main-navbar .navbar-nav > li.dropdown.open > a {
    border-bottom: 3px solid #0081e0;
    color: #fff;
  }
  .theme-fortunix #main-navbar li + li,
  .theme-fortunix #main-navbar .nav + .nav,
  .theme-fortunix #main-navbar .nav + .right {
    border-top: none;
  }
  .theme-fortunix #main-navbar .dropdown.open > a {
    margin: 0;
    border-top: none;
  }
  .theme-fortunix #main-navbar .dropdown-menu {
    background: #0072c6;
    border: none;
    border-top: 1px solid #0081e0;
    padding: 5px 0;
  }
  .theme-fortunix #main-navbar .dropdown-menu .divider {
    background-color: #0066b2;
  }
  .theme-fortunix #main-navbar .dropdown-menu > li > a {
    color: #fff;
  }
  .theme-fortunix #main-navbar .dropdown-menu > li > a:hover {
    background: #007eda;
  }
  .theme-fortunix #main-navbar .widget-notifications a:hover {
    color: #fff !important;
  }
  .theme-fortunix #main-navbar .widget-notifications .notification,
  .theme-fortunix #main-navbar .widget-notifications .notifications-link {
    border-color: #0066b2;
    -webkit-box-shadow: 0 1px 0 rgba(255, 255, 255, 0.05) inset;
    box-shadow: 0 1px 0 rgba(255, 255, 255, 0.05) inset;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
  }
  .theme-fortunix #main-navbar .widget-notifications .notification-description {
    color: #888;
  }
  .theme-fortunix #main-navbar .widget-notifications .notification-ago {
    color: #666;
  }
  .theme-fortunix #main-navbar .widget-notifications .notifications-link:hover {
    background: #0084e5;
  }
  .theme-fortunix #main-navbar .widget-messages-alt a:hover {
    color: #fff !important;
  }
  .theme-fortunix #main-navbar .widget-messages-alt .message,
  .theme-fortunix #main-navbar .widget-messages-alt .messages-link {
    border-color: #0066b2;
    -webkit-box-shadow: 0 1px 0 rgba(255, 255, 255, 0.05) inset;
    box-shadow: 0 1px 0 rgba(255, 255, 255, 0.05) inset;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
  }
  .theme-fortunix #main-navbar .widget-messages-alt .message-subject {
    color: #bbb;
  }
  .theme-fortunix #main-navbar .widget-messages-alt .message-description {
    color: #666;
  }
  .theme-fortunix #main-navbar .widget-messages-alt .message-description a {
    color: #888;
  }
  .theme-fortunix #main-navbar .widget-messages-alt .messages-link:hover {
    background: #0084e5;
  }
  .theme-fortunix.main-menu-right #main-navbar .navbar-header,
  .theme-fortunix.mmc #main-navbar .navbar-header,
  .theme-fortunix.no-main-menu #main-navbar .navbar-header {
    background: #0072c6;
  }
  .theme-fortunix.main-menu-right #main-navbar .navbar-header {
    border-right: 1px solid #0066b2;
  }
}
@media (min-width: 768px) {
  .theme-fortunix.right-to-left #main-navbar .right > .navbar-nav > li {
    border-right: 1px solid #0066b2;
    border-left: none;
    -webkit-box-shadow: 1px 0 0 rgba(255, 255, 255, 0.03);
    box-shadow: 1px 0 0 rgba(255, 255, 255, 0.03);
  }
  .theme-fortunix.right-to-left.mmc #main-navbar .navbar-header {
    -webkit-box-shadow: 1px 0 0 rgba(255, 255, 255, 0.03) inset;
    box-shadow: 1px 0 0 rgba(255, 255, 255, 0.03) inset;
  }
}
.theme-fortunix #main-menu-bg {
  background-color: #33393d;
}
.theme-fortunix #main-menu {
  padding-top: 30px;
}
.theme-fortunix #main-menu .menu-logo {
  background: #0072c6;
  padding: 10px 0;
}
.theme-fortunix #main-menu .navigation a,
.theme-fortunix #main-menu .mmc-dropdown-open-ul a {
  color: #b0b8be;
  position: relative;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}
.theme-fortunix #main-menu .navigation a:after,
.theme-fortunix #main-menu .mmc-dropdown-open-ul a:after {
  content: "";
  top: 0;
  bottom: 0;
  width: 1px;
  position: absolute;
  right: auto;
  background: #0072c6;
  display: none;
  left: 0;
}
.theme-fortunix #main-menu .navigation a:hover,
.theme-fortunix #main-menu .mmc-dropdown-open-ul a:hover {
  color: #fff;
}
.theme-fortunix #main-menu .navigation a:hover:after,
.theme-fortunix #main-menu .mmc-dropdown-open-ul a:hover:after {
  display: block !important;
}
.theme-fortunix #main-menu .navigation {
  -webkit-box-shadow: 0 1px 0 #303a4a;
  box-shadow: 0 1px 0 #303a4a;
}
.theme-fortunix #main-menu .navigation li {
  border-top: 1px solid #2c3235;
  border-bottom: 1px solid #383e42;
}
.theme-fortunix #main-menu .navigation li ul > li {
  border-bottom: none;
}
.theme-fortunix #main-menu .navigation li a:hover {
  background: #2c3235;
}
.theme-fortunix #main-menu .navigation li.active {
  border-top: none;
  background: #242424;
  background: rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: 0px 1px 6px inset rgba(0, 0, 0, 0.25) !important;
  box-shadow: 0px 1px 6px inset rgba(0, 0, 0, 0.25) !important;
}
.theme-fortunix #main-menu .navigation li.active > a,
.theme-fortunix #main-menu .navigation li.active > a:hover {
  color: #75c6f3 !important;
}
.theme-fortunix #main-menu li.active > a {
  background: #272c2f;
  color: #fff;
}
.theme-fortunix #main-menu li.active > a .menu-icon {
  color: #0081e0;
}
.theme-fortunix #main-menu li.active > a:after {
  display: block;
}
.theme-fortunix #main-menu .mm-dropdown.active > a {
  background: none;
}
.theme-fortunix #main-menu .mm-dropdown.active > a:after {
  display: none;
}
.theme-fortunix #main-menu .mm-dropdown > ul,
.theme-fortunix #main-menu .mmc-dropdown-open-ul {
  background: #3a4145;
}
.theme-fortunix #main-menu .mmc-dropdown-open-ul .mmc-title {
  background: #0072c6;
  color: #fff;
}
.theme-fortunix #main-menu .mmc-dropdown-open > a {
  background: #0072c6 !important;
}
.theme-fortunix #main-menu .mmc-dropdown-open > a,
.theme-fortunix #main-menu .mmc-dropdown-open > a .menu-icon {
  color: #fff !important;
}
.theme-fortunix #main-menu .mmc-dropdown-open > a:hover:after {
  display: none !important;
}
.theme-fortunix #main-menu .menu-content {
  border-color: #3f464b;
}
.theme-fortunix #main-menu .menu-content .btn-outline {
  border-color: #3f464b;
  color: #95a0a7;
}
.theme-fortunix #main-menu .menu-content .btn-outline:hover {
  color: #fff;
}
.theme-fortunix .mmc-dropdown-open .mm-dropdown.open > a,
.theme-fortunix.mme #main-menu .mm-dropdown.open > a {
  color: #fff;
}
@media (min-width: 768px) {
  .theme-fortunix #main-menu .mm-dropdown.open > a {
    color: #fff;
  }
  .theme-fortunix.mmc #main-menu .navigation > .mm-dropdown.open > a {
    color: #95a0a7;
  }
}
.theme-fortunix.right-to-left #main-menu .navigation a:after {
  left: auto;
  right: 0;
}
.theme-fortunix #main-navbar .navbar-brand div,
.theme-fortunix .demo-logo {
  background: #0081e0;
}
.theme-fortunix.page-signin .signin-info,
.theme-fortunix.page-signup .signup-header {
  background: #0072c6;
  background: rgba(0, 114, 198, 0.8);
}
.theme-fortunix .timeline:before,
.theme-fortunix .timeline:after,
.theme-fortunix .tl-header,
.theme-fortunix .tl-icon {
  background: #dedede;
}
.theme-fortunix .tl-header,
.theme-fortunix .tl-icon {
  box-shadow: 0 0 0 4px #f7f7f7;
}
.theme-fortunix .tl-entry:before {
  background: #0072c6;
  box-shadow: 0 0 0 3px #f7f7f7;
}
.theme-fortunix .tl-body:before {
  border-color: transparent #c4c4c4 transparent transparent;
}
@media (min-width: 768px) {
  .theme-fortunix .timeline.centered .tl-entry.left .tl-body:before {
    border-color: transparent transparent transparent #c4c4c4;
  }
  .theme-fortunix.right-to-left .timeline.centered .tl-entry.left .tl-body:before {
    border-color: transparent #c4c4c4 transparent transparent;
  }
}
.theme-fortunix.right-to-left .tl-body:before {
  border-color: transparent transparent transparent #c4c4c4;
}
.theme-fortunix.page-mail .mail-nav,
.theme-fortunix.page-mail .mail-nav:before {
  background: #f7f7f7;
}
.theme-fortunix.page-mail .mail-nav .sections li.active .label,
.theme-fortunix.page-mail .mail-nav .sections li.active .badge {
  background: #0072c6;
}
.theme-fortunix.page-mail .mail-nav,
.theme-fortunix.page-mail .mail-nav .compose-btn,
.theme-fortunix.page-mail .mail-nav .sections li.active,
.theme-fortunix.page-mail .mail-nav:before {
  border-color: #c4c4c4;
}
.theme-fortunix.page-mail .mail-nav .navigation.open .sections li.active {
  background: #ededed;
}
.theme-fortunix.page-mail .mail-nav li.divider {
  background: #c4c4c4;
}
.theme-fortunix.page-mail .mail-nav .sections li.active a {
  color: #454545;
}
.theme-fortunix.page-mail .mail-nav .sections li.active:after {
  color: #ababab;
}
.theme-fortunix.page-mail .mail-nav .sections a {
  color: #6b6b6b;
}
.theme-fortunix.page-mail .mail-nav-header {
  color: #ababab;
}
.theme-fortunix.page-mail .mail-nav .add-more a {
  color: #ababab;
}
@media (min-width: 992px) {
  .theme-fortunix.page-mail .mail-nav .navigation .sections li.active {
    background: #ededed;
  }
  .theme-fortunix.page-mail .mail-nav .sections a:hover {
    color: #454545;
  }
}
.theme-fortunix .reload .table {
  display: none;
}
.theme-fortunix .table thead > tr > th,
.theme-fortunix .table tbody > tr > th,
.theme-fortunix .table tfoot > tr > th,
.theme-fortunix .table thead > tr > td,
.theme-fortunix .table tbody > tr > td,
.theme-fortunix .table tfoot > tr > td {
  border-top-color: #c4c4c4;
  color: #6c7680;
}
.theme-fortunix .table thead > tr > th a,
.theme-fortunix .table tbody > tr > th a,
.theme-fortunix .table tfoot > tr > th a,
.theme-fortunix .table thead > tr > td a,
.theme-fortunix .table tbody > tr > td a,
.theme-fortunix .table tfoot > tr > td a {
  color: #6c7680;
  font-weight: 600;
}
.theme-fortunix .table thead > tr > th a:hover,
.theme-fortunix .table tbody > tr > th a:hover,
.theme-fortunix .table tfoot > tr > th a:hover,
.theme-fortunix .table thead > tr > td a:hover,
.theme-fortunix .table tbody > tr > td a:hover,
.theme-fortunix .table tfoot > tr > td a:hover {
  text-decoration: underline;
}
.theme-fortunix .table thead > tr > td,
.theme-fortunix .table tbody > tr > td,
.theme-fortunix .table tfoot > tr > td {
  padding-top: 10px;
  padding-bottom: 10px;
}
.theme-fortunix .table thead > tr:first-child > td,
.theme-fortunix .table tbody > tr:first-child > td,
.theme-fortunix .table tfoot > tr:first-child > td {
  border-top: none;
}
.theme-fortunix .table thead > tr > td:first-child,
.theme-fortunix .table tbody > tr > td:first-child,
.theme-fortunix .table tfoot > tr > td:first-child {
  padding-left: 20px;
}
.theme-fortunix .table thead .px-single,
.theme-fortunix .table tbody .px-single,
.theme-fortunix .table tfoot .px-single {
  vertical-align: middle;
  display: block;
  padding-top: 2px;
}
.theme-fortunix .table thead > tr > td:last-child,
.theme-fortunix .table tbody > tr > td:last-child,
.theme-fortunix .table tfoot > tr > td:last-child {
  padding-right: 20px;
}
.theme-fortunix .table thead > tr > th.clean,
.theme-fortunix .table tbody > tr > th.clean,
.theme-fortunix .table tfoot > tr > th.clean {
  font-weight: 600;
  background: #f4f4f4;
  background-image: -webkit-linear-gradient(top, #efefef 0%, #f7f7f7 100%);
  background-image: linear-gradient(to bottom, #efefef 0%, #f7f7f7 100%);
  background-repeat: repeat-x;
  color: #9b9b9b;
  font-size: 11px;
  line-height: 20px;
}
.theme-fortunix .table thead > tr > th,
.theme-fortunix .table tbody > tr > th,
.theme-fortunix .table tfoot > tr > th {
  font-weight: 600;
  background: #f9f9f9;
  color: #999999;
  font-size: 11px;
  line-height: 20px;
  text-shadow: 0 2px 2px #ffffff;
}
.theme-fortunix .table tbody + tbody {
  border: 1px solid #c4c4c4;
}
.theme-fortunix .table thead > tr {
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  border: none;
}
.theme-fortunix .table-hover > tbody > tr:hover > td,
.theme-fortunix .table-hover > tbody > tr:hover > th {
  background-color: #f2fbed;
}
.theme-fortunix .table-bordered {
  border-color: #c4c4c4;
  border-top: none;
}
.theme-fortunix .table-bordered > thead > tr > th,
.theme-fortunix .table-bordered > tbody > tr > th,
.theme-fortunix .table-bordered > tfoot > tr > th,
.theme-fortunix .table-bordered > thead > tr > td,
.theme-fortunix .table-bordered > tbody > tr > td,
.theme-fortunix .table-bordered > tfoot > tr > td {
  border-color: #c4c4c4;
}
.theme-fortunix .table-bordered > thead > tr > th,
.theme-fortunix .table-bordered > tbody > tr > th,
.theme-fortunix .table-bordered > tfoot > tr > th {
  border-top: none;
  border-bottom: 1px solid #c4c4c4;
}
.theme-fortunix .table-bordered > thead > tr > th:first-child,
.theme-fortunix .table-bordered > tbody > tr > th:first-child,
.theme-fortunix .table-bordered > tfoot > tr > th:first-child,
.theme-fortunix .table-bordered > thead > tr > td:first-child,
.theme-fortunix .table-bordered > tbody > tr > td:first-child,
.theme-fortunix .table-bordered > tfoot > tr > td:first-child {
  border-left: none;
}
.theme-fortunix .table-bordered > thead > tr > td:first-child,
.theme-fortunix .table-bordered > tbody > tr > td:first-child,
.theme-fortunix .table-bordered > tfoot > tr > td:first-child {
  padding-left: 10px;
}
.theme-fortunix .table-bordered > thead > tr > th:last-child,
.theme-fortunix .table-bordered > tbody > tr > th:last-child,
.theme-fortunix .table-bordered > tfoot > tr > th:last-child,
.theme-fortunix .table-bordered > thead > tr > td:last-child,
.theme-fortunix .table-bordered > tbody > tr > td:last-child,
.theme-fortunix .table-bordered > tfoot > tr > td:last-child {
  border-right: none;
}
.theme-fortunix .table-bordered > thead > tr:last-child,
.theme-fortunix .table-bordered > tbody > tr:last-child,
.theme-fortunix .table-bordered > tfoot > tr:last-child {
  border-bottom: none;
}
.theme-fortunix .table-bordered > thead > tr:last-child > td,
.theme-fortunix .table-bordered > tbody > tr:last-child > td,
.theme-fortunix .table-bordered > tfoot > tr:last-child > td {
  border-bottom: none;
}
.theme-fortunix .table-bordered > tbody > td {
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.theme-fortunix .table-bordered > tfoot > th,
.theme-fortunix .table-bordered > tfoot > td,
.theme-fortunix .table-bordered > tfoot > tr {
  border: none;
  padding: 0;
}
.theme-fortunix .table-input > thead > tr > td,
.theme-fortunix .table-input > tbody > tr > td,
.theme-fortunix .table-input > tfoot > tr > td {
  padding-top: 0px;
  padding-bottom: 0px;
}
.theme-fortunix .table-input > thead > tr > td:first-child,
.theme-fortunix .table-input > tbody > tr > td:first-child,
.theme-fortunix .table-input > tfoot > tr > td:first-child {
  padding-left: 0px;
}
.theme-fortunix .table.loading {
  position: relative;
}
.theme-fortunix .table.loading tbody > tr > td {
  opacity: 0.5;
}
.theme-fortunix .ng-table th {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.theme-fortunix .ng-table th.sortable {
  cursor: pointer;
  text-align: left;
}
.theme-fortunix .ng-table th.sortable div {
  padding-right: 18px;
  position: relative;
}
.theme-fortunix .ng-table th.sortable div:after,
.theme-fortunix .ng-table th.sortable div:before {
  content: "";
  border-width: 0 4px 4px;
  border-style: solid;
  border-color: #000 transparent;
  visibility: visible;
  right: 8px;
  top: 50%;
  position: absolute;
  opacity: .3;
  margin-top: -4px;
}
.theme-fortunix .ng-table th.sortable div:before {
  margin-top: 2px;
  border-bottom: none;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid #000;
}
.theme-fortunix .ng-table th.sortable div:hover:after,
.theme-fortunix .ng-table th.sortable div:hover:before {
  opacity: 1;
  visibility: visible;
}
.theme-fortunix .ng-table th.sortable.sort-desc,
.theme-fortunix .ng-table th.sortable.sort-asc {
  background-color: #f1f1f1;
  text-shadow: 0 1px 1px rgba(255, 255, 255, 0.75);
}
.theme-fortunix .ng-table th.sortable.sort-desc div:after,
.theme-fortunix .ng-table th.sortable.sort-asc div:after {
  margin-top: -2px;
}
.theme-fortunix .ng-table th.sortable.sort-desc div:before,
.theme-fortunix .ng-table th.sortable.sort-asc div:before {
  visibility: hidden;
}
.theme-fortunix .ng-table th.sortable.sort-asc div:after,
.theme-fortunix .ng-table th.sortable.sort-asc div:hover:after {
  visibility: visible;
  filter: alpha(opacity=60);
  -khtml-opacity: 0.6;
  -moz-opacity: 0.6;
  opacity: 0.6;
}
.theme-fortunix .ng-table th.sortable.sort-desc div:after {
  border-bottom: none;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid #000;
  visibility: visible;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  filter: alpha(opacity=60);
  -khtml-opacity: 0.6;
  -moz-opacity: 0.6;
  opacity: 0.6;
}
.theme-fortunix .indicator,
.theme-fortunix .indicator-right,
.theme-konektifa .indicator-right {
  background: none;
  vertical-align: middle;
}
.theme-fortunix .indicator::before,
.theme-fortunix .indicator-right::after,
.theme-konektifa .indicator-right::after {
  content: '';
  display: inline-block;
  height: 8px;
  width: 8px;
  border-radius: 8px;
  margin: 0 4px 0 0px;
  background: #b0b0b0;
}
.theme-fortunix .indicator.default::before,
.theme-fortunix .indicator.draft::before,
.theme-fortunix .indicator.unpaid::before,
.theme-fortunix .indicator.new::before,
.theme-fortunix .indicator-right.default::after,
.theme-konektifa .indicator-right.default::after {
  background: #b0b0b0;
}
.theme-fortunix .indicator.fulfilled::before,
.theme-fortunix .indicator.success::before,
.theme-fortunix .indicator.approved::before,
.theme-fortunix .indicator.invoiced::before,
.theme-fortunix .indicator.paid::before,
.theme-fortunix .indicator.delivered:before,
.theme-fortunix .indicator.submited:before,
.theme-fortunix .indicator.completed:before,
.theme-fortunix .indicator.finished:before,
.theme-fortunix .indicator-right.success::after,
.theme-konektifa .indicator-right.success::after {
  background: #5ebd5e;
}
.theme-fortunix .indicator.warning::before,
.theme-fortunix .indicator.partial::before,
.theme-fortunix .indicator.production:before,
.theme-fortunix .indicator.packed:before,
.theme-fortunix .indicator.ready:before,
.theme-fortunix .indicator-right.warning::after,
.theme-konektifa .indicator-right.warning::after {
  background: #f4b04f;
}
.theme-fortunix .indicator.danger::before,
.theme-fortunix .indicator.rejected::before,
.theme-fortunix .indicator.canceled::before,
.theme-fortunix .indicator.returned:before,
.theme-fortunix .indicator-right.danger::after,
.theme-konektifa .indicator-right.danger::after {
  background: #e66454;
}
.theme-fortunix .indicator.primary::before,
.theme-fortunix .indicator.active::before,
.theme-fortunix .indicator.published::before,
.theme-fortunix .indicator.picking:before,
.theme-fortunix .indicator.shipping:before,
.theme-fortunix .indicator.shipped:before,
.theme-fortunix .indicator.onprogress:before,
.theme-fortunix .indicator-right.primary::after,
.theme-konektifa .indicator-right.primary::after {
  background: #0072c6;
}
.theme-fortunix .indicator-right::after {
  margin: 0 0 0 4px;
}
.theme-fortunix .bordered {
  border: 1px solid #c4c4c4;
}
.theme-fortunix .bordered[class*='bg-'],
.theme-fortunix [class*='bg-'] .border-t {
  border-color: #fff;
  border-color: rgba(0, 0, 0, 0.2);
}
.theme-fortunix .border-t {
  border-top: 1px solid #c4c4c4;
}
.theme-fortunix .no-border {
  border-width: 0 !important;
}
.theme-fortunix .no-border-hr {
  border-left-width: 0 !important;
  border-right-width: 0 !important;
}
.theme-fortunix .no-border-vr {
  border-top-width: 0 !important;
  border-bottom-width: 0 !important;
}
.theme-fortunix .no-border-t {
  border-top-width: 0 !important;
}
.theme-fortunix body:not(.right-to-left) .no-border-r {
  border-right-width: 0 !important;
}
.theme-fortunix .no-border-b {
  border-bottom-width: 0 !important;
}
.theme-fortunix body:not(.right-to-left) .no-border-l {
  border-left-width: 0 !important;
}
.theme-fortunix .no-grid-gutter-h {
  margin-left: -18px !important;
  margin-right: -18px !important;
}
.theme-fortunix .panel-wide {
  margin-left: -20px !important;
  margin-right: -20px !important;
}
.theme-fortunix .grid-gutter-margin-b {
  margin-bottom: 22px;
}
.theme-fortunix .no-margin {
  margin: 0 !important;
}
.theme-fortunix .no-margin-hr {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
.theme-fortunix .no-margin-vr {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
.theme-fortunix .no-margin-b {
  margin-bottom: 0 !important;
}
.theme-fortunix .no-margin-t {
  margin-top: 0 !important;
}
.theme-fortunix .form-group-margin {
  margin-bottom: 15px !important;
}
.theme-fortunix .panel-padding {
  padding: 20px !important;
}
.theme-fortunix .grid-gutter-padding-h {
  padding-left: 18px;
  padding-right: 18px;
}
.theme-fortunix .tab-content-padding {
  padding: 15px 0 !important;
}
.theme-fortunix .panel-padding-h {
  padding-left: 20px !important;
  padding-right: 20px !important;
}
.theme-fortunix .padding-sm {
  padding: 10px 15px !important;
}
.theme-fortunix .padding-sm-hr {
  padding-left: 15px !important;
  padding-right: 15px !important;
}
.theme-fortunix .padding-xs-hr {
  padding-left: 10px !important;
  padding-right: 10px !important;
}
.theme-fortunix .padding-sm-vr {
  padding-top: 15px !important;
  padding-bottom: 15px !important;
}
.theme-fortunix .padding-xs-vr {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}
.theme-fortunix .no-padding {
  padding: 0 !important;
}
.theme-fortunix .no-padding-hr {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.theme-fortunix .no-padding-vr {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.theme-fortunix .no-padding-b {
  padding-bottom: 0 !important;
}
.theme-fortunix .no-padding-t {
  padding-top: 0 !important;
}
.theme-fortunix .text-left {
  text-align: left;
}
.theme-fortunix .text-center {
  text-align: center;
}
.theme-fortunix .text-right {
  text-align: right;
}
@media (min-width: 768px) {
  .theme-fortunix .text-left-sm {
    text-align: left;
  }
  .theme-fortunix .text-center-sm {
    text-align: center;
  }
  .theme-fortunix .text-right-sm {
    text-align: right;
  }
}
@media (min-width: 992px) {
  .theme-fortunix .text-left-md {
    text-align: left;
  }
  .theme-fortunix .text-center-md {
    text-align: center;
  }
  .theme-fortunix .text-right-md {
    text-align: right;
  }
}
@media (min-width: 1200px) {
  .theme-fortunix .text-left-lg {
    text-align: left;
  }
  .theme-fortunix .text-center-lg {
    text-align: center;
  }
  .theme-fortunix .text-right-lg {
    text-align: right;
  }
}
.theme-fortunix .valign-top,
.theme-fortunix .valign-top td,
.theme-fortunix .valign-top th {
  vertical-align: top !important;
}
.theme-fortunix .valign-middle,
.theme-fortunix .valign-middle td,
.theme-fortunix .valign-middle th {
  vertical-align: middle !important;
}
.theme-fortunix .valign-bottom,
.theme-fortunix .valign-bottom td,
.theme-fortunix .valign-bottom th {
  vertical-align: bottom !important;
}
.theme-fortunix .text-xs {
  font-size: 11px;
}
.theme-fortunix .text-sm {
  font-size: 12px;
}
.theme-fortunix .text-bg {
  font-size: 17px;
}
.theme-fortunix .text-lg {
  font-size: 23px;
}
.theme-fortunix .text-xlg {
  font-size: 30px;
}
.theme-fortunix .text-slg {
  font-size: 50px;
}
.theme-fortunix .text-slim {
  font-weight: 300 !important;
}
.theme-fortunix .text-normal {
  font-weight: 400 !important;
}
.theme-fortunix .text-semibold {
  font-weight: 600 !important;
}
.theme-fortunix .text-bold {
  font-weight: 700 !important;
}
.theme-fortunix .text-default,
.theme-fortunix .text-default:hover,
.theme-fortunix .text-default:active,
.theme-fortunix .text-default:focus,
.theme-fortunix .text-default a,
.theme-fortunix .text-default a:focus {
  color: #829091;
}
.theme-fortunix .text-default a:hover,
.theme-fortunix .text-default a:active {
  color: #5d6869;
}
.theme-fortunix .text-success,
.theme-fortunix .text-success:hover,
.theme-fortunix .text-success:active,
.theme-fortunix .text-success:focus,
.theme-fortunix .text-success a,
.theme-fortunix .text-success a:focus {
  color: #46ac46;
}
.theme-fortunix .text-success a:hover,
.theme-fortunix .text-success a:active {
  color: #307630;
}
.theme-fortunix .text-danger,
.theme-fortunix .text-danger:hover,
.theme-fortunix .text-danger:active,
.theme-fortunix .text-danger:focus,
.theme-fortunix .text-danger a,
.theme-fortunix .text-danger a:focus {
  color: #e14430;
}
.theme-fortunix .text-danger a:hover,
.theme-fortunix .text-danger a:active {
  color: #ac2919;
}
.theme-fortunix .text-warning,
.theme-fortunix .text-warning:hover,
.theme-fortunix .text-warning:active,
.theme-fortunix .text-warning:focus,
.theme-fortunix .text-warning a,
.theme-fortunix .text-warning a:focus {
  color: #f29f29;
}
.theme-fortunix .text-warning a:hover,
.theme-fortunix .text-warning a:active {
  color: #c2770c;
}
.theme-fortunix .text-info,
.theme-fortunix .text-info:hover,
.theme-fortunix .text-info:active,
.theme-fortunix .text-info:focus,
.theme-fortunix .text-info a,
.theme-fortunix .text-info a:focus {
  color: #39b3d7;
}
.theme-fortunix .text-info a:hover,
.theme-fortunix .text-info a:active {
  color: #2185a3;
}
.theme-fortunix .text-light-gray,
.theme-fortunix .text-light-gray:hover,
.theme-fortunix .text-light-gray:active,
.theme-fortunix .text-light-gray:focus,
.theme-fortunix .text-light-gray a,
.theme-fortunix .text-light-gray a:focus {
  color: #bbb;
}
.theme-fortunix .text-light-gray a:hover,
.theme-fortunix .text-light-gray a:active {
  color: #959595;
}
.theme-fortunix .text-light,
.theme-fortunix .text-light:hover,
.theme-fortunix .text-light:active,
.theme-fortunix .text-light:focus,
.theme-fortunix .text-light a,
.theme-fortunix .text-light a:focus {
  color: #fff;
}
.theme-fortunix .text-light a:hover,
.theme-fortunix .text-light a:active {
  color: #d9d9d9;
}
.theme-fortunix .bg-default {
  background: #98a3a4 !important;
}
.theme-fortunix .bg-default[href]:hover {
  background: #969797 !important;
}
.theme-fortunix .bg-default.darken {
  background: #969797 !important;
}
.theme-fortunix .bg-default.darken[href]:hover {
  background: #8e8f8f !important;
}
.theme-fortunix .bg-default.darker {
  background: #8e8f8f !important;
}
.theme-fortunix .bg-default.darker[href]:hover {
  background: #878787 !important;
}
.theme-fortunix .bg-default,
.theme-fortunix .bg-default:hover,
.theme-fortunix .bg-default:active,
.theme-fortunix .bg-default:focus,
.theme-fortunix .bg-default a,
.theme-fortunix .bg-default a:focus {
  color: #fff;
}
.theme-fortunix .bg-default a:hover,
.theme-fortunix .bg-default a:active {
  color: #d9d9d9;
}
.theme-fortunix .bg-default[href]:hover,
.theme-fortunix .bg-default [href]:hover {
  color: #fff;
}
.theme-fortunix .bg-default * {
  border-color: #abb4b5;
}
.theme-fortunix .bg-success {
  background: #5ebd5e !important;
}
.theme-fortunix .bg-success[href]:hover {
  background: #5ab15a !important;
}
.theme-fortunix .bg-success.darken {
  background: #5ab15a !important;
}
.theme-fortunix .bg-success.darken[href]:hover {
  background: #51ab51 !important;
}
.theme-fortunix .bg-success.darker {
  background: #51ab51 !important;
}
.theme-fortunix .bg-success.darker[href]:hover {
  background: #4ca14c !important;
}
.theme-fortunix .bg-success,
.theme-fortunix .bg-success:hover,
.theme-fortunix .bg-success:active,
.theme-fortunix .bg-success:focus,
.theme-fortunix .bg-success a,
.theme-fortunix .bg-success a:focus {
  color: #fff;
}
.theme-fortunix .bg-success a:hover,
.theme-fortunix .bg-success a:active {
  color: #d9d9d9;
}
.theme-fortunix .bg-success[href]:hover,
.theme-fortunix .bg-success [href]:hover {
  color: #fff;
}
.theme-fortunix .bg-success * {
  border-color: #77c777;
}
.theme-fortunix .bg-danger {
  background: #e66454 !important;
}
.theme-fortunix .bg-danger[href]:hover {
  background: #de5d4d !important;
}
.theme-fortunix .bg-danger.darken {
  background: #de5d4d !important;
}
.theme-fortunix .bg-danger.darken[href]:hover {
  background: #db5140 !important;
}
.theme-fortunix .bg-danger.darker {
  background: #db5140 !important;
}
.theme-fortunix .bg-danger.darker[href]:hover {
  background: #d94533 !important;
}
.theme-fortunix .bg-danger,
.theme-fortunix .bg-danger:hover,
.theme-fortunix .bg-danger:active,
.theme-fortunix .bg-danger:focus,
.theme-fortunix .bg-danger a,
.theme-fortunix .bg-danger a:focus {
  color: #fff;
}
.theme-fortunix .bg-danger a:hover,
.theme-fortunix .bg-danger a:active {
  color: #d9d9d9;
}
.theme-fortunix .bg-danger[href]:hover,
.theme-fortunix .bg-danger [href]:hover {
  color: #fff;
}
.theme-fortunix .bg-danger * {
  border-color: #eb8073;
}
.theme-fortunix .bg-warning {
  background: #f4b04f !important;
}
.theme-fortunix .bg-warning[href]:hover {
  background: #eda847 !important;
}
.theme-fortunix .bg-warning.darken {
  background: #eda847 !important;
}
.theme-fortunix .bg-warning.darken[href]:hover {
  background: #eca239 !important;
}
.theme-fortunix .bg-warning.darker {
  background: #eca239 !important;
}
.theme-fortunix .bg-warning.darker[href]:hover {
  background: #ea9b2b !important;
}
.theme-fortunix .bg-warning,
.theme-fortunix .bg-warning:hover,
.theme-fortunix .bg-warning:active,
.theme-fortunix .bg-warning:focus,
.theme-fortunix .bg-warning a,
.theme-fortunix .bg-warning a:focus {
  color: #fff;
}
.theme-fortunix .bg-warning a:hover,
.theme-fortunix .bg-warning a:active {
  color: #d9d9d9;
}
.theme-fortunix .bg-warning[href]:hover,
.theme-fortunix .bg-warning [href]:hover {
  color: #fff;
}
.theme-fortunix .bg-warning * {
  border-color: #f6bf71;
}
.theme-fortunix .bg-info {
  background: #5bc0de !important;
}
.theme-fortunix .bg-info[href]:hover {
  background: #55b8d5 !important;
}
.theme-fortunix .bg-info.darken {
  background: #55b8d5 !important;
}
.theme-fortunix .bg-info.darken[href]:hover {
  background: #48b3d2 !important;
}
.theme-fortunix .bg-info.darker {
  background: #48b3d2 !important;
}
.theme-fortunix .bg-info.darker[href]:hover {
  background: #3cadcf !important;
}
.theme-fortunix .bg-info,
.theme-fortunix .bg-info:hover,
.theme-fortunix .bg-info:active,
.theme-fortunix .bg-info:focus,
.theme-fortunix .bg-info a,
.theme-fortunix .bg-info a:focus {
  color: #fff;
}
.theme-fortunix .bg-info a:hover,
.theme-fortunix .bg-info a:active {
  color: #d9d9d9;
}
.theme-fortunix .bg-info[href]:hover,
.theme-fortunix .bg-info [href]:hover {
  color: #fff;
}
.theme-fortunix .bg-info * {
  border-color: #79cbe4;
}
.theme-fortunix .bg-panel {
  background: #F7F9FA;
}
.theme-fortunix .no-bg {
  background: none !important;
}
.theme-fortunix .rounded {
  border-radius: 999999px !important;
}
.theme-fortunix .no-border-radius {
  border-radius: 0 !important;
}
@media (min-width: 768px) {
  .theme-fortunix .col-sm-auto {
    width: auto;
  }
}
@media (min-width: 992px) {
  .theme-fortunix .col-md-auto {
    width: auto;
  }
}
@media (min-width: 1200px) {
  .theme-fortunix .col-lg-auto {
    width: auto;
  }
}
.theme-fortunix label.checkbox span.lbl {
  line-height: 18px;
  cursor: pointer;
}
.theme-fortunix .nav-icon-btn.nav-icon-btn-primary .label {
  background: #0072c6;
}
.theme-fortunix .btn-primary {
  color: #fff;
}
.theme-fortunix .btn-primary,
.theme-fortunix .btn-primary:focus {
  border-color: #0063ad;
  border-bottom-color: #00528e;
  background: #0072c6;
  background-image: -webkit-linear-gradient(top, #0075cb 0%, #0063ad 100%);
  background-image: linear-gradient(to bottom, #0075cb 0%, #0063ad 100%);
  background-repeat: repeat-x;
}
.theme-fortunix .btn-primary:hover {
  color: #fff;
  border-color: #005b9d;
  border-bottom-color: #003d6a;
  background: #0063ad !important;
  background-image: -webkit-linear-gradient(top, #006cbc 0%, #005b9d 100%) !important;
  background-image: linear-gradient(to bottom, #006cbc 0%, #005b9d 100%) !important;
  background-repeat: repeat-x;
}
.theme-fortunix .btn-primary:active,
.theme-fortunix .btn-primary.active,
.open .theme-fortunix .btn-primary.dropdown-toggle {
  background: none;
  background: #0066b2 !important;
  border-color: #00497f;
  border-bottom-color: #00497f !important;
  color: #fff;
  -webkit-box-shadow: 0 0 12px rgba(0, 0, 0, 0.1) inset;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.1) inset;
}
.theme-fortunix .btn-primary.btn-flat {
  background: #0069b7;
}
.theme-fortunix .btn-primary.btn-flat:hover,
.theme-fortunix .btn-primary.btn-flat:active,
.theme-fortunix .btn-primary.btn-flat.active,
.open .theme-fortunix .btn-primary.btn-flat.dropdown-toggle {
  background: #0069b7 !important;
  color: #fff;
}
.theme-fortunix .btn-primary.btn-outline:hover,
.theme-fortunix .btn-primary.btn-outline.btn-flat:hover {
  color: #fff;
  border-color: #005b9d;
  border-bottom-color: #003d6a;
}
.theme-fortunix .btn-primary.btn-outline:active,
.theme-fortunix .btn-primary.btn-outline.btn-flat:active {
  border-color: #00497f;
}
.btn-group .theme-fortunix .btn-primary,
.btn-group .theme-fortunix .btn-primary:focus,
.input-group-btn .theme-fortunix .btn-primary,
.input-group-btn .theme-fortunix .btn-primary:focus {
  border-left-color: #005b9d;
  border-right-color: #005b9d;
}
.btn-group .theme-fortunix .btn-primary:hover,
.btn-group.open .theme-fortunix .btn-primary.dropdown-toggle,
.input-group-btn .theme-fortunix .btn-primary:hover,
.input-group-btn.open .theme-fortunix .btn-primary.dropdown-toggle {
  border-color: #005b9d;
  border-bottom-color: #003d6a;
}
.btn-group .theme-fortunix .btn-primary:active,
.btn-group .theme-fortunix .btn-primary.active,
.input-group-btn .theme-fortunix .btn-primary:active,
.input-group-btn .theme-fortunix .btn-primary.active {
  border-left-color: #00497f;
  border-right-color: #00497f;
}
.theme-fortunix .open .btn.btn-primary.dropdown-toggle {
  color: #fff;
  border-bottom-color: #00497f !important;
  background: none;
  background: #0066b2 !important;
}
.theme-fortunix .progress-bar {
  background: #0072c6;
  border-color: #0060a7;
}
.theme-fortunix .progress-striped .progress-bar {
  background-color: #0072c6;
  background-image: -webkit-gradient(linear, 0 100%, 100% 0, color-stop(0.25, rgba(255, 255, 255, 0.1)), color-stop(0.25, transparent), color-stop(0.5, transparent), color-stop(0.5, rgba(255, 255, 255, 0.1)), color-stop(0.75, rgba(255, 255, 255, 0.1)), color-stop(0.75, transparent), to(transparent));
  background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.1) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.1) 50%, rgba(255, 255, 255, 0.1) 75%, transparent 75%, transparent);
  background-image: -moz-linear-gradient(45deg, rgba(255, 255, 255, 0.1) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.1) 50%, rgba(255, 255, 255, 0.1) 75%, transparent 75%, transparent);
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.1) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.1) 50%, rgba(255, 255, 255, 0.1) 75%, transparent 75%, transparent);
  background-size: 10px 10px;
}
.theme-fortunix .label.label-primary {
  background: #0072c6;
}
.theme-fortunix .label.label-primary[href]:hover,
.theme-fortunix .label.label-primary[href]:focus {
  background-color: #005593;
}
.theme-fortunix .label.label-primary.label-tag {
  border: 1px solid #0072c6;
}
.theme-fortunix .label.label-primary.label-tag:before {
  border-color: transparent #0072c6 transparent transparent;
}
.theme-fortunix .label.label-primary.label-tag[href]:hover,
.theme-fortunix .label.label-primary.label-tag[href]:focus {
  border-color: #005593;
}
.theme-fortunix .label.label-primary.label-tag[href]:hover:before,
.theme-fortunix .label.label-primary.label-tag[href]:focus:before {
  border-color: transparent #005593 transparent transparent;
}
.right-to-left .theme-fortunix .label.label-primary.label-tag:before {
  border-color: transparent transparent transparent #0072c6;
}
.right-to-left .theme-fortunix .label.label-primary.label-tag[href]:hover:before,
.right-to-left .theme-fortunix .label.label-primary.label-tag[href]:focus:before {
  border-color: transparent transparent transparent #005593;
}
.ie8 .right-to-left .theme-fortunix .label.label-primary.label-tag:before,
.ie9 .right-to-left .theme-fortunix .label.label-primary.label-tag:before {
  border-color: transparent #0072c6 transparent transparent;
}
.ie8 .right-to-left .theme-fortunix .label.label-primary.label-tag[href]:hover:before,
.ie8 .right-to-left .theme-fortunix .label.label-primary.label-tag[href]:focus:before,
.ie9 .right-to-left .theme-fortunix .label.label-primary.label-tag[href]:hover:before,
.ie9 .right-to-left .theme-fortunix .label.label-primary.label-tag[href]:focus:before {
  border-color: transparent #005593 transparent transparent;
}
.theme-fortunix.right-to-left .label.label-primary.label-tag:before {
  border-color: transparent transparent transparent #0072c6;
}
.theme-fortunix.right-to-left .label.label-primary.label-tag:hover:before,
.theme-fortunix.right-to-left .label.label-primary.label-tag:focus:before {
  border-color: transparent transparent transparent #005593;
}
.ie8 .theme-fortunix.right-to-left .label.label-primary.label-tag:before,
.ie9 .theme-fortunix.right-to-left .label.label-primary.label-tag:before {
  border-color: transparent #0072c6 transparent transparent;
}
.ie8 .theme-fortunix.right-to-left .label.label-primary.label-tag:hover:before,
.ie8 .theme-fortunix.right-to-left .label.label-primary.label-tag:focus:before,
.ie9 .theme-fortunix.right-to-left .label.label-primary.label-tag:hover:before,
.ie9 .theme-fortunix.right-to-left .label.label-primary.label-tag:focus:before {
  border-color: transparent #005593 transparent transparent;
}
.theme-fortunix .badge.badge-primary {
  background: #0072c6;
  border: 1px solid #0072c6;
}
.theme-fortunix .badge.badge-primary[href]:hover,
.theme-fortunix .badge.badge-primary[href]:focus {
  background-color: #005593;
  border-color: #005593;
}
.theme-fortunix .badge.badge-primary {
  background: #0072c6;
  border: 1px solid #0072c6;
}
.theme-fortunix .badge.badge-primary[href]:hover,
.theme-fortunix .badge.badge-primary[href]:focus {
  background-color: #005593;
  border-color: #005593;
}
.theme-fortunix .table-primary table {
  border-top-color: #0a4d7f !important;
}
.theme-fortunix .table-primary thead,
.theme-fortunix .table-primary thead tr,
.theme-fortunix .table-primary thead th,
.theme-fortunix .table-primary .table-header {
  border-color: #0a4d7f !important;
  color: #fff;
}
.theme-fortunix .table-primary thead tr,
.theme-fortunix .table-primary thead th {
  background: #075fa1;
}
.theme-fortunix .table-primary .table-header {
  background: #0072c6;
}
.theme-fortunix .nav-tabs > li.active > a,
.theme-fortunix .nav-tabs > li.active > a:hover,
.theme-fortunix .nav-tabs > li.active > a:focus {
  background: #0072c6;
  border-bottom: 2px solid #0063ad;
}
.theme-fortunix .nav-tabs .nav-tabs.nav-justified > .active > a {
  border-bottom-color: #0063ad;
}
.theme-fortunix .nav-pills > li.active > a,
.theme-fortunix .nav-pills > li.active > a:hover,
.theme-fortunix .nav-pills > li.active > a:focus {
  background: #0072c6;
}
.theme-fortunix .dropdown-menu > li > a:hover,
.theme-fortunix .dropdown-menu > li.active > a {
  background: #0072c6;
}
.theme-fortunix .dropdown-menu > li > a:hover {
  color: #fff;
}
.theme-fortunix .btn-group .btn-primary,
.theme-fortunix .btn-group .btn-primary:focus,
.theme-fortunix .input-group-btn .btn-primary,
.theme-fortunix .input-group-btn .btn-primary:focus {
  border-left-color: #005b9d;
  border-right-color: #005b9d;
}
.theme-fortunix .btn-group .btn-primary:hover,
.theme-fortunix .btn-group.open .btn-primary.dropdown-toggle,
.theme-fortunix .input-group-btn .btn-primary:hover,
.theme-fortunix .input-group-btn.open .btn-primary.dropdown-toggle {
  border-color: #00497f;
}
.theme-fortunix .btn-group .btn-primary:active,
.theme-fortunix .btn-group .btn-primary.active,
.theme-fortunix .input-group-btn .btn-primary:active,
.theme-fortunix .input-group-btn .btn-primary.active {
  border-left-color: #00497f;
  border-right-color: #00497f;
}
.theme-fortunix .pagination > li > a:hover {
  border-color: #0072c6;
}
.theme-fortunix .pagination > li.active > a,
.theme-fortunix .pagination > li.active > a:hover,
.theme-fortunix .pagination > li.active > a:focus,
.theme-fortunix .pagination > li.active > span {
  background: #0072c6;
  border-color: #0072c6;
}
.theme-fortunix .pager li > a:hover {
  border-color: #0072c6;
}
.theme-fortunix a.list-group-item.active {
  background: #0072c6;
  border-color: #0072c6;
}
.theme-fortunix a.list-group-item.active .list-group-item-text {
  color: #93d1ff;
}
.theme-fortunix .form-control:focus,
.theme-fortunix .form-control.focus {
  border-color: #0072c6;
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.gt-ie8 .theme-fortunix input.px:focus + .lbl:before,
.gt-ie8 .theme-fortunix input.px + .lbl:hover:before {
  border-color: #0072c6;
}
.gt-ie8 .theme-fortunix input[type="checkbox"].px + .lbl:after {
  color: #0072c6;
}
.gt-ie8 .theme-fortunix input[type="radio"].px + .lbl:after {
  background: #0072c6;
}
.theme-fortunix .text-primary,
.theme-fortunix .text-primary:hover,
.theme-fortunix .text-primary:active,
.theme-fortunix .text-primary:focus,
.theme-fortunix .text-primary a,
.theme-fortunix .text-primary a:focus {
  color: #0072c6;
}
.theme-fortunix .text-primary a:hover,
.theme-fortunix .text-primary a:active {
  color: #00467a;
}
.theme-fortunix .bg-primary {
  background: #0072c6 !important;
}
.theme-fortunix .bg-primary[href]:hover {
  background: #0568b1 !important;
}
.theme-fortunix .bg-primary.darken {
  background: #0568b1 !important;
}
.theme-fortunix .bg-primary.darken[href]:hover {
  background: #0560a2 !important;
}
.theme-fortunix .bg-primary.darker {
  background: #0560a2 !important;
}
.theme-fortunix .bg-primary.darker[href]:hover {
  background: #055794 !important;
}
.theme-fortunix .bg-primary,
.theme-fortunix .bg-primary:hover,
.theme-fortunix .bg-primary:active,
.theme-fortunix .bg-primary:focus,
.theme-fortunix .bg-primary a,
.theme-fortunix .bg-primary a:focus {
  color: #fff;
}
.theme-fortunix .bg-primary a:hover,
.theme-fortunix .bg-primary a:active {
  color: #d9d9d9;
}
.theme-fortunix .bg-primary[href]:hover,
.theme-fortunix .bg-primary [href]:hover {
  color: #fff;
}
.theme-fortunix .bg-primary * {
  border-color: #0087ea;
}
.theme-fortunix .ui-slider-handle.ui-state-active:before {
  background: #0072c6;
}
.theme-fortunix .ui-slider-range {
  background: #0072c6;
}
.theme-fortunix .ui-autocomplete > li > a:hover,
.theme-fortunix .ui-autocompletev > li.active > a {
  background: #0072c6;
}
.theme-fortunix .ui-autocomplete > li > a:hover {
  color: #fff;
}
.theme-fortunix .ui-autocomplete .ui-state-focus {
  background: #0072c6;
  color: #fff;
}
.theme-fortunix .ui-menu .ui-menu-item a:hover,
.theme-fortunix .ui-menu .ui-menu-item a.ui-state-focus,
.theme-fortunix .ui-menu .ui-menu-item a.ui-state-active {
  background: #0072c6;
}
.theme-fortunix .ui-progressbar-value {
  background: #0072c6;
  border-color: #0060a7;
  background-color: #0072c6;
  background-image: -webkit-gradient(linear, 0 100%, 100% 0, color-stop(0.25, rgba(255, 255, 255, 0.1)), color-stop(0.25, transparent), color-stop(0.5, transparent), color-stop(0.5, rgba(255, 255, 255, 0.1)), color-stop(0.75, rgba(255, 255, 255, 0.1)), color-stop(0.75, transparent), to(transparent));
  background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.1) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.1) 50%, rgba(255, 255, 255, 0.1) 75%, transparent 75%, transparent);
  background-image: -moz-linear-gradient(45deg, rgba(255, 255, 255, 0.1) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.1) 50%, rgba(255, 255, 255, 0.1) 75%, transparent 75%, transparent);
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.1) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.1) 50%, rgba(255, 255, 255, 0.1) 75%, transparent 75%, transparent);
  background-size: 10px 10px;
}
.theme-fortunix .ui-tabs-nav > .ui-tabs-active > a,
.theme-fortunix .ui-tabs-nav > .ui-tabs-active > a:hover,
.theme-fortunix .ui-tabs-nav > .ui-tabs-active > a:focus {
  color: #fff;
  background: #0072c6;
  border-bottom: 2px solid #0063ad;
}
.theme-fortunix .ui-datepicker .ui-state-active {
  background: #0072c6 !important;
}
.theme-fortunix .ui-datepicker-title {
  background: #0072c6;
}
.theme-fortunix .ui-datepicker th {
  background: #11619b;
}
.theme-fortunix .ui-datepicker-buttonpane button.ui-priority-primary {
  color: #fff;
}
.theme-fortunix .ui-datepicker-buttonpane button.ui-priority-primary,
.theme-fortunix .ui-datepicker-buttonpane button.ui-priority-primary:focus {
  border-color: #0063ad;
  border-bottom-color: #00528e;
  background: #0072c6;
  background-image: -webkit-linear-gradient(top, #0075cb 0%, #0063ad 100%);
  background-image: linear-gradient(to bottom, #0075cb 0%, #0063ad 100%);
  background-repeat: repeat-x;
}
.theme-fortunix .ui-datepicker-buttonpane button.ui-priority-primary:hover {
  color: #fff;
  border-color: #005b9d;
  border-bottom-color: #003d6a;
  background: #0063ad !important;
  background-image: -webkit-linear-gradient(top, #006cbc 0%, #005b9d 100%) !important;
  background-image: linear-gradient(to bottom, #006cbc 0%, #005b9d 100%) !important;
  background-repeat: repeat-x;
}
.theme-fortunix .ui-datepicker-buttonpane button.ui-priority-primary:active,
.theme-fortunix .ui-datepicker-buttonpane button.ui-priority-primary.active,
.open .theme-fortunix .ui-datepicker-buttonpane button.ui-priority-primary.dropdown-toggle {
  background: none;
  background: #0066b2 !important;
  border-color: #00497f;
  border-bottom-color: #00497f !important;
  color: #fff;
  -webkit-box-shadow: 0 0 12px rgba(0, 0, 0, 0.1) inset;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.1) inset;
}
.theme-fortunix .ui-datepicker-buttonpane button.ui-priority-primary.btn-flat {
  background: #0069b7;
}
.theme-fortunix .ui-datepicker-buttonpane button.ui-priority-primary.btn-flat:hover,
.theme-fortunix .ui-datepicker-buttonpane button.ui-priority-primary.btn-flat:active,
.theme-fortunix .ui-datepicker-buttonpane button.ui-priority-primary.btn-flat.active,
.open .theme-fortunix .ui-datepicker-buttonpane button.ui-priority-primary.btn-flat.dropdown-toggle {
  background: #0069b7 !important;
  color: #fff;
}
.theme-fortunix .ui-datepicker-buttonpane button.ui-priority-primary.btn-outline:hover,
.theme-fortunix .ui-datepicker-buttonpane button.ui-priority-primary.btn-outline.btn-flat:hover {
  color: #fff;
  border-color: #005b9d;
  border-bottom-color: #003d6a;
}
.theme-fortunix .ui-datepicker-buttonpane button.ui-priority-primary.btn-outline:active,
.theme-fortunix .ui-datepicker-buttonpane button.ui-priority-primary.btn-outline.btn-flat:active {
  border-color: #00497f;
}
.btn-group .theme-fortunix .ui-datepicker-buttonpane button.ui-priority-primary,
.btn-group .theme-fortunix .ui-datepicker-buttonpane button.ui-priority-primary:focus,
.input-group-btn .theme-fortunix .ui-datepicker-buttonpane button.ui-priority-primary,
.input-group-btn .theme-fortunix .ui-datepicker-buttonpane button.ui-priority-primary:focus {
  border-left-color: #005b9d;
  border-right-color: #005b9d;
}
.btn-group .theme-fortunix .ui-datepicker-buttonpane button.ui-priority-primary:hover,
.btn-group.open .theme-fortunix .ui-datepicker-buttonpane button.ui-priority-primary.dropdown-toggle,
.input-group-btn .theme-fortunix .ui-datepicker-buttonpane button.ui-priority-primary:hover,
.input-group-btn.open .theme-fortunix .ui-datepicker-buttonpane button.ui-priority-primary.dropdown-toggle {
  border-color: #005b9d;
  border-bottom-color: #003d6a;
}
.btn-group .theme-fortunix .ui-datepicker-buttonpane button.ui-priority-primary:active,
.btn-group .theme-fortunix .ui-datepicker-buttonpane button.ui-priority-primary.active,
.input-group-btn .theme-fortunix .ui-datepicker-buttonpane button.ui-priority-primary:active,
.input-group-btn .theme-fortunix .ui-datepicker-buttonpane button.ui-priority-primary.active {
  border-left-color: #00497f;
  border-right-color: #00497f;
}
.theme-fortunix .select2-drop-active,
.theme-fortunix .select2-container-multi.select2-container-active .select2-choices,
.theme-fortunix .select2-container-multi.select2-dropdown-open .select2-choices {
  border: 1px solid #0072c6;
}
.theme-fortunix .select2-drop.select2-drop-above.select2-drop-active {
  border-top: 1px solid #0072c6;
}
.theme-fortunix .select2-container-active .select2-choice,
.theme-fortunix .select2-container-active .select2-choices {
  border: 1px solid #0072c6;
}
.theme-fortunix .select2-dropdown-open .select2-drop-above .select2-choice,
.theme-fortunix .select2-dropdown-open .select2-drop-above .select2-choices {
  border: 1px solid #0072c6;
  border-top-color: #aaa;
}
.theme-fortunix .select2-results .select2-highlighted {
  background: #0072c6;
}
.theme-fortunix .select2-primary .select2-container-multi .select2-choices .select2-search-choice {
  background: #0072c6;
}
.theme-fortunix .select2-primary .select2-container-multi .select2-choices .select2-search-choice-focus {
  background: #005593;
}
.theme-fortunix .select2-primary .select2-container-multi.select2-container-disabled .select2-choices .select2-search-choice {
  background: #0072c6 !important;
}
.theme-fortunix .switcher-primary .switcher-state-on {
  background: #0072c6 !important;
}
.theme-fortunix .pixel-file-input:hover {
  border-color: #0072c6;
}
.theme-fortunix .datepicker table tr td.selected,
.theme-fortunix .datepicker table tr td.selected:hover,
.theme-fortunix .datepicker table tr td.selected.disabled,
.theme-fortunix .datepicker table tr td.selected.disabled:hover {
  background: #0072c6;
}
.theme-fortunix .datepicker table tr td.active,
.theme-fortunix .datepicker table tr td.active:hover,
.theme-fortunix .datepicker table tr td.active.disabled,
.theme-fortunix .datepicker table tr td.active.disabled:hover {
  background: #0072c6;
}
.theme-fortunix .datepicker table tr td span.active,
.theme-fortunix .datepicker table tr td span.active:hover,
.theme-fortunix .datepicker table tr td span.active.disabled,
.theme-fortunix .datepicker table tr td span.active.disabled:hover {
  background: #0072c6;
}
.theme-fortunix .datepicker thead tr {
  background: #11619b;
}
.theme-fortunix .datepicker thead tr:first-child {
  background: #0072c6 !important;
  border-bottom-color: #154c74 !important;
}
.theme-fortunix .datepicker thead th.prev,
.theme-fortunix .datepicker thead th.next {
  background: #0072c6;
}
.theme-fortunix .datepicker thead th.prev {
  border-color: #154c74;
}
.theme-fortunix .datepicker thead th.next {
  border-color: #154c74;
}
.theme-fortunix .datepicker > .datepicker-days thead tr:first-child th.cw + .prev {
  border-color: #154c74;
}
.theme-fortunix .datepicker > .datepicker-days thead tr:last-child th.cw + th {
  border-color: #154c74;
}
.theme-fortunix .bootstrap-timepicker-widget table td a:hover {
  background: #0072c6;
  border-color: #0072c6;
}
.theme-fortunix .pagination a.dp-today,
.theme-fortunix .pagination a.dp-today:hover {
  background: #008ff9;
  border-color: #0081e0;
}
.theme-fortunix .pagination a.dp-today:hover {
  border-color: #0072c6 !important;
}
.theme-fortunix .pagination a.dp-selected {
  background: #0072c6 !important;
  border-color: #0072c6 !important;
}
.theme-fortunix .tt-suggestion.tt-is-under-cursor {
  background: #0072c6;
}
.theme-fortunix .table-primary .dataTables_wrapper .DT-per-page {
  border-color: #0a4d7f !important;
}
.theme-fortunix .dropzone-box.dz-drag-hover {
  border-color: #0072c6;
}
.theme-fortunix .dropzone-box:hover .fa.fa-cloud-upload {
  background: #0072c6;
}
.theme-fortunix .md-editor.active {
  border-color: #0072c6;
}
.theme-fortunix .widget-rating .active a {
  color: #0072c6;
}
.theme-konektifa {
  background-color: #f0f3f4;
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-size: 13px;
  color: #58666e;
  background-color: transparent;
  -webkit-font-smoothing: antialiased;
  line-height: 1.42857143;
}
.theme-konektifa .bg-gd {
  background-image: -webkit-gradient(linear, left 0, left 100%, from(rgba(40, 50, 60, 0)), to(rgba(40, 50, 60, 0.075)));
  background-image: -webkit-linear-gradient(top, rgba(40, 50, 60, 0), 0, rgba(40, 50, 60, 0.075), 100%);
  background-image: -moz-linear-gradient(top, rgba(40, 50, 60, 0) 0, rgba(40, 50, 60, 0.075) 100%);
  background-image: linear-gradient(to bottom, rgba(40, 50, 60, 0) 0, rgba(40, 50, 60, 0.075) 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#0028323c', endColorstr='#1328323c', GradientType=0);
  filter: none;
}
.theme-konektifa .bg-gd-dk {
  background-image: -webkit-gradient(linear, left 10%, left 100%, from(rgba(40, 50, 60, 0)), to(rgba(40, 50, 60, 0.5)));
  background-image: -webkit-linear-gradient(top, rgba(40, 50, 60, 0), 10%, rgba(40, 50, 60, 0.5), 100%);
  background-image: -moz-linear-gradient(top, rgba(40, 50, 60, 0) 10%, rgba(40, 50, 60, 0.5) 100%);
  background-image: linear-gradient(to bottom, rgba(40, 50, 60, 0) 10%, rgba(40, 50, 60, 0.5) 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#0028323c', endColorstr='#8028323c', GradientType=0);
  filter: none;
}
.theme-konektifa .bg-light {
  background-color: #edf1f2;
  color: #58666e;
}
.theme-konektifa .bg-light.lt,
.theme-konektifa .bg-light .lt {
  background-color: #f3f5f6;
}
.theme-konektifa .bg-light.lter,
.theme-konektifa .bg-light .lter {
  background-color: #f6f8f8;
}
.theme-konektifa .bg-light.dk,
.theme-konektifa .bg-light .dk {
  background-color: #e4eaec;
}
.theme-konektifa .bg-light.dker,
.theme-konektifa .bg-light .dker {
  background-color: #dde6e9;
}
.theme-konektifa .bg-light.bg,
.theme-konektifa .bg-light .bg {
  background-color: #edf1f2;
}
.theme-konektifa .bg-dark {
  background-color: #3a3f51;
  color: #a6a8b1;
}
.theme-konektifa .bg-dark.lt,
.theme-konektifa .bg-dark .lt {
  background-color: #474c5e;
}
.theme-konektifa .bg-dark.lter,
.theme-konektifa .bg-dark .lter {
  background-color: #54596a;
}
.theme-konektifa .bg-dark.dk,
.theme-konektifa .bg-dark .dk {
  background-color: #2e3344;
}
.theme-konektifa .bg-dark.dker,
.theme-konektifa .bg-dark .dker {
  background-color: #232735;
}
.theme-konektifa .bg-dark.bg,
.theme-konektifa .bg-dark .bg {
  background-color: #3a3f51;
}
.theme-konektifa .bg-dark a {
  color: #c1c3c9;
}
.theme-konektifa .bg-dark a:hover {
  color: #fff;
}
.theme-konektifa .bg-dark a.list-group-item:hover,
.theme-konektifa .bg-dark a.list-group-item:focus {
  background-color: inherit;
}
.theme-konektifa .bg-dark .nav > li:hover > a,
.theme-konektifa .bg-dark .nav > li:focus > a,
.theme-konektifa .bg-dark .nav > li.active > a {
  color: #fff;
  background-color: #2e3344;
}
.theme-konektifa .bg-dark .nav > li > a {
  color: #b4b6bd;
}
.theme-konektifa .bg-dark .nav > li > a:hover,
.theme-konektifa .bg-dark .nav > li > a:focus {
  background-color: #32374a;
}
.theme-konektifa .bg-dark .nav .open > a {
  background-color: #2e3344;
}
.theme-konektifa .bg-dark .caret {
  border-top-color: #a6a8b1;
  border-bottom-color: #a6a8b1;
}
.theme-konektifa .bg-dark.navbar .nav > li.active > a {
  color: #fff;
  background-color: #2e3344;
}
.theme-konektifa .bg-dark .open > a,
.theme-konektifa .bg-dark .open > a:hover,
.theme-konektifa .bg-dark .open > a:focus {
  color: #fff;
}
.theme-konektifa .bg-dark .text-muted {
  color: #8b8e99 !important;
}
.theme-konektifa .bg-dark .text-lt {
  color: #eaebed !important;
}
.theme-konektifa .bg-dark.auto .list-group-item,
.theme-konektifa .bg-dark .auto .list-group-item {
  border-color: #2f3342 !important;
  background-color: transparent;
}
.theme-konektifa .bg-dark.auto .list-group-item:hover,
.theme-konektifa .bg-dark .auto .list-group-item:hover,
.theme-konektifa .bg-dark.auto .list-group-item:focus,
.theme-konektifa .bg-dark .auto .list-group-item:focus,
.theme-konektifa .bg-dark.auto .list-group-item:active,
.theme-konektifa .bg-dark .auto .list-group-item:active,
.theme-konektifa .bg-dark.auto .list-group-item.active,
.theme-konektifa .bg-dark .auto .list-group-item.active {
  background-color: #2e3344 !important;
}
.theme-konektifa .bg-black {
  background-color: #1c2b36;
  color: #7793a7;
}
.theme-konektifa .bg-black.lt,
.theme-konektifa .bg-black .lt {
  background-color: #263845;
}
.theme-konektifa .bg-black.lter,
.theme-konektifa .bg-black .lter {
  background-color: #314554;
}
.theme-konektifa .bg-black.dk,
.theme-konektifa .bg-black .dk {
  background-color: #131e26;
}
.theme-konektifa .bg-black.dker,
.theme-konektifa .bg-black .dker {
  background-color: #0a1015;
}
.theme-konektifa .bg-black.bg,
.theme-konektifa .bg-black .bg {
  background-color: #1c2b36;
}
.theme-konektifa .bg-black a {
  color: #96abbb;
}
.theme-konektifa .bg-black a:hover {
  color: #fff;
}
.theme-konektifa .bg-black a.list-group-item:hover,
.theme-konektifa .bg-black a.list-group-item:focus {
  background-color: inherit;
}
.theme-konektifa .bg-black .nav > li:hover > a,
.theme-konektifa .bg-black .nav > li:focus > a,
.theme-konektifa .bg-black .nav > li.active > a {
  color: #fff;
  background-color: #131e26;
}
.theme-konektifa .bg-black .nav > li > a {
  color: #869fb1;
}
.theme-konektifa .bg-black .nav > li > a:hover,
.theme-konektifa .bg-black .nav > li > a:focus {
  background-color: #16232d;
}
.theme-konektifa .bg-black .nav .open > a {
  background-color: #131e26;
}
.theme-konektifa .bg-black .caret {
  border-top-color: #7793a7;
  border-bottom-color: #7793a7;
}
.theme-konektifa .bg-black.navbar .nav > li.active > a {
  color: #fff;
  background-color: #131e26;
}
.theme-konektifa .bg-black .open > a,
.theme-konektifa .bg-black .open > a:hover,
.theme-konektifa .bg-black .open > a:focus {
  color: #fff;
}
.theme-konektifa .bg-black .text-muted {
  color: #5c798f !important;
}
.theme-konektifa .bg-black .text-lt {
  color: #c4d0d9 !important;
}
.theme-konektifa .bg-black.auto .list-group-item,
.theme-konektifa .bg-black .auto .list-group-item {
  border-color: #131e25 !important;
  background-color: transparent;
}
.theme-konektifa .bg-black.auto .list-group-item:hover,
.theme-konektifa .bg-black .auto .list-group-item:hover,
.theme-konektifa .bg-black.auto .list-group-item:focus,
.theme-konektifa .bg-black .auto .list-group-item:focus,
.theme-konektifa .bg-black.auto .list-group-item:active,
.theme-konektifa .bg-black .auto .list-group-item:active,
.theme-konektifa .bg-black.auto .list-group-item.active,
.theme-konektifa .bg-black .auto .list-group-item.active {
  background-color: #131e26 !important;
}
.theme-konektifa .bgs-primary,
.theme-konektifa .bg-primary {
  background-color: #7266ba;
  color: #f4f3f9;
}
.theme-konektifa .bgs-primary.lt,
.theme-konektifa .bg-primary.lt,
.theme-konektifa .bgs-primary .lt,
.theme-konektifa .bg-primary .lt {
  background-color: #847abf;
}
.theme-konektifa .bgs-primary.lter,
.theme-konektifa .bg-primary.lter,
.theme-konektifa .bgs-primary .lter,
.theme-konektifa .bg-primary .lter {
  background-color: #958dc6;
}
.theme-konektifa .bgs-primary.dk,
.theme-konektifa .bg-primary.dk,
.theme-konektifa .bgs-primary .dk,
.theme-konektifa .bg-primary .dk {
  background-color: #6051b5;
}
.theme-konektifa .bgs-primary.dker,
.theme-konektifa .bg-primary.dker,
.theme-konektifa .bgs-primary .dker,
.theme-konektifa .bg-primary .dker {
  background-color: #5244a9;
}
.theme-konektifa .bgs-primary.bg,
.theme-konektifa .bg-primary.bg,
.theme-konektifa .bgs-primary .bg,
.theme-konektifa .bg-primary .bg {
  background-color: #7266ba;
}
.theme-konektifa .bgs-primary a,
.theme-konektifa .bg-primary a {
  color: #ffffff;
}
.theme-konektifa .bgs-primary a:hover,
.theme-konektifa .bg-primary a:hover {
  color: #fff;
}
.theme-konektifa .bgs-primary a.list-group-item:hover,
.theme-konektifa .bg-primary a.list-group-item:hover,
.theme-konektifa .bgs-primary a.list-group-item:focus,
.theme-konektifa .bg-primary a.list-group-item:focus {
  background-color: inherit;
}
.theme-konektifa .bgs-primary .nav > li:hover > a,
.theme-konektifa .bg-primary .nav > li:hover > a,
.theme-konektifa .bgs-primary .nav > li:focus > a,
.theme-konektifa .bg-primary .nav > li:focus > a,
.theme-konektifa .bgs-primary .nav > li.active > a,
.theme-konektifa .bg-primary .nav > li.active > a {
  color: #fff;
  background-color: #6051b5;
}
.theme-konektifa .bgs-primary .nav > li > a,
.theme-konektifa .bg-primary .nav > li > a {
  color: #f2f2f2;
}
.theme-konektifa .bgs-primary .nav > li > a:hover,
.theme-konektifa .bg-primary .nav > li > a:hover,
.theme-konektifa .bgs-primary .nav > li > a:focus,
.theme-konektifa .bg-primary .nav > li > a:focus {
  background-color: #6658b8;
}
.theme-konektifa .bgs-primary .nav .open > a,
.theme-konektifa .bg-primary .nav .open > a {
  background-color: #6051b5;
}
.theme-konektifa .bgs-primary .caret,
.theme-konektifa .bg-primary .caret {
  border-top-color: #f4f3f9;
  border-bottom-color: #f4f3f9;
}
.theme-konektifa .bgs-primary.navbar .nav > li.active > a,
.theme-konektifa .bg-primary.navbar .nav > li.active > a {
  color: #fff;
  background-color: #6051b5;
}
.theme-konektifa .bgs-primary .open > a,
.theme-konektifa .bg-primary .open > a,
.theme-konektifa .bgs-primary .open > a:hover,
.theme-konektifa .bg-primary .open > a:hover,
.theme-konektifa .bgs-primary .open > a:focus,
.theme-konektifa .bg-primary .open > a:focus {
  color: #fff;
}
.theme-konektifa .bgs-primary .text-muted,
.theme-konektifa .bg-primary .text-muted {
  color: #d6d3e6 !important;
}
.theme-konektifa .bgs-primary .text-lt,
.theme-konektifa .bg-primary .text-lt {
  color: #ffffff !important;
}
.theme-konektifa .bgs-primary.auto .list-group-item,
.theme-konektifa .bg-primary.auto .list-group-item,
.theme-konektifa .bgs-primary .auto .list-group-item,
.theme-konektifa .bg-primary .auto .list-group-item {
  border-color: #6254b2 !important;
  background-color: transparent;
}
.theme-konektifa .bgs-primary.auto .list-group-item:hover,
.theme-konektifa .bg-primary.auto .list-group-item:hover,
.theme-konektifa .bgs-primary .auto .list-group-item:hover,
.theme-konektifa .bg-primary .auto .list-group-item:hover,
.theme-konektifa .bgs-primary.auto .list-group-item:focus,
.theme-konektifa .bg-primary.auto .list-group-item:focus,
.theme-konektifa .bgs-primary .auto .list-group-item:focus,
.theme-konektifa .bg-primary .auto .list-group-item:focus,
.theme-konektifa .bgs-primary.auto .list-group-item:active,
.theme-konektifa .bg-primary.auto .list-group-item:active,
.theme-konektifa .bgs-primary .auto .list-group-item:active,
.theme-konektifa .bg-primary .auto .list-group-item:active,
.theme-konektifa .bgs-primary.auto .list-group-item.active,
.theme-konektifa .bg-primary.auto .list-group-item.active,
.theme-konektifa .bgs-primary .auto .list-group-item.active,
.theme-konektifa .bg-primary .auto .list-group-item.active {
  background-color: #6051b5 !important;
}
.theme-konektifa .bgs-success,
.theme-konektifa .bg-success {
  background-color: #27c24c;
  color: #c6efd0;
}
.theme-konektifa .bgs-success.lt,
.theme-konektifa .bg-success.lt,
.theme-konektifa .bgs-success .lt,
.theme-konektifa .bg-success .lt {
  background-color: #31d257;
}
.theme-konektifa .bgs-success.lter,
.theme-konektifa .bg-success.lter,
.theme-konektifa .bgs-success .lter,
.theme-konektifa .bg-success .lter {
  background-color: #48d46a;
}
.theme-konektifa .bgs-success.dk,
.theme-konektifa .bg-success.dk,
.theme-konektifa .bgs-success .dk,
.theme-konektifa .bg-success .dk {
  background-color: #20af42;
}
.theme-konektifa .bgs-success.dker,
.theme-konektifa .bg-success.dker,
.theme-konektifa .bgs-success .dker,
.theme-konektifa .bg-success .dker {
  background-color: #1a9c39;
}
.theme-konektifa .bgs-success.bg,
.theme-konektifa .bg-success.bg,
.theme-konektifa .bgs-success .bg,
.theme-konektifa .bg-success .bg {
  background-color: #27c24c;
}
.theme-konektifa .bgs-success a,
.theme-konektifa .bg-success a {
  color: #eefaf1;
}
.theme-konektifa .bgs-success a:hover,
.theme-konektifa .bg-success a:hover {
  color: #fff;
}
.theme-konektifa .bgs-success a.list-group-item:hover,
.theme-konektifa .bg-success a.list-group-item:hover,
.theme-konektifa .bgs-success a.list-group-item:focus,
.theme-konektifa .bg-success a.list-group-item:focus {
  background-color: inherit;
}
.theme-konektifa .bgs-success .nav > li:hover > a,
.theme-konektifa .bg-success .nav > li:hover > a,
.theme-konektifa .bgs-success .nav > li:focus > a,
.theme-konektifa .bg-success .nav > li:focus > a,
.theme-konektifa .bgs-success .nav > li.active > a,
.theme-konektifa .bg-success .nav > li.active > a {
  color: #fff;
  background-color: #20af42;
}
.theme-konektifa .bgs-success .nav > li > a,
.theme-konektifa .bg-success .nav > li > a {
  color: #daf5e0;
}
.theme-konektifa .bgs-success .nav > li > a:hover,
.theme-konektifa .bg-success .nav > li > a:hover,
.theme-konektifa .bgs-success .nav > li > a:focus,
.theme-konektifa .bg-success .nav > li > a:focus {
  background-color: #22b846;
}
.theme-konektifa .bgs-success .nav .open > a,
.theme-konektifa .bg-success .nav .open > a {
  background-color: #20af42;
}
.theme-konektifa .bgs-success .caret,
.theme-konektifa .bg-success .caret {
  border-top-color: #c6efd0;
  border-bottom-color: #c6efd0;
}
.theme-konektifa .bgs-success.navbar .nav > li.active > a,
.theme-konektifa .bg-success.navbar .nav > li.active > a {
  color: #fff;
  background-color: #20af42;
}
.theme-konektifa .bgs-success .open > a,
.theme-konektifa .bg-success .open > a,
.theme-konektifa .bgs-success .open > a:hover,
.theme-konektifa .bg-success .open > a:hover,
.theme-konektifa .bgs-success .open > a:focus,
.theme-konektifa .bg-success .open > a:focus {
  color: #fff;
}
.theme-konektifa .bgs-success .text-muted,
.theme-konektifa .bg-success .text-muted {
  color: #9ee4af !important;
}
.theme-konektifa .bgs-success .text-lt,
.theme-konektifa .bg-success .text-lt {
  color: #ffffff !important;
}
.theme-konektifa .bgs-success.auto .list-group-item,
.theme-konektifa .bg-success.auto .list-group-item,
.theme-konektifa .bgs-success .auto .list-group-item,
.theme-konektifa .bg-success .auto .list-group-item {
  border-color: #23ad44 !important;
  background-color: transparent;
}
.theme-konektifa .bgs-success.auto .list-group-item:hover,
.theme-konektifa .bg-success.auto .list-group-item:hover,
.theme-konektifa .bgs-success .auto .list-group-item:hover,
.theme-konektifa .bg-success .auto .list-group-item:hover,
.theme-konektifa .bgs-success.auto .list-group-item:focus,
.theme-konektifa .bg-success.auto .list-group-item:focus,
.theme-konektifa .bgs-success .auto .list-group-item:focus,
.theme-konektifa .bg-success .auto .list-group-item:focus,
.theme-konektifa .bgs-success.auto .list-group-item:active,
.theme-konektifa .bg-success.auto .list-group-item:active,
.theme-konektifa .bgs-success .auto .list-group-item:active,
.theme-konektifa .bg-success .auto .list-group-item:active,
.theme-konektifa .bgs-success.auto .list-group-item.active,
.theme-konektifa .bg-success.auto .list-group-item.active,
.theme-konektifa .bgs-success .auto .list-group-item.active,
.theme-konektifa .bg-success .auto .list-group-item.active {
  background-color: #20af42 !important;
}
.theme-konektifa .bgs-info,
.theme-konektifa .bg-info {
  background-color: #23b7e5;
  color: #dcf2f8;
}
.theme-konektifa .bgs-info.lt,
.theme-konektifa .bg-info.lt,
.theme-konektifa .bgs-info .lt,
.theme-konektifa .bg-info .lt {
  background-color: #3dbde5;
}
.theme-konektifa .bgs-info.lter,
.theme-konektifa .bg-info.lter,
.theme-konektifa .bgs-info .lter,
.theme-konektifa .bg-info .lter {
  background-color: #55c3e6;
}
.theme-konektifa .bgs-info.dk,
.theme-konektifa .bg-info.dk,
.theme-konektifa .bgs-info .dk,
.theme-konektifa .bg-info .dk {
  background-color: #16aad8;
}
.theme-konektifa .bgs-info.dker,
.theme-konektifa .bg-info.dker,
.theme-konektifa .bgs-info .dker,
.theme-konektifa .bg-info .dker {
  background-color: #1199c4;
}
.theme-konektifa .bgs-info.bg,
.theme-konektifa .bg-info.bg,
.theme-konektifa .bgs-info .bg,
.theme-konektifa .bg-info .bg {
  background-color: #23b7e5;
}
.theme-konektifa .bgs-info a,
.theme-konektifa .bg-info a {
  color: #ffffff;
}
.theme-konektifa .bgs-info a:hover,
.theme-konektifa .bg-info a:hover {
  color: #fff;
}
.theme-konektifa .bgs-info a.list-group-item:hover,
.theme-konektifa .bg-info a.list-group-item:hover,
.theme-konektifa .bgs-info a.list-group-item:focus,
.theme-konektifa .bg-info a.list-group-item:focus {
  background-color: inherit;
}
.theme-konektifa .bgs-info .nav > li:hover > a,
.theme-konektifa .bg-info .nav > li:hover > a,
.theme-konektifa .bgs-info .nav > li:focus > a,
.theme-konektifa .bg-info .nav > li:focus > a,
.theme-konektifa .bgs-info .nav > li.active > a,
.theme-konektifa .bg-info .nav > li.active > a {
  color: #fff;
  background-color: #16aad8;
}
.theme-konektifa .bgs-info .nav > li > a,
.theme-konektifa .bg-info .nav > li > a {
  color: #f2f2f2;
}
.theme-konektifa .bgs-info .nav > li > a:hover,
.theme-konektifa .bg-info .nav > li > a:hover,
.theme-konektifa .bgs-info .nav > li > a:focus,
.theme-konektifa .bg-info .nav > li > a:focus {
  background-color: #17b2e2;
}
.theme-konektifa .bgs-info .nav .open > a,
.theme-konektifa .bg-info .nav .open > a {
  background-color: #16aad8;
}
.theme-konektifa .bgs-info .caret,
.theme-konektifa .bg-info .caret {
  border-top-color: #dcf2f8;
  border-bottom-color: #dcf2f8;
}
.theme-konektifa .bgs-info.navbar .nav > li.active > a,
.theme-konektifa .bg-info.navbar .nav > li.active > a {
  color: #fff;
  background-color: #16aad8;
}
.theme-konektifa .bgs-info .open > a,
.theme-konektifa .bg-info .open > a,
.theme-konektifa .bgs-info .open > a:hover,
.theme-konektifa .bg-info .open > a:hover,
.theme-konektifa .bgs-info .open > a:focus,
.theme-konektifa .bg-info .open > a:focus {
  color: #fff;
}
.theme-konektifa .bgs-info .text-muted,
.theme-konektifa .bg-info .text-muted {
  color: #b0e1f1 !important;
}
.theme-konektifa .bgs-info .text-lt,
.theme-konektifa .bg-info .text-lt {
  color: #ffffff !important;
}
.theme-konektifa .bgs-info.auto .list-group-item,
.theme-konektifa .bg-info.auto .list-group-item,
.theme-konektifa .bgs-info .auto .list-group-item,
.theme-konektifa .bg-info .auto .list-group-item {
  border-color: #19a9d5 !important;
  background-color: transparent;
}
.theme-konektifa .bgs-info.auto .list-group-item:hover,
.theme-konektifa .bg-info.auto .list-group-item:hover,
.theme-konektifa .bgs-info .auto .list-group-item:hover,
.theme-konektifa .bg-info .auto .list-group-item:hover,
.theme-konektifa .bgs-info.auto .list-group-item:focus,
.theme-konektifa .bg-info.auto .list-group-item:focus,
.theme-konektifa .bgs-info .auto .list-group-item:focus,
.theme-konektifa .bg-info .auto .list-group-item:focus,
.theme-konektifa .bgs-info.auto .list-group-item:active,
.theme-konektifa .bg-info.auto .list-group-item:active,
.theme-konektifa .bgs-info .auto .list-group-item:active,
.theme-konektifa .bg-info .auto .list-group-item:active,
.theme-konektifa .bgs-info.auto .list-group-item.active,
.theme-konektifa .bg-info.auto .list-group-item.active,
.theme-konektifa .bgs-info .auto .list-group-item.active,
.theme-konektifa .bg-info .auto .list-group-item.active {
  background-color: #16aad8 !important;
}
.theme-konektifa .bgs-warning,
.theme-konektifa .bg-warning {
  background-color: #fad733;
  color: #fffefa;
}
.theme-konektifa .bgs-warning.lt,
.theme-konektifa .bg-warning.lt,
.theme-konektifa .bgs-warning .lt,
.theme-konektifa .bg-warning .lt {
  background-color: #f8da4e;
}
.theme-konektifa .bgs-warning.lter,
.theme-konektifa .bg-warning.lter,
.theme-konektifa .bgs-warning .lter,
.theme-konektifa .bg-warning .lter {
  background-color: #f7de69;
}
.theme-konektifa .bgs-warning.dk,
.theme-konektifa .bg-warning.dk,
.theme-konektifa .bgs-warning .dk,
.theme-konektifa .bg-warning .dk {
  background-color: #fcd417;
}
.theme-konektifa .bgs-warning.dker,
.theme-konektifa .bg-warning.dker,
.theme-konektifa .bgs-warning .dker,
.theme-konektifa .bg-warning .dker {
  background-color: #face00;
}
.theme-konektifa .bgs-warning.bg,
.theme-konektifa .bg-warning.bg,
.theme-konektifa .bgs-warning .bg,
.theme-konektifa .bg-warning .bg {
  background-color: #fad733;
}
.theme-konektifa .bgs-warning a,
.theme-konektifa .bg-warning a {
  color: #ffffff;
}
.theme-konektifa .bgs-warning a:hover,
.theme-konektifa .bg-warning a:hover {
  color: #fff;
}
.theme-konektifa .bgs-warning a.list-group-item:hover,
.theme-konektifa .bg-warning a.list-group-item:hover,
.theme-konektifa .bgs-warning a.list-group-item:focus,
.theme-konektifa .bg-warning a.list-group-item:focus {
  background-color: inherit;
}
.theme-konektifa .bgs-warning .nav > li:hover > a,
.theme-konektifa .bg-warning .nav > li:hover > a,
.theme-konektifa .bgs-warning .nav > li:focus > a,
.theme-konektifa .bg-warning .nav > li:focus > a,
.theme-konektifa .bgs-warning .nav > li.active > a,
.theme-konektifa .bg-warning .nav > li.active > a {
  color: #fff;
  background-color: #fcd417;
}
.theme-konektifa .bgs-warning .nav > li > a,
.theme-konektifa .bg-warning .nav > li > a {
  color: #f2f2f2;
}
.theme-konektifa .bgs-warning .nav > li > a:hover,
.theme-konektifa .bg-warning .nav > li > a:hover,
.theme-konektifa .bgs-warning .nav > li > a:focus,
.theme-konektifa .bg-warning .nav > li > a:focus {
  background-color: #fcd621;
}
.theme-konektifa .bgs-warning .nav .open > a,
.theme-konektifa .bg-warning .nav .open > a {
  background-color: #fcd417;
}
.theme-konektifa .bgs-warning .caret,
.theme-konektifa .bg-warning .caret {
  border-top-color: #fffefa;
  border-bottom-color: #fffefa;
}
.theme-konektifa .bgs-warning.navbar .nav > li.active > a,
.theme-konektifa .bg-warning.navbar .nav > li.active > a {
  color: #fff;
  background-color: #fcd417;
}
.theme-konektifa .bgs-warning .open > a,
.theme-konektifa .bg-warning .open > a,
.theme-konektifa .bgs-warning .open > a:hover,
.theme-konektifa .bg-warning .open > a:hover,
.theme-konektifa .bgs-warning .open > a:focus,
.theme-konektifa .bg-warning .open > a:focus {
  color: #fff;
}
.theme-konektifa .bgs-warning .text-muted,
.theme-konektifa .bg-warning .text-muted {
  color: #fbf2cb !important;
}
.theme-konektifa .bgs-warning .text-lt,
.theme-konektifa .bg-warning .text-lt {
  color: #ffffff !important;
}
.theme-konektifa .bgs-warning.auto .list-group-item,
.theme-konektifa .bg-warning.auto .list-group-item,
.theme-konektifa .bgs-warning .auto .list-group-item,
.theme-konektifa .bg-warning .auto .list-group-item {
  border-color: #f9d21a !important;
  background-color: transparent;
}
.theme-konektifa .bgs-warning.auto .list-group-item:hover,
.theme-konektifa .bg-warning.auto .list-group-item:hover,
.theme-konektifa .bgs-warning .auto .list-group-item:hover,
.theme-konektifa .bg-warning .auto .list-group-item:hover,
.theme-konektifa .bgs-warning.auto .list-group-item:focus,
.theme-konektifa .bg-warning.auto .list-group-item:focus,
.theme-konektifa .bgs-warning .auto .list-group-item:focus,
.theme-konektifa .bg-warning .auto .list-group-item:focus,
.theme-konektifa .bgs-warning.auto .list-group-item:active,
.theme-konektifa .bg-warning.auto .list-group-item:active,
.theme-konektifa .bgs-warning .auto .list-group-item:active,
.theme-konektifa .bg-warning .auto .list-group-item:active,
.theme-konektifa .bgs-warning.auto .list-group-item.active,
.theme-konektifa .bg-warning.auto .list-group-item.active,
.theme-konektifa .bgs-warning .auto .list-group-item.active,
.theme-konektifa .bg-warning .auto .list-group-item.active {
  background-color: #fcd417 !important;
}
.theme-konektifa .bgs-danger,
.theme-konektifa .bg-danger {
  background-color: #f05050;
  color: #ffffff;
}
.theme-konektifa .bgs-danger.lt,
.theme-konektifa .bg-danger.lt,
.theme-konektifa .bgs-danger .lt,
.theme-konektifa .bg-danger .lt {
  background-color: #f06a6a;
}
.theme-konektifa .bgs-danger.lter,
.theme-konektifa .bg-danger.lter,
.theme-konektifa .bgs-danger .lter,
.theme-konektifa .bg-danger .lter {
  background-color: #f18282;
}
.theme-konektifa .bgs-danger.dk,
.theme-konektifa .bg-danger.dk,
.theme-konektifa .bgs-danger .dk,
.theme-konektifa .bg-danger .dk {
  background-color: #f13636;
}
.theme-konektifa .bgs-danger.dker,
.theme-konektifa .bg-danger.dker,
.theme-konektifa .bgs-danger .dker,
.theme-konektifa .bg-danger .dker {
  background-color: #f21b1b;
}
.theme-konektifa .bgs-danger.bg,
.theme-konektifa .bg-danger.bg,
.theme-konektifa .bgs-danger .bg,
.theme-konektifa .bg-danger .bg {
  background-color: #f05050;
}
.theme-konektifa .bgs-danger a,
.theme-konektifa .bg-danger a {
  color: #ffffff;
}
.theme-konektifa .bgs-danger a:hover,
.theme-konektifa .bg-danger a:hover {
  color: #fff;
}
.theme-konektifa .bgs-danger a.list-group-item:hover,
.theme-konektifa .bg-danger a.list-group-item:hover,
.theme-konektifa .bgs-danger a.list-group-item:focus,
.theme-konektifa .bg-danger a.list-group-item:focus {
  background-color: inherit;
}
.theme-konektifa .bgs-danger .nav > li:hover > a,
.theme-konektifa .bg-danger .nav > li:hover > a,
.theme-konektifa .bgs-danger .nav > li:focus > a,
.theme-konektifa .bg-danger .nav > li:focus > a,
.theme-konektifa .bgs-danger .nav > li.active > a,
.theme-konektifa .bg-danger .nav > li.active > a {
  color: #fff;
  background-color: #f13636;
}
.theme-konektifa .bgs-danger .nav > li > a,
.theme-konektifa .bg-danger .nav > li > a {
  color: #f2f2f2;
}
.theme-konektifa .bgs-danger .nav > li > a:hover,
.theme-konektifa .bg-danger .nav > li > a:hover,
.theme-konektifa .bgs-danger .nav > li > a:focus,
.theme-konektifa .bg-danger .nav > li > a:focus {
  background-color: #f13f3f;
}
.theme-konektifa .bgs-danger .nav .open > a,
.theme-konektifa .bg-danger .nav .open > a {
  background-color: #f13636;
}
.theme-konektifa .bgs-danger .caret,
.theme-konektifa .bg-danger .caret {
  border-top-color: #ffffff;
  border-bottom-color: #ffffff;
}
.theme-konektifa .bgs-danger.navbar .nav > li.active > a,
.theme-konektifa .bg-danger.navbar .nav > li.active > a {
  color: #fff;
  background-color: #f13636;
}
.theme-konektifa .bgs-danger .open > a,
.theme-konektifa .bg-danger .open > a,
.theme-konektifa .bgs-danger .open > a:hover,
.theme-konektifa .bg-danger .open > a:hover,
.theme-konektifa .bgs-danger .open > a:focus,
.theme-konektifa .bg-danger .open > a:focus {
  color: #fff;
}
.theme-konektifa .bgs-danger .text-muted,
.theme-konektifa .bg-danger .text-muted {
  color: #e6e6e6 !important;
}
.theme-konektifa .bgs-danger .text-lt,
.theme-konektifa .bg-danger .text-lt {
  color: #ffffff !important;
}
.theme-konektifa .bgs-danger.auto .list-group-item,
.theme-konektifa .bg-danger.auto .list-group-item,
.theme-konektifa .bgs-danger .auto .list-group-item,
.theme-konektifa .bg-danger .auto .list-group-item {
  border-color: #ee3939 !important;
  background-color: transparent;
}
.theme-konektifa .bgs-danger.auto .list-group-item:hover,
.theme-konektifa .bg-danger.auto .list-group-item:hover,
.theme-konektifa .bgs-danger .auto .list-group-item:hover,
.theme-konektifa .bg-danger .auto .list-group-item:hover,
.theme-konektifa .bgs-danger.auto .list-group-item:focus,
.theme-konektifa .bg-danger.auto .list-group-item:focus,
.theme-konektifa .bgs-danger .auto .list-group-item:focus,
.theme-konektifa .bg-danger .auto .list-group-item:focus,
.theme-konektifa .bgs-danger.auto .list-group-item:active,
.theme-konektifa .bg-danger.auto .list-group-item:active,
.theme-konektifa .bgs-danger .auto .list-group-item:active,
.theme-konektifa .bg-danger .auto .list-group-item:active,
.theme-konektifa .bgs-danger.auto .list-group-item.active,
.theme-konektifa .bg-danger.auto .list-group-item.active,
.theme-konektifa .bgs-danger .auto .list-group-item.active,
.theme-konektifa .bg-danger .auto .list-group-item.active {
  background-color: #f13636 !important;
}
.theme-konektifa .bg-white {
  background-color: #fff;
  color: #58666e;
}
.theme-konektifa .bg-white a {
  color: #363f44;
}
.theme-konektifa .bg-white a:hover {
  color: #1f2427;
}
.theme-konektifa .bg-white .text-muted {
  color: #98a6ad !important;
}
.theme-konektifa .bg-white .lt,
.theme-konektifa .bg-white .lter,
.theme-konektifa .bg-white .dk,
.theme-konektifa .bg-white .dker {
  background-color: #fff;
}
.theme-konektifa .bg-white-only {
  background-color: #fff;
}
.theme-konektifa .bg-white-opacity {
  background-color: rgba(255, 255, 255, 0.5);
}
.theme-konektifa .bg-black-opacity {
  background-color: rgba(32, 43, 54, 0.5);
}
.theme-konektifa a.bg-light:hover {
  color: #363f44;
}
.theme-konektifa a.bg-primary:hover {
  background-color: #6254b2;
}
.theme-konektifa a.text-primary:hover {
  color: #6254b2;
}
.theme-konektifa .text-primary {
  color: #7266ba;
}
.theme-konektifa .text-primary-lt {
  color: #8278c2;
}
.theme-konektifa .text-primary-lter {
  color: #9289ca;
}
.theme-konektifa .text-primary-dk {
  color: #6254b2;
}
.theme-konektifa .text-primary-dker {
  color: #564aa3;
}
.theme-konektifa a.bg-info:hover {
  background-color: #19a9d5;
}
.theme-konektifa a.text-info:hover {
  color: #19a9d5;
}
.theme-konektifa .text-info {
  color: #23b7e5;
}
.theme-konektifa .text-info-lt {
  color: #3abee8;
}
.theme-konektifa .text-info-lter {
  color: #51c6ea;
}
.theme-konektifa .text-info-dk {
  color: #19a9d5;
}
.theme-konektifa .text-info-dker {
  color: #1797be;
}
.theme-konektifa a.bg-success:hover {
  background-color: #23ad44;
}
.theme-konektifa a.text-success:hover {
  color: #23ad44;
}
.theme-konektifa .text-success {
  color: #27c24c;
}
.theme-konektifa .text-success-lt {
  color: #2ed556;
}
.theme-konektifa .text-success-lter {
  color: #43d967;
}
.theme-konektifa .text-success-dk {
  color: #23ad44;
}
.theme-konektifa .text-success-dker {
  color: #1e983b;
}
.theme-konektifa a.bg-warning:hover {
  background-color: #f9d21a;
}
.theme-konektifa a.text-warning:hover {
  color: #f9d21a;
}
.theme-konektifa .text-warning {
  color: #fad733;
}
.theme-konektifa .text-warning-lt {
  color: #fbdc4c;
}
.theme-konektifa .text-warning-lter {
  color: #fbe165;
}
.theme-konektifa .text-warning-dk {
  color: #f9d21a;
}
.theme-konektifa .text-warning-dker {
  color: #f4ca06;
}
.theme-konektifa a.bg-danger:hover {
  background-color: #ee3939;
}
.theme-konektifa a.text-danger:hover {
  color: #ee3939;
}
.theme-konektifa .text-danger {
  color: #f05050;
}
.theme-konektifa .text-danger-lt {
  color: #f26767;
}
.theme-konektifa .text-danger-lter {
  color: #f47f7f;
}
.theme-konektifa .text-danger-dk {
  color: #ee3939;
}
.theme-konektifa .text-danger-dker {
  color: #ec2121;
}
.theme-konektifa a.bg-dark:hover {
  background-color: #2f3342;
}
.theme-konektifa a.text-dark:hover {
  color: #2f3342;
}
.theme-konektifa .text-dark {
  color: #3a3f51;
}
.theme-konektifa .text-dark-lt {
  color: #454b60;
}
.theme-konektifa .text-dark-lter {
  color: #4f566f;
}
.theme-konektifa .text-dark-dk {
  color: #2f3342;
}
.theme-konektifa .text-dark-dker {
  color: #252833;
}
.theme-konektifa a.bg-black:hover {
  background-color: #131e25;
}
.theme-konektifa a.text-black:hover {
  color: #131e25;
}
.theme-konektifa .text-black {
  color: #1c2b36;
}
.theme-konektifa .text-black-lt {
  color: #253847;
}
.theme-konektifa .text-black-lter {
  color: #2d4658;
}
.theme-konektifa .text-black-dk {
  color: #131e25;
}
.theme-konektifa .text-black-dker {
  color: #0b1014;
}
.theme-konektifa .text-white {
  color: #fff;
}
.theme-konektifa .text-black {
  color: #000;
}
.theme-konektifa .text-muted {
  color: #98a6ad;
}
.theme-konektifa h1,
.theme-konektifa h2,
.theme-konektifa h3,
.theme-konektifa h4,
.theme-konektifa h5,
.theme-konektifa h6 {
  margin: 0;
  font-weight: 400;
  color: #4B4D4F;
  text-rendering: optimizelegibility;
  font-family: "Raleway", "Open Sans", Helvetica, Arial, sans-serif;
}
.theme-konektifa h4,
.theme-konektifa h5,
.theme-konektifa h6 {
  line-height: 18px;
}
.theme-konektifa h1 {
  font-size: 24px;
  line-height: normal;
}
.theme-konektifa h2 {
  font-size: 20px;
  line-height: normal;
}
.theme-konektifa h3 {
  line-height: normal;
  font-size: 18px;
}
.theme-konektifa h4 {
  font-size: 14px;
}
.theme-konektifa h5 {
  font-size: 12px;
}
.theme-konektifa h6 {
  font-size: 11px;
  color: #999999;
  text-transform: uppercase;
}
.theme-konektifa #main-menu-bg {
  background-color: #3a3f51;
}
.theme-konektifa #main-menu {
  padding-top: 30px;
  background-color: #3a3f51;
  color: #a6a8b1;
}
.theme-konektifa #main-menu.lt,
.theme-konektifa #main-menu .lt {
  background-color: #474c5e;
}
.theme-konektifa #main-menu.lter,
.theme-konektifa #main-menu .lter {
  background-color: #54596a;
}
.theme-konektifa #main-menu.dk,
.theme-konektifa #main-menu .dk {
  background-color: #2e3344;
}
.theme-konektifa #main-menu.dker,
.theme-konektifa #main-menu .dker {
  background-color: #232735;
}
.theme-konektifa #main-menu.bg,
.theme-konektifa #main-menu .bg {
  background-color: #3a3f51;
}
.theme-konektifa #main-menu a {
  color: #c1c3c9;
}
.theme-konektifa #main-menu a:hover {
  color: #fff;
}
.theme-konektifa #main-menu a.list-group-item:hover,
.theme-konektifa #main-menu a.list-group-item:focus {
  background-color: inherit;
}
.theme-konektifa #main-menu .nav > li:hover > a,
.theme-konektifa #main-menu .nav > li:focus > a,
.theme-konektifa #main-menu .nav > li.active > a {
  color: #fff;
  background-color: #2e3344;
}
.theme-konektifa #main-menu .nav > li > a {
  color: #b4b6bd;
}
.theme-konektifa #main-menu .nav > li > a:hover,
.theme-konektifa #main-menu .nav > li > a:focus {
  background-color: #32374a;
}
.theme-konektifa #main-menu .nav .open > a {
  background-color: #2e3344;
}
.theme-konektifa #main-menu .caret {
  border-top-color: #a6a8b1;
  border-bottom-color: #a6a8b1;
}
.theme-konektifa #main-menu.navbar .nav > li.active > a {
  color: #fff;
  background-color: #2e3344;
}
.theme-konektifa #main-menu .open > a,
.theme-konektifa #main-menu .open > a:hover,
.theme-konektifa #main-menu .open > a:focus {
  color: #fff;
}
.theme-konektifa #main-menu .text-muted {
  color: #8b8e99 !important;
}
.theme-konektifa #main-menu .text-lt {
  color: #eaebed !important;
}
.theme-konektifa #main-menu.auto .list-group-item,
.theme-konektifa #main-menu .auto .list-group-item {
  border-color: #2f3342 !important;
  background-color: transparent;
}
.theme-konektifa #main-menu.auto .list-group-item:hover,
.theme-konektifa #main-menu .auto .list-group-item:hover,
.theme-konektifa #main-menu.auto .list-group-item:focus,
.theme-konektifa #main-menu .auto .list-group-item:focus,
.theme-konektifa #main-menu.auto .list-group-item:active,
.theme-konektifa #main-menu .auto .list-group-item:active,
.theme-konektifa #main-menu.auto .list-group-item.active,
.theme-konektifa #main-menu .auto .list-group-item.active {
  background-color: #2e3344 !important;
}
.theme-konektifa #main-menu .menu-logo {
  background: #6254b2;
  padding: 10px 0;
}
.theme-konektifa #main-menu .navigation a,
.theme-konektifa #main-menu .mmc-dropdown-open-ul a {
  font-family: "Raleway", "Open Sans", Helvetica, Arial, sans-serif;
  color: #b0b8be;
  position: relative;
  -webkit-transition: background-color 0.2s ease-in-out 0s;
  transition: background-color 0.2s ease-in-out 0s;
}
.theme-konektifa #main-menu .navigation a:after,
.theme-konektifa #main-menu .mmc-dropdown-open-ul a:after {
  content: "";
  top: 0;
  bottom: 0;
  width: 1px;
  position: absolute;
  right: auto;
  background: #7266ba;
  display: none;
  left: 0;
}
.theme-konektifa #main-menu .navigation a:hover,
.theme-konektifa #main-menu .mmc-dropdown-open-ul a:hover {
  color: #fff;
}
.theme-konektifa #main-menu .navigation a:hover:after,
.theme-konektifa #main-menu .mmc-dropdown-open-ul a:hover:after {
  display: block !important;
}
.theme-konektifa #main-menu .navigation {
  -webkit-box-shadow: 0 1px 0 #3a3f51;
  box-shadow: 0 1px 0 #3a3f51;
  border: none;
}
.theme-konektifa #main-menu .navigation li {
  border-bottom: 1px solid #2f3342;
  border-top: 1px solid #40465a;
}
.theme-konektifa #main-menu .navigation li ul > li {
  border-top: 1px solid #363a4b;
  border-bottom: none;
}
.theme-konektifa #main-menu .navigation li a:hover {
  background: none;
}
.theme-konektifa #main-menu .navigation li.active {
  background: none !important;
}
.theme-konektifa #main-menu .navigation li.active > a {
  color: #fff;
  border: none;
}
.theme-konektifa #main-menu .navigation li.active > a:after {
  display: block;
}
.theme-konektifa #main-menu .navigation li.active > a,
.theme-konektifa #main-menu .navigation li.active > a:hover {
  color: #fff !important;
}
.theme-konektifa #main-menu .mm-dropdown.active > a {
  background: none;
}
.theme-konektifa #main-menu .mm-dropdown.active > a:after {
  display: none;
}
.theme-konektifa #main-menu .mm-dropdown > ul,
.theme-konektifa #main-menu .mmc-dropdown-open-ul {
  background: #40465a;
}
.theme-konektifa #main-menu .mmc-dropdown-open-ul .mmc-title {
  background: #2f3342;
  color: #fff;
}
.theme-konektifa #main-menu .mmc-dropdown-open-ul li {
  border-bottom: 1px solid #363a4b;
}
.theme-konektifa #main-menu .mmc-dropdown-open > a {
  background: #2f3342 !important;
}
.theme-konektifa #main-menu .mmc-dropdown-open > a:hover:after {
  display: none !important;
}
.theme-konektifa #main-menu .menu-content {
  border-color: #454b60;
}
.theme-konektifa #main-menu .menu-content .btn-outline {
  border-color: #454b60;
  color: #9ea4b9;
}
.theme-konektifa #main-menu .menu-content .btn-outline:hover {
  color: #fff;
}
.theme-konektifa .mmc-dropdown-open .mm-dropdown.open > a,
.theme-konektifa.mme #main-menu .mm-dropdown.open > a {
  color: #fff;
}
@media (min-width: 768px) {
  .theme-konektifa #main-menu .mm-dropdown.open > a {
    color: #b0b8be;
  }
  .theme-konektifa.mmc #main-menu .navigation > .mm-dropdown.open > a {
    color: #9ea4b9;
  }
}
.theme-konektifa.right-to-left #main-menu .navigation a:after {
  left: auto;
  right: 0;
}
.theme-konektifa #content-wrapper {
  color: #58666e;
  min-height: 100vh;
  padding-top: 18px;
  background: #f0f3f4;
}
.theme-konektifa #content-wrapper .page-header {
  padding: 12px 20px;
  margin-bottom: 0;
  display: block;
  background: #F6F8F8;
  border-color: #dee5e7;
}
.theme-konektifa #content-wrapper .page-header h1 {
  font-size: 16px;
  line-height: 35px;
  color: #58666e !important;
  padding-bottom: 0;
  margin-bottom: 0;
}
.theme-konektifa #content-wrapper .page-navs .nav-tabs {
  display: block;
  margin: 0 -18px;
  padding: 0 20px;
  border-top: none;
  border-bottom: 1px solid #dee5e7;
  background-color: #f0f3f4;
}
.theme-konektifa #content-wrapper .page-navs .nav-tabs > li {
  margin-right: 10px;
  min-height: 40px;
  border-right: 1px solid #dee5e7;
  padding-right: 10px;
}
.theme-konektifa #content-wrapper .page-navs .nav-tabs > li.table-action {
  margin: 0;
  padding: 0;
  padding-top: 8px;
  float: right;
  border-right: none;
}
.theme-konektifa #content-wrapper .page-navs .nav-tabs > li > a {
  text-transform: uppercase;
  font-size: 11px;
  font-weight: 600;
  padding: 0;
  line-height: 40px;
  letter-spacing: 1.2px;
  border-bottom-color: transparent;
}
.theme-konektifa #content-wrapper .page-navs .nav-tabs > li.active > a {
  font-weight: 800;
  border-bottom-color: transparent;
}
.theme-konektifa #content-wrapper .page-navs .nav-tabs > li.active > a:hover {
  border-bottom-color: transparent;
}
.theme-konektifa #content-wrapper.light {
  background: #fff;
}
.theme-konektifa #content-wrapper.light .page-body {
  margin: 0 -18px;
}
.theme-konektifa #content-wrapper.dashboard {
  padding-right: 0px;
  display: block;
  position: relative;
}
.theme-konektifa #content-wrapper.dashboard .dashboard-aside {
  background: #fff;
  padding: 0;
  margin: 0;
  padding-top: 18px;
  height: 100vh;
  display: block;
  border-left: 1px solid #dee5e7;
}
.theme-konektifa .panel,
.theme-konektifa .panel-default {
  background: #fff;
  border-color: #dee5e7;
  border-radius: 0 0 2px 2px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
}
.theme-konektifa .panel-heading {
  background: #f6f8f8;
  border-bottom: 1px solid #edf1f2;
}
.theme-konektifa .panel-body {
  background: #fff;
}
.theme-konektifa .panel-footer {
  background: #fff;
  border-top: 1px solid #edf1f2;
}
.theme-konektifa .panel-title {
  color: #58666e;
  font-family: "Raleway", "Open Sans", Helvetica, Arial, sans-serif;
}
.theme-konektifa .table thead > tr > th,
.theme-konektifa .table tbody > tr > th,
.theme-konektifa .table tfoot > tr > th,
.theme-konektifa .table thead > tr > td,
.theme-konektifa .table tbody > tr > td,
.theme-konektifa .table tfoot > tr > td {
  color: #58666e;
}
.theme-konektifa .table thead > tr > th a,
.theme-konektifa .table tbody > tr > th a,
.theme-konektifa .table tfoot > tr > th a,
.theme-konektifa .table thead > tr > td a,
.theme-konektifa .table tbody > tr > td a,
.theme-konektifa .table tfoot > tr > td a {
  color: #58666e;
  font-weight: 600;
}
.theme-konektifa .table thead > tr > th a:hover,
.theme-konektifa .table tbody > tr > th a:hover,
.theme-konektifa .table tfoot > tr > th a:hover,
.theme-konektifa .table thead > tr > td a:hover,
.theme-konektifa .table tbody > tr > td a:hover,
.theme-konektifa .table tfoot > tr > td a:hover {
  text-decoration: underline;
}
.theme-konektifa .table thead > tr > th .btn-group ul.dropdown-menu a,
.theme-konektifa .table tbody > tr > th .btn-group ul.dropdown-menu a,
.theme-konektifa .table tfoot > tr > th .btn-group ul.dropdown-menu a,
.theme-konektifa .table thead > tr > td .btn-group ul.dropdown-menu a,
.theme-konektifa .table tbody > tr > td .btn-group ul.dropdown-menu a,
.theme-konektifa .table tfoot > tr > td .btn-group ul.dropdown-menu a {
  font-weight: normal !important;
}
.theme-konektifa .table thead > tr > th .btn-group ul.dropdown-menu a:hover,
.theme-konektifa .table tbody > tr > th .btn-group ul.dropdown-menu a:hover,
.theme-konektifa .table tfoot > tr > th .btn-group ul.dropdown-menu a:hover,
.theme-konektifa .table thead > tr > td .btn-group ul.dropdown-menu a:hover,
.theme-konektifa .table tbody > tr > td .btn-group ul.dropdown-menu a:hover,
.theme-konektifa .table tfoot > tr > td .btn-group ul.dropdown-menu a:hover {
  text-decoration: none !important;
}
.theme-konektifa .table thead > tr > th .btn-group ul.dropdown-menu a i,
.theme-konektifa .table tbody > tr > th .btn-group ul.dropdown-menu a i,
.theme-konektifa .table tfoot > tr > th .btn-group ul.dropdown-menu a i,
.theme-konektifa .table thead > tr > td .btn-group ul.dropdown-menu a i,
.theme-konektifa .table tbody > tr > td .btn-group ul.dropdown-menu a i,
.theme-konektifa .table tfoot > tr > td .btn-group ul.dropdown-menu a i {
  margin-right: 5px;
}
.theme-konektifa .table thead > tr > td,
.theme-konektifa .table tbody > tr > td,
.theme-konektifa .table tfoot > tr > td {
  border-top-color: #eaeff0;
  padding-top: 10px;
  padding-bottom: 10px;
  vertical-align: middle;
}
.theme-konektifa .table thead > tr > td:first-child,
.theme-konektifa .table tbody > tr > td:first-child,
.theme-konektifa .table tfoot > tr > td:first-child {
  padding-left: 20px;
}
.theme-konektifa .table thead > tr > td:last-child,
.theme-konektifa .table tbody > tr > td:last-child,
.theme-konektifa .table tfoot > tr > td:last-child {
  padding-right: 20px;
}
.theme-konektifa .table thead > tr > th,
.theme-konektifa .table tbody > tr > th,
.theme-konektifa .table tfoot > tr > th {
  font-weight: 600;
  background: #f6f8f8;
  color: #58666e;
  font-size: 11px;
  line-height: 20px;
  text-shadow: 0 2px 2px #ffffff;
  border-bottom: none;
}
.theme-konektifa .table thead > tr > th.clean,
.theme-konektifa .table tbody > tr > th.clean,
.theme-konektifa .table tfoot > tr > th.clean {
  font-weight: 600;
  background: #f4f4f4;
  background-image: -webkit-linear-gradient(top, #efefef 0%, #f7f7f7 100%);
  background-image: linear-gradient(to bottom, #efefef 0%, #f7f7f7 100%);
  background-repeat: repeat-x;
  color: #9b9b9b;
  font-size: 11px;
  line-height: 20px;
}
.theme-konektifa .table thead .px-single,
.theme-konektifa .table tbody .px-single,
.theme-konektifa .table tfoot .px-single {
  vertical-align: middle;
  display: block;
  padding-top: 2px;
}
.theme-konektifa .table tbody + tbody {
  border: 1px solid #eaeff0;
}
.theme-konektifa .table thead > tr > th {
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.05), 0 1px 0 rgba(0, 0, 0, 0.05);
}
.theme-konektifa .table-hover > tbody > tr > td,
.theme-konektifa .table-hover > tbody > tr > th {
  background: transparent;
  transition-property: background;
  transition-duration: 0.2s;
  transition-timing-function: linear;
}
.theme-konektifa .table-hover > tbody > tr:hover > td,
.theme-konektifa .table-hover > tbody > tr:hover > th {
  background: #f6f8f8;
}
.theme-konektifa .table-bordered {
  border-color: #eaeff0;
  border-top: none;
}
.theme-konektifa .table-bordered > thead > tr > th,
.theme-konektifa .table-bordered > tbody > tr > th,
.theme-konektifa .table-bordered > tfoot > tr > th,
.theme-konektifa .table-bordered > thead > tr > td,
.theme-konektifa .table-bordered > tbody > tr > td,
.theme-konektifa .table-bordered > tfoot > tr > td {
  border-color: #eaeff0;
}
.theme-konektifa .table-bordered > thead > tr > th,
.theme-konektifa .table-bordered > tbody > tr > th,
.theme-konektifa .table-bordered > tfoot > tr > th {
  border-top: none;
  border-bottom: 1px solid #eaeff0;
}
.theme-konektifa .table-bordered > thead > tr > th:first-child,
.theme-konektifa .table-bordered > tbody > tr > th:first-child,
.theme-konektifa .table-bordered > tfoot > tr > th:first-child,
.theme-konektifa .table-bordered > thead > tr > td:first-child,
.theme-konektifa .table-bordered > tbody > tr > td:first-child,
.theme-konektifa .table-bordered > tfoot > tr > td:first-child {
  border-left: none;
}
.theme-konektifa .table-bordered > thead > tr > td:first-child,
.theme-konektifa .table-bordered > tbody > tr > td:first-child,
.theme-konektifa .table-bordered > tfoot > tr > td:first-child {
  padding-left: 10px;
}
.theme-konektifa .table-bordered > thead > tr > th:last-child,
.theme-konektifa .table-bordered > tbody > tr > th:last-child,
.theme-konektifa .table-bordered > tfoot > tr > th:last-child,
.theme-konektifa .table-bordered > thead > tr > td:last-child,
.theme-konektifa .table-bordered > tbody > tr > td:last-child,
.theme-konektifa .table-bordered > tfoot > tr > td:last-child {
  border-right: none;
}
.theme-konektifa .table-bordered > thead > tr:last-child,
.theme-konektifa .table-bordered > tbody > tr:last-child,
.theme-konektifa .table-bordered > tfoot > tr:last-child {
  border-bottom: none;
}
.theme-konektifa .table-bordered > thead > tr:last-child > td,
.theme-konektifa .table-bordered > tbody > tr:last-child > td,
.theme-konektifa .table-bordered > tfoot > tr:last-child > td {
  border-bottom: none;
}
.theme-konektifa .table-bordered > tfoot > th,
.theme-konektifa .table-bordered > tfoot > td,
.theme-konektifa .table-bordered > tfoot > tr {
  border: none;
  padding: 0;
}
.theme-konektifa .table.table-input {
  border-top: 1px solid #c4c4c4;
  background: #FFF;
}
.theme-konektifa .table.table-input > thead > tr > th,
.theme-konektifa .table.table-input > tbody > tr > th,
.theme-konektifa .table.table-input > tfoot > tr > th,
.theme-konektifa .table.table-input > thead > tr > td,
.theme-konektifa .table.table-input > tbody > tr > td,
.theme-konektifa .table.table-input > tfoot > tr > td {
  border: 1px solid #c4c4c4;
}
.theme-konektifa .table.table-input thead tr th,
.theme-konektifa .table.table-input tfoot tr th {
  background-color: #f7f7f7;
  background-image: linear-gradient(#f7f7f7, #eee);
  color: #9b9b9b;
  font-size: 11px;
  line-height: 20px;
  font-weight: normal;
  padding-left: 20px;
  border-left: none;
  border-right: none;
  box-shadow: none;
}
.theme-konektifa .table.table-input tfoot tr th {
  background: #f7f7f7;
}
.theme-konektifa .table.table-input tbody tr td.opacity {
  opacity: 0.9;
}
.theme-konektifa .table.table-input tbody tr td {
  background: #fff;
  padding: 0;
  height: 40px;
  vertical-align: middle;
}
.theme-konektifa .table.table-input tbody tr td.has-error {
  border-color: #f05050 !important;
  color: #f05050 !important;
  position: relative;
}
.theme-konektifa .table.table-input tbody tr td.has-error p.help-block {
  position: absolute;
  top: 40px;
  z-index: 23;
}
.theme-konektifa .table.table-input tbody tr td span {
  padding-left: 20px;
}
.theme-konektifa .table.table-input tbody tr td input,
.theme-konektifa .table.table-input tbody tr td .form-control {
  background: none !important;
  height: 40px;
  padding: 0;
  padding-left: 20px !important;
  border: none;
}
.theme-konektifa .table.table-input tbody tr td input:-moz-placeholder,
.theme-konektifa .table.table-input tbody tr td input::-webkit-input-placeholder {
  color: #c4c4c4;
}
.theme-konektifa .table.table-input tbody tr td > a {
  margin: 0 !important;
  padding: 0 !important;
  max-height: 40px;
  line-height: 20px;
  display: inline-block;
  vertical-align: middle;
  overflow: hidden;
}
.theme-konektifa .table.table-input tbody tr td > a i {
  display: block;
  vertical-align: middle;
  margin-top: -5px;
}
.theme-konektifa .table.table-input tbody tr td > a.btn {
  margin: 0 !important;
  padding: 0 !important;
  max-height: 40px !important;
  display: block !important;
  vertical-align: middle !important;
  overflow: hidden;
}
.theme-konektifa .table.table-input tbody tr td > a.btn i {
  display: block !important;
  vertical-align: middle !important;
  padding-top: 19px !important;
}
.theme-konektifa .table.table-input tbody tr td .btn-group ul.dropdown-menu a {
  font-weight: normal !important;
}
.theme-konektifa .table.table-input tbody tr td .btn-group ul.dropdown-menu a:hover {
  text-decoration: none !important;
}
.theme-konektifa .table.table-input tbody tr td .input-notes {
  position: relative;
  width: 100%;
  display: block;
  height: auto;
  padding: 0 18px;
}
.theme-konektifa .table.table-input tbody tr td .input-notes a {
  position: absolute;
  top: -15px;
  right: 5px;
}
.theme-konektifa .table.table-input tbody tr td .input-notes.in {
  position: relative !important;
  top: 0;
}
.theme-konektifa .table.table-input tbody tr td .input-notes input.input-xs {
  height: 23px !important;
  line-height: 23px;
  margin: 0 0 5px 0 !important;
  padding: 0 !important;
  width: 80%;
  border: 1px solid #fff;
  font-size: 11px !important;
  color: #c0c0c0;
  font-style: italic;
}
.theme-konektifa .table.table-input tbody tr td .input-notes input.input-xs:focus {
  border: 1px solid #23b7e5;
}
.theme-konektifa .table.table-input tbody tr td > .btn {
  border: none;
  display: block;
  min-height: 40px;
  vertical-align: middle;
  text-align: center;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}
.theme-konektifa .table.table-input tbody tr td > .btn.bordered {
  border-left: 1px solid #c4c4c4;
}
.theme-konektifa .table.loading {
  position: relative;
}
.theme-konektifa .table.loading tbody > tr > td {
  opacity: 0.5;
}
.theme-konektifa .ng-table th {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.theme-konektifa .ng-table th.sortable {
  cursor: pointer;
  text-align: left;
}
.theme-konektifa .ng-table th.sortable div {
  padding-right: 18px;
  position: relative;
}
.theme-konektifa .ng-table th.sortable div:after,
.theme-konektifa .ng-table th.sortable div:before {
  content: "";
  border-width: 0 4px 4px;
  border-style: solid;
  border-color: #000 transparent;
  visibility: visible;
  right: 8px;
  top: 50%;
  position: absolute;
  opacity: .3;
  margin-top: -4px;
}
.theme-konektifa .ng-table th.sortable div:before {
  margin-top: 2px;
  border-bottom: none;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid #000;
}
.theme-konektifa .ng-table th.sortable div:hover:after,
.theme-konektifa .ng-table th.sortable div:hover:before {
  opacity: 1;
  visibility: visible;
}
.theme-konektifa .ng-table th.sortable.sort-desc,
.theme-konektifa .ng-table th.sortable.sort-asc {
  background-color: #edf1f2;
  text-shadow: 0 1px 1px rgba(255, 255, 255, 0.75);
}
.theme-konektifa .ng-table th.sortable.sort-desc div:after,
.theme-konektifa .ng-table th.sortable.sort-asc div:after {
  margin-top: -2px;
}
.theme-konektifa .ng-table th.sortable.sort-desc div:before,
.theme-konektifa .ng-table th.sortable.sort-asc div:before {
  visibility: hidden;
}
.theme-konektifa .ng-table th.sortable.sort-asc div:after,
.theme-konektifa .ng-table th.sortable.sort-asc div:hover:after {
  visibility: visible;
  filter: alpha(opacity=60);
  -khtml-opacity: 0.6;
  -moz-opacity: 0.6;
  opacity: 0.6;
}
.theme-konektifa .ng-table th.sortable.sort-desc div:after {
  border-bottom: none;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid #000;
  visibility: visible;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  filter: alpha(opacity=60);
  -khtml-opacity: 0.6;
  -moz-opacity: 0.6;
  opacity: 0.6;
}
.theme-konektifa label.checkbox span.lbl {
  line-height: 18px;
  cursor: pointer;
}
.theme-konektifa .input-xs {
  height: 28px;
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 2px;
}
select.theme-konektifa .input-xs {
  height: 28px;
  line-height: 28px;
}
textarea.theme-konektifa .input-xs,
select[multiple].theme-konektifa .input-xs {
  height: auto;
}
.theme-konektifa select.input-xs {
  padding: 3px 5px 5px;
}
.theme-konektifa .bordered {
  border: 1px solid #dee5e7;
}
.theme-konektifa .tooltips {
  cursor: pointer;
}
.theme-konektifa .truncate {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.theme-konektifa .bordered[class*='bg-'],
.theme-konektifa [class*='bg-'] .border-t {
  border-color: #fff;
  border-color: rgba(0, 0, 0, 0.2);
}
.theme-konektifa .border-t {
  border-top: 1px solid #dee5e7;
}
.theme-konektifa .no-border {
  border-width: 0 !important;
}
.theme-konektifa .no-border-hr {
  border-left-width: 0 !important;
  border-right-width: 0 !important;
}
.theme-konektifa .no-border-vr {
  border-top-width: 0 !important;
  border-bottom-width: 0 !important;
}
.theme-konektifa .no-border-t {
  border-top-width: 0 !important;
}
.theme-konektifa body:not(.right-to-left) .no-border-r {
  border-right-width: 0 !important;
}
.theme-konektifa .no-border-b {
  border-bottom-width: 0 !important;
}
.theme-konektifa body:not(.right-to-left) .no-border-l {
  border-left-width: 0 !important;
}
.theme-konektifa .no-grid-gutter-h {
  margin-left: -18px !important;
  margin-right: -18px !important;
}
.theme-konektifa .panel-wide {
  margin-left: -20px !important;
  margin-right: -20px !important;
}
.theme-konektifa .grid-gutter-margin-b {
  margin-bottom: 22px;
}
.theme-konektifa .no-margin {
  margin: 0 !important;
}
.theme-konektifa .no-margin-hr {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
.theme-konektifa .no-margin-vr {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
.theme-konektifa .no-margin-b {
  margin-bottom: 0 !important;
}
.theme-konektifa .no-margin-t {
  margin-top: 0 !important;
}
.theme-konektifa .form-group-margin {
  margin-bottom: 15px !important;
}
.theme-konektifa .panel-padding {
  padding: 20px !important;
}
.theme-konektifa .grid-gutter-padding-h {
  padding-left: 18px;
  padding-right: 18px;
}
.theme-konektifa .tab-content-padding {
  padding: 15px 0 !important;
}
.theme-konektifa .panel-padding-h {
  padding-left: 20px !important;
  padding-right: 20px !important;
}
.theme-konektifa .padding-sm {
  padding: 10px 15px !important;
}
.theme-konektifa .padding-sm-hr {
  padding-left: 15px !important;
  padding-right: 15px !important;
}
.theme-konektifa .padding-xs-hr {
  padding-left: 10px !important;
  padding-right: 10px !important;
}
.theme-konektifa .padding-sm-vr {
  padding-top: 15px !important;
  padding-bottom: 15px !important;
}
.theme-konektifa .padding-xs-vr {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}
.theme-konektifa .no-padding {
  padding: 0 !important;
}
.theme-konektifa .no-padding-hr {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.theme-konektifa .no-padding-vr {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.theme-konektifa .no-padding-b {
  padding-bottom: 0 !important;
}
.theme-konektifa .no-padding-t {
  padding-top: 0 !important;
}
.theme-konektifa .text-left {
  text-align: left;
}
.theme-konektifa .text-center {
  text-align: center;
}
.theme-konektifa .text-right {
  text-align: right;
}
@media (min-width: 768px) {
  .theme-konektifa .text-left-sm {
    text-align: left;
  }
  .theme-konektifa .text-center-sm {
    text-align: center;
  }
  .theme-konektifa .text-right-sm {
    text-align: right;
  }
}
@media (min-width: 992px) {
  .theme-konektifa .text-left-md {
    text-align: left;
  }
  .theme-konektifa .text-center-md {
    text-align: center;
  }
  .theme-konektifa .text-right-md {
    text-align: right;
  }
}
@media (min-width: 1200px) {
  .theme-konektifa .text-left-lg {
    text-align: left;
  }
  .theme-konektifa .text-center-lg {
    text-align: center;
  }
  .theme-konektifa .text-right-lg {
    text-align: right;
  }
}
.theme-konektifa .valign-top,
.theme-konektifa .valign-top td,
.theme-konektifa .valign-top th {
  vertical-align: top !important;
}
.theme-konektifa .valign-middle,
.theme-konektifa .valign-middle td,
.theme-konektifa .valign-middle th {
  vertical-align: middle !important;
}
.theme-konektifa .valign-bottom,
.theme-konektifa .valign-bottom td,
.theme-konektifa .valign-bottom th {
  vertical-align: bottom !important;
}
.theme-konektifa .text-xs {
  font-size: 11px;
}
.theme-konektifa .text-sm {
  font-size: 12px;
}
.theme-konektifa .text-bg {
  font-size: 17px;
}
.theme-konektifa .text-lg {
  font-size: 23px;
}
.theme-konektifa .text-xlg {
  font-size: 30px;
}
.theme-konektifa .text-slg {
  font-size: 50px;
}
.theme-konektifa .text-slim {
  font-weight: 300 !important;
}
.theme-konektifa .text-normal {
  font-weight: 400 !important;
}
.theme-konektifa .text-semibold {
  font-weight: 600 !important;
}
.theme-konektifa .text-bold {
  font-weight: 700 !important;
}
.theme-konektifa .text-default,
.theme-konektifa .text-default:hover,
.theme-konektifa .text-default:active,
.theme-konektifa .text-default:focus,
.theme-konektifa .text-default a,
.theme-konektifa .text-default a:focus {
  color: #829091;
}
.theme-konektifa .text-default a:hover,
.theme-konektifa .text-default a:active {
  color: #5d6869;
}
.theme-konektifa .text-success,
.theme-konektifa .text-success:hover,
.theme-konektifa .text-success:active,
.theme-konektifa .text-success:focus,
.theme-konektifa .text-success a,
.theme-konektifa .text-success a:focus {
  color: #20a03f;
}
.theme-konektifa .text-success a:hover,
.theme-konektifa .text-success a:active {
  color: #136026;
}
.theme-konektifa .text-danger,
.theme-konektifa .text-danger:hover,
.theme-konektifa .text-danger:active,
.theme-konektifa .text-danger:focus,
.theme-konektifa .text-danger a,
.theme-konektifa .text-danger a:focus {
  color: #ed2a2a;
}
.theme-konektifa .text-danger a:hover,
.theme-konektifa .text-danger a:active {
  color: #bb1010;
}
.theme-konektifa .text-warning,
.theme-konektifa .text-warning:hover,
.theme-konektifa .text-warning:active,
.theme-konektifa .text-warning:focus,
.theme-konektifa .text-warning a,
.theme-konektifa .text-warning a:focus {
  color: #f9cf0b;
}
.theme-konektifa .text-warning a:hover,
.theme-konektifa .text-warning a:active {
  color: #b39504;
}
.theme-konektifa .text-info,
.theme-konektifa .text-info:hover,
.theme-konektifa .text-info:active,
.theme-konektifa .text-info:focus,
.theme-konektifa .text-info a,
.theme-konektifa .text-info a:focus {
  color: #189ec8;
}
.theme-konektifa .text-info a:hover,
.theme-konektifa .text-info a:active {
  color: #106883;
}
.theme-konektifa .text-light-gray,
.theme-konektifa .text-light-gray:hover,
.theme-konektifa .text-light-gray:active,
.theme-konektifa .text-light-gray:focus,
.theme-konektifa .text-light-gray a,
.theme-konektifa .text-light-gray a:focus {
  color: #bbb;
}
.theme-konektifa .text-light-gray a:hover,
.theme-konektifa .text-light-gray a:active {
  color: #959595;
}
.theme-konektifa .text-light,
.theme-konektifa .text-light:hover,
.theme-konektifa .text-light:active,
.theme-konektifa .text-light:focus,
.theme-konektifa .text-light a,
.theme-konektifa .text-light a:focus {
  color: #fff;
}
.theme-konektifa .text-light a:hover,
.theme-konektifa .text-light a:active {
  color: #d9d9d9;
}
.theme-konektifa .bg-default {
  background: #98a3a4 !important;
}
.theme-konektifa .bg-default[href]:hover {
  background: #969797 !important;
}
.theme-konektifa .bg-default.darken {
  background: #969797 !important;
}
.theme-konektifa .bg-default.darken[href]:hover {
  background: #8e8f8f !important;
}
.theme-konektifa .bg-default.darker {
  background: #8e8f8f !important;
}
.theme-konektifa .bg-default.darker[href]:hover {
  background: #878787 !important;
}
.theme-konektifa .bg-default,
.theme-konektifa .bg-default:hover,
.theme-konektifa .bg-default:active,
.theme-konektifa .bg-default:focus,
.theme-konektifa .bg-default a,
.theme-konektifa .bg-default a:focus {
  color: #fff;
}
.theme-konektifa .bg-default a:hover,
.theme-konektifa .bg-default a:active {
  color: #d9d9d9;
}
.theme-konektifa .bg-default[href]:hover,
.theme-konektifa .bg-default [href]:hover {
  color: #fff;
}
.theme-konektifa .bg-default * {
  border-color: #abb4b5;
}
.theme-konektifa .bg-success {
  background: #27c24c !important;
}
.theme-konektifa .bg-success[href]:hover {
  background: #2baf4a !important;
}
.theme-konektifa .bg-success.darken {
  background: #2baf4a !important;
}
.theme-konektifa .bg-success.darken[href]:hover {
  background: #28a245 !important;
}
.theme-konektifa .bg-success.darker {
  background: #28a245 !important;
}
.theme-konektifa .bg-success.darker[href]:hover {
  background: #259640 !important;
}
.theme-konektifa .bg-success,
.theme-konektifa .bg-success:hover,
.theme-konektifa .bg-success:active,
.theme-konektifa .bg-success:focus,
.theme-konektifa .bg-success a,
.theme-konektifa .bg-success a:focus {
  color: #fff;
}
.theme-konektifa .bg-success a:hover,
.theme-konektifa .bg-success a:active {
  color: #d9d9d9;
}
.theme-konektifa .bg-success[href]:hover,
.theme-konektifa .bg-success [href]:hover {
  color: #fff;
}
.theme-konektifa .bg-success * {
  border-color: #36d75c;
}
.theme-konektifa .bg-danger {
  background: #f05050 !important;
}
.theme-konektifa .bg-danger[href]:hover {
  background: #e94848 !important;
}
.theme-konektifa .bg-danger.darken {
  background: #e94848 !important;
}
.theme-konektifa .bg-danger.darken[href]:hover {
  background: #e73a3a !important;
}
.theme-konektifa .bg-danger.darker {
  background: #e73a3a !important;
}
.theme-konektifa .bg-danger.darker[href]:hover {
  background: #e52d2d !important;
}
.theme-konektifa .bg-danger,
.theme-konektifa .bg-danger:hover,
.theme-konektifa .bg-danger:active,
.theme-konektifa .bg-danger:focus,
.theme-konektifa .bg-danger a,
.theme-konektifa .bg-danger a:focus {
  color: #fff;
}
.theme-konektifa .bg-danger a:hover,
.theme-konektifa .bg-danger a:active {
  color: #d9d9d9;
}
.theme-konektifa .bg-danger[href]:hover,
.theme-konektifa .bg-danger [href]:hover {
  color: #fff;
}
.theme-konektifa .bg-danger * {
  border-color: #f37171;
}
.theme-konektifa .bg-warning {
  background: #fad733 !important;
}
.theme-konektifa .bg-warning[href]:hover {
  background: #f3d02b !important;
}
.theme-konektifa .bg-warning.darken {
  background: #f3d02b !important;
}
.theme-konektifa .bg-warning.darken[href]:hover {
  background: #f2cc1c !important;
}
.theme-konektifa .bg-warning.darker {
  background: #f2cc1c !important;
}
.theme-konektifa .bg-warning.darker[href]:hover {
  background: #f1c90e !important;
}
.theme-konektifa .bg-warning,
.theme-konektifa .bg-warning:hover,
.theme-konektifa .bg-warning:active,
.theme-konektifa .bg-warning:focus,
.theme-konektifa .bg-warning a,
.theme-konektifa .bg-warning a:focus {
  color: #fff;
}
.theme-konektifa .bg-warning a:hover,
.theme-konektifa .bg-warning a:active {
  color: #d9d9d9;
}
.theme-konektifa .bg-warning[href]:hover,
.theme-konektifa .bg-warning [href]:hover {
  color: #fff;
}
.theme-konektifa .bg-warning * {
  border-color: #fbde56;
}
.theme-konektifa .bg-info {
  background: #23b7e5 !important;
}
.theme-konektifa .bg-info[href]:hover {
  background: #22acd7 !important;
}
.theme-konektifa .bg-info.darken {
  background: #22acd7 !important;
}
.theme-konektifa .bg-info.darken[href]:hover {
  background: #20a1ca !important;
}
.theme-konektifa .bg-info.darker {
  background: #20a1ca !important;
}
.theme-konektifa .bg-info.darker[href]:hover {
  background: #1e97bd !important;
}
.theme-konektifa .bg-info,
.theme-konektifa .bg-info:hover,
.theme-konektifa .bg-info:active,
.theme-konektifa .bg-info:focus,
.theme-konektifa .bg-info a,
.theme-konektifa .bg-info a:focus {
  color: #fff;
}
.theme-konektifa .bg-info a:hover,
.theme-konektifa .bg-info a:active {
  color: #d9d9d9;
}
.theme-konektifa .bg-info[href]:hover,
.theme-konektifa .bg-info [href]:hover {
  color: #fff;
}
.theme-konektifa .bg-info * {
  border-color: #43c1e9;
}
.theme-konektifa .bg-panel {
  background: #f6f8f8;
}
.theme-konektifa .no-bg {
  background: none !important;
}
.theme-konektifa .indicator {
  background: none;
  vertical-align: middle;
}
.theme-konektifa .indicator::before {
  content: '';
  display: inline-block;
  height: 8px;
  width: 8px;
  border-radius: 8px;
  margin: 0 4px 0 0px;
  background: #b0b0b0;
}
.theme-konektifa .indicator.default::before,
.theme-konektifa .indicator.draft::before,
.theme-konektifa .indicator.unpaid::before,
.theme-konektifa .indicator.new::before {
  background: #b0b0b0;
}
.theme-konektifa .indicator.success::before,
.theme-konektifa .indicator.in::before,
.theme-konektifa .indicator.new::before,
.theme-konektifa .indicator.cleared::before,
.theme-konektifa .indicator.sales_invoices::before,
.theme-konektifa .indicator.purchase_invoices::before,
.theme-konektifa .indicator.approved::before,
.theme-konektifa .indicator.invoiced::before,
.theme-konektifa .indicator.paid::before,
.theme-konektifa .indicator.delivered:before,
.theme-konektifa .indicator.submited:before,
.theme-konektifa .indicator.completed:before,
.theme-konektifa .indicator.active:before {
  background: #27c24c;
}
.theme-konektifa .indicator.warning::before,
.theme-konektifa .indicator.purchase_returns::before,
.theme-konektifa .indicator.sales_returns::before,
.theme-konektifa .indicator.service::before,
.theme-konektifa .indicator.partial::before,
.theme-konektifa .indicator.production:before,
.theme-konektifa .indicator.packed:before,
.theme-konektifa .indicator.ready:before,
.theme-konektifa .indicator.rejected::before,
.theme-konektifa .indicator.refund::before {
  background: #fad733;
}
.theme-konektifa .indicator.danger::before,
.theme-konektifa .indicator.out::before,
.theme-konektifa .indicator.uncleared::before,
.theme-konektifa .indicator.canceled::before,
.theme-konektifa .indicator.returned::before,
.theme-konektifa .indicator.not_received::before {
  background: #f05050;
}
.theme-konektifa .indicator.received::before,
.theme-konektifa .indicator.fulfilled::before,
.theme-konektifa .indicator.info::before,
.theme-konektifa .indicator.finished::before,
.theme-konektifa .indicator.material::before,
.theme-konektifa .indicator.published::before,
.theme-konektifa .indicator.reship::before,
.theme-konektifa .indicator.company_deposits::before,
.theme-konektifa .indicator.picking:before,
.theme-konektifa .indicator.shipping:before,
.theme-konektifa .indicator.shipped:before,
.theme-konektifa .indicator.onprogress:before {
  background: #23b7e5;
}
.theme-konektifa .indicator.primary::before,
.theme-konektifa .indicator.product::before {
  background: #7266ba;
}
.theme-konektifa .indicator-right::after {
  margin: 0 0 0 4px;
}
.theme-konektifa .rounded {
  border-radius: 999999px !important;
}
.theme-konektifa .no-border-radius {
  border-radius: 0 !important;
}
@media (min-width: 768px) {
  .theme-konektifa .col-sm-auto {
    width: auto;
  }
}
@media (min-width: 992px) {
  .theme-konektifa .col-md-auto {
    width: auto;
  }
}
@media (min-width: 1200px) {
  .theme-konektifa .col-lg-auto {
    width: auto;
  }
}
.theme-konektifa .identicon {
  text-align: center;
  vertical-align: top;
}
.theme-konektifa .identicon.s50 {
  font-size: 36px;
  line-height: 50px;
}
.theme-konektifa .avatar.s50 {
  width: 50px;
  height: 50px;
}
.theme-konektifa .text-header {
  margin: 0;
  font-weight: 400;
  text-rendering: optimizelegibility;
  font-family: "Raleway", "Open Sans", Helvetica, Arial, sans-serif;
}
.theme-konektifa [ng\:cloak],
.theme-konektifa [ng-cloak],
.theme-konektifa .ng-cloak {
  display: none !important;
}
.theme-konektifa.page-signin .signin-info,
.theme-konektifa.page-signup .signup-header {
  background: #7266ba;
  background: rgba(114, 102, 186, 0.8);
}
.theme-konektifa .timeline:before,
.theme-konektifa .timeline:after,
.theme-konektifa .tl-header,
.theme-konektifa .tl-icon {
  background: #d3dbde;
}
.theme-konektifa .tl-header,
.theme-konektifa .tl-icon {
  box-shadow: 0 0 0 4px #f0f3f4;
}
.theme-konektifa .tl-entry:before {
  background: #7266ba;
  box-shadow: 0 0 0 3px #f0f3f4;
}
.theme-konektifa .tl-body:before {
  border-color: transparent #dee5e7 transparent transparent;
}
@media (min-width: 768px) {
  .theme-konektifa .timeline.centered .tl-entry.left .tl-body:before {
    border-color: transparent transparent transparent #dee5e7;
  }
  .theme-konektifa.right-to-left .timeline.centered .tl-entry.left .tl-body:before {
    border-color: transparent #dee5e7 transparent transparent;
  }
}
.theme-konektifa.right-to-left .tl-body:before {
  border-color: transparent transparent transparent #dee5e7;
}
.theme-konektifa.page-mail .mail-nav,
.theme-konektifa.page-mail .mail-nav:before {
  background: #f0f3f4;
}
.theme-konektifa.page-mail .mail-nav .sections li.active .label,
.theme-konektifa.page-mail .mail-nav .sections li.active .badge {
  background: #7266ba;
}
.theme-konektifa.page-mail .mail-nav,
.theme-konektifa.page-mail .mail-nav .compose-btn,
.theme-konektifa.page-mail .mail-nav .sections li.active,
.theme-konektifa.page-mail .mail-nav:before {
  border-color: #dee5e7;
}
.theme-konektifa.page-mail .mail-nav .navigation.open .sections li.active {
  background: #e4eaeb;
}
.theme-konektifa.page-mail .mail-nav li.divider {
  background: #dee5e7;
}
.theme-konektifa.page-mail .mail-nav .sections li.active a {
  color: #364449;
}
.theme-konektifa.page-mail .mail-nav .sections li.active:after {
  color: #98acb3;
}
.theme-konektifa.page-mail .mail-nav .sections a {
  color: #566e75;
}
.theme-konektifa.page-mail .mail-nav-header {
  color: #98acb3;
}
.theme-konektifa.page-mail .mail-nav .add-more a {
  color: #98acb3;
}
@media (min-width: 992px) {
  .theme-konektifa.page-mail .mail-nav .navigation .sections li.active {
    background: #e4eaeb;
  }
  .theme-konektifa.page-mail .mail-nav .sections a:hover {
    color: #364449;
  }
}
.theme-konektifa .dropdown-menu {
  overflow: hidden;
  border-radius: 2px;
  -webkit-box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  border: 1px solid #dee5e7;
  border: 1px solid rgba(0, 0, 0, 0.1);
}
.theme-konektifa .dropdown-menu.pull-left {
  left: 100%;
}
.theme-konektifa .dropdown-menu > .panel {
  border: none;
  margin: -5px 0;
}
.theme-konektifa .dropdown-menu > li > a {
  padding: 5px 15px;
}
.theme-konektifa .dropdown-menu > li > a:hover,
.theme-konektifa .dropdown-menu > li > a:focus,
.theme-konektifa .dropdown-menu > .active > a,
.theme-konektifa .dropdown-menu > .active > a:hover,
.theme-konektifa .dropdown-menu > .active > a:focus {
  background-image: none;
  filter: none;
  background-color: #edf1f2 !important;
  color: #141719;
}
.theme-konektifa .dropdown-header {
  padding: 5px 15px;
}
.theme-konektifa .dropdown-submenu {
  position: relative;
}
.theme-konektifa .dropdown-submenu:hover > a,
.theme-konektifa .dropdown-submenu:focus > a {
  background-color: #edf1f2 !important;
  color: #58666e;
}
.theme-konektifa .dropdown-submenu:hover > .dropdown-menu,
.theme-konektifa .dropdown-submenu:focus > .dropdown-menu {
  display: block;
}
.theme-konektifa .dropdown-submenu.pull-left {
  float: none !important;
}
.theme-konektifa .dropdown-submenu.pull-left > .dropdown-menu {
  left: -100%;
  margin-left: 10px;
}
.theme-konektifa .dropdown-submenu .dropdown-menu {
  left: 100%;
  top: 0;
  margin-top: -6px;
  margin-left: -1px;
}
.dropup .theme-konektifa .dropdown-submenu > .dropdown-menu {
  top: auto;
  bottom: 0;
}
.theme-konektifa .btn-group > .btn {
  margin-left: -1px;
}
.theme-konektifa .popover {
  background: #fff;
  border-color: #dee5e7;
  margin-bottom: 22px;
  border-radius: 0 0 2px 2px;
  -webkit-box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  border: 1px solid #dee5e7;
  padding: 0;
}
.theme-konektifa .popover.top .arrow {
  border-top: none;
}
.theme-konektifa .popover.top .arrow:after {
  border-top-color: #dee5e7;
  border-width: 7px 8px 0 8px;
  bottom: 3px;
}
.theme-konektifa .popover.bottom .arrow {
  border-bottom: none;
}
.theme-konektifa .popover.bottom .arrow:after {
  border-bottom-color: #dee5e7;
  border-width: 0 8px 7px 8px;
  top: 3px;
}
.theme-konektifa .popover.left .arrow {
  border-left: none;
}
.theme-konektifa .popover.left .arrow:after {
  border-left-color: #dee5e7;
  border-width: 8px 0 8px 7px;
  bottom: -9px;
  right: 3px;
}
.theme-konektifa .popover.right .arrow {
  border-right: none;
}
.theme-konektifa .popover.right .arrow:after {
  border-right-color: #dee5e7;
  border-width: 8px 7px 8px 0;
  bottom: -9px;
  left: 3px;
}
.theme-konektifa .popover-title {
  margin: 0;
  padding: 8px 14px;
  font-size: 14px;
  font-weight: normal;
  line-height: 18px;
  background-color: #f6f8f8;
  border-bottom: 1px solid #edf1f2;
  color: #58666e;
}
.theme-konektifa .popover-content {
  padding: 0;
}
.theme-konektifa .popover-colorful + .popover {
  border-width: 1px;
}
.theme-konektifa .popover-colorful + .popover.top {
  margin-bottom: 8px;
}
.theme-konektifa .popover-colorful + .popover.left {
  margin-right: 8px;
}
.theme-konektifa .popover-colorful + .popover.top .arrow:after {
  bottom: 5px;
}
.theme-konektifa .popover-colorful + .popover.bottom .arrow:after {
  top: 5px;
}
.theme-konektifa .popover-colorful + .popover.left .arrow:after {
  right: 5px;
}
.theme-konektifa .popover-colorful + .popover.right .arrow:after {
  left: 5px;
}
.theme-konektifa .popover-title {
  background-color: rgba(0, 0, 0, 0.06);
  border-bottom-color: #ddd;
  border-radius: 0;
}
.theme-konektifa .ie8 .popover.top.in {
  margin-top: -30px;
}
.theme-konektifa .ie8 .popover.left.in {
  margin-left: -30px;
}
.theme-konektifa .ie8 .popover .arrow {
  display: none !important;
}
.theme-konektifa .popover-warning + .popover .popover-title {
  background: #f9f1c7;
  border-bottom-color: #f6deac;
  color: #af8640;
}
.theme-konektifa .popover-warning.popover-colorful + .popover {
  background: #f9f1c7;
  border-color: #f6deac;
  color: #af8640;
}
.theme-konektifa .popover-warning.popover-colorful + .popover.top .arrow:after {
  border-top-color: #f6deac;
}
.theme-konektifa .popover-warning.popover-colorful + .popover.bottom .arrow:after {
  border-bottom-color: #f6deac;
}
.theme-konektifa .popover-warning.popover-colorful + .popover.left .arrow:after {
  border-left-color: #f6deac;
}
.theme-konektifa .popover-warning.popover-colorful + .popover.right .arrow:after {
  border-right-color: #f6deac;
}
.theme-konektifa .popover-danger + .popover .popover-title {
  background: #f2dede;
  border-bottom-color: #ebccd1;
  color: #b94a48;
}
.theme-konektifa .popover-danger.popover-colorful + .popover {
  background: #f2dede;
  border-color: #ebccd1;
  color: #b94a48;
}
.theme-konektifa .popover-danger.popover-colorful + .popover.top .arrow:after {
  border-top-color: #ebccd1;
}
.theme-konektifa .popover-danger.popover-colorful + .popover.bottom .arrow:after {
  border-bottom-color: #ebccd1;
}
.theme-konektifa .popover-danger.popover-colorful + .popover.left .arrow:after {
  border-left-color: #ebccd1;
}
.theme-konektifa .popover-danger.popover-colorful + .popover.right .arrow:after {
  border-right-color: #ebccd1;
}
.theme-konektifa .popover-success + .popover .popover-title {
  background: #dff0d8;
  border-bottom-color: #d0e6be;
  color: #468847;
}
.theme-konektifa .popover-success.popover-colorful + .popover {
  background: #dff0d8;
  border-color: #d0e6be;
  color: #468847;
}
.theme-konektifa .popover-success.popover-colorful + .popover.top .arrow:after {
  border-top-color: #d0e6be;
}
.theme-konektifa .popover-success.popover-colorful + .popover.bottom .arrow:after {
  border-bottom-color: #d0e6be;
}
.theme-konektifa .popover-success.popover-colorful + .popover.left .arrow:after {
  border-left-color: #d0e6be;
}
.theme-konektifa .popover-success.popover-colorful + .popover.right .arrow:after {
  border-right-color: #d0e6be;
}
.theme-konektifa .popover-info + .popover .popover-title {
  background: #d9edf7;
  border-bottom-color: #bce8f1;
  color: #3a87ad;
}
.theme-konektifa .popover-info.popover-colorful + .popover {
  background: #d9edf7;
  border-color: #bce8f1;
  color: #3a87ad;
}
.theme-konektifa .popover-info.popover-colorful + .popover.top .arrow:after {
  border-top-color: #bce8f1;
}
.theme-konektifa .popover-info.popover-colorful + .popover.bottom .arrow:after {
  border-bottom-color: #bce8f1;
}
.theme-konektifa .popover-info.popover-colorful + .popover.left .arrow:after {
  border-left-color: #bce8f1;
}
.theme-konektifa .popover-info.popover-colorful + .popover.right .arrow:after {
  border-right-color: #bce8f1;
}
.theme-konektifa .popover-warning.popover-dark + .popover .popover-title {
  background: #fad733;
  border-bottom-color: #fad733;
  color: #fff;
}
.theme-konektifa .popover-warning.popover-dark.popover-colorful + .popover {
  background: #fad733;
  border-color: #fad733;
  color: #fff;
}
.theme-konektifa .popover-warning.popover-dark.popover-colorful + .popover .popover-title {
  border-bottom-color: #fbdf5b;
  border-bottom-width: 2px;
}
.theme-konektifa .popover-warning.popover-dark.popover-colorful + .popover.top .arrow:after {
  border-top-color: #fad733;
}
.theme-konektifa .popover-warning.popover-dark.popover-colorful + .popover.bottom .arrow:after {
  border-bottom-color: #fad733;
}
.theme-konektifa .popover-warning.popover-dark.popover-colorful + .popover.left .arrow:after {
  border-left-color: #fad733;
}
.theme-konektifa .popover-warning.popover-dark.popover-colorful + .popover.right .arrow:after {
  border-right-color: #fad733;
}
.theme-konektifa .popover-danger.popover-dark + .popover .popover-title {
  background: #f05050;
  border-bottom-color: #f05050;
  color: #fff;
}
.theme-konektifa .popover-danger.popover-dark.popover-colorful + .popover {
  background: #f05050;
  border-color: #f05050;
  color: #fff;
}
.theme-konektifa .popover-danger.popover-dark.popover-colorful + .popover .popover-title {
  border-bottom-color: #f37676;
  border-bottom-width: 2px;
}
.theme-konektifa .popover-danger.popover-dark.popover-colorful + .popover.top .arrow:after {
  border-top-color: #f05050;
}
.theme-konektifa .popover-danger.popover-dark.popover-colorful + .popover.bottom .arrow:after {
  border-bottom-color: #f05050;
}
.theme-konektifa .popover-danger.popover-dark.popover-colorful + .popover.left .arrow:after {
  border-left-color: #f05050;
}
.theme-konektifa .popover-danger.popover-dark.popover-colorful + .popover.right .arrow:after {
  border-right-color: #f05050;
}
.theme-konektifa .popover-success.popover-dark + .popover .popover-title {
  background: #27c24c;
  border-bottom-color: #27c24c;
  color: #fff;
}
.theme-konektifa .popover-success.popover-dark.popover-colorful + .popover {
  background: #27c24c;
  border-color: #27c24c;
  color: #fff;
}
.theme-konektifa .popover-success.popover-dark.popover-colorful + .popover .popover-title {
  border-bottom-color: #3ad760;
  border-bottom-width: 2px;
}
.theme-konektifa .popover-success.popover-dark.popover-colorful + .popover.top .arrow:after {
  border-top-color: #27c24c;
}
.theme-konektifa .popover-success.popover-dark.popover-colorful + .popover.bottom .arrow:after {
  border-bottom-color: #27c24c;
}
.theme-konektifa .popover-success.popover-dark.popover-colorful + .popover.left .arrow:after {
  border-left-color: #27c24c;
}
.theme-konektifa .popover-success.popover-dark.popover-colorful + .popover.right .arrow:after {
  border-right-color: #27c24c;
}
.theme-konektifa .popover-info.popover-dark + .popover .popover-title {
  background: #23b7e5;
  border-bottom-color: #23b7e5;
  color: #fff;
}
.theme-konektifa .popover-info.popover-dark.popover-colorful + .popover {
  background: #23b7e5;
  border-color: #23b7e5;
  color: #fff;
}
.theme-konektifa .popover-info.popover-dark.popover-colorful + .popover .popover-title {
  border-bottom-color: #47c3e9;
  border-bottom-width: 2px;
}
.theme-konektifa .popover-info.popover-dark.popover-colorful + .popover.top .arrow:after {
  border-top-color: #23b7e5;
}
.theme-konektifa .popover-info.popover-dark.popover-colorful + .popover.bottom .arrow:after {
  border-bottom-color: #23b7e5;
}
.theme-konektifa .popover-info.popover-dark.popover-colorful + .popover.left .arrow:after {
  border-left-color: #23b7e5;
}
.theme-konektifa .popover-info.popover-dark.popover-colorful + .popover.right .arrow:after {
  border-right-color: #23b7e5;
}
.theme-konektifa .nav-icon-btn.nav-icon-btn-primary .label {
  background: #7266ba;
}
.theme-konektifa .btn-primary {
  color: #fff;
}
.theme-konektifa .btn-primary,
.theme-konektifa .btn-primary:focus {
  border-color: #6254b2;
  border-bottom-color: #5548a0;
  background: #7266ba;
  background-image: -webkit-linear-gradient(top, #756abc 0%, #6254b2 100%);
  background-image: linear-gradient(to bottom, #756abc 0%, #6254b2 100%);
  background-repeat: repeat-x;
}
.theme-konektifa .btn-primary:hover {
  color: #fff;
  border-color: #5a4daa;
  border-bottom-color: #483d87;
  background: #6254b2 !important;
  background-image: -webkit-linear-gradient(top, #6c5fb7 0%, #5a4daa 100%) !important;
  background-image: linear-gradient(to bottom, #6c5fb7 0%, #5a4daa 100%) !important;
  background-repeat: repeat-x;
}
.theme-konektifa .btn-primary:active,
.theme-konektifa .btn-primary.active,
.open .theme-konektifa .btn-primary.dropdown-toggle {
  background: none;
  background: #6558b4 !important;
  border-color: #4f4395;
  border-bottom-color: #4f4395 !important;
  color: #fff;
  -webkit-box-shadow: 0 0 12px rgba(0, 0, 0, 0.1) inset;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.1) inset;
}
.theme-konektifa .btn-primary.btn-flat {
  background: #685bb5;
}
.theme-konektifa .btn-primary.btn-flat:hover,
.theme-konektifa .btn-primary.btn-flat:active,
.theme-konektifa .btn-primary.btn-flat.active,
.open .theme-konektifa .btn-primary.btn-flat.dropdown-toggle {
  background: #685bb5 !important;
  color: #fff;
}
.theme-konektifa .btn-primary.btn-outline:hover,
.theme-konektifa .btn-primary.btn-outline.btn-flat:hover {
  color: #fff;
  border-color: #5a4daa;
  border-bottom-color: #483d87;
}
.theme-konektifa .btn-primary.btn-outline:active,
.theme-konektifa .btn-primary.btn-outline.btn-flat:active {
  border-color: #4f4395;
}
.btn-group .theme-konektifa .btn-primary,
.btn-group .theme-konektifa .btn-primary:focus,
.input-group-btn .theme-konektifa .btn-primary,
.input-group-btn .theme-konektifa .btn-primary:focus {
  border-left-color: #5a4daa;
  border-right-color: #5a4daa;
}
.btn-group .theme-konektifa .btn-primary:hover,
.btn-group.open .theme-konektifa .btn-primary.dropdown-toggle,
.input-group-btn .theme-konektifa .btn-primary:hover,
.input-group-btn.open .theme-konektifa .btn-primary.dropdown-toggle {
  border-color: #5a4daa;
  border-bottom-color: #483d87;
}
.btn-group .theme-konektifa .btn-primary:active,
.btn-group .theme-konektifa .btn-primary.active,
.input-group-btn .theme-konektifa .btn-primary:active,
.input-group-btn .theme-konektifa .btn-primary.active {
  border-left-color: #4f4395;
  border-right-color: #4f4395;
}
.theme-konektifa .open .btn.btn-primary.dropdown-toggle {
  color: #fff;
  border-bottom-color: #4f4395 !important;
  background: none;
  background: #6558b4 !important;
}
.theme-konektifa .progress-bar {
  background: #7266ba;
  border-color: #5f51b0;
}
.theme-konektifa .progress-striped .progress-bar {
  background-color: #7266ba;
  background-image: -webkit-gradient(linear, 0 100%, 100% 0, color-stop(0.25, rgba(255, 255, 255, 0.1)), color-stop(0.25, transparent), color-stop(0.5, transparent), color-stop(0.5, rgba(255, 255, 255, 0.1)), color-stop(0.75, rgba(255, 255, 255, 0.1)), color-stop(0.75, transparent), to(transparent));
  background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.1) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.1) 50%, rgba(255, 255, 255, 0.1) 75%, transparent 75%, transparent);
  background-image: -moz-linear-gradient(45deg, rgba(255, 255, 255, 0.1) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.1) 50%, rgba(255, 255, 255, 0.1) 75%, transparent 75%, transparent);
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.1) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.1) 50%, rgba(255, 255, 255, 0.1) 75%, transparent 75%, transparent);
  background-size: 10px 10px;
}
.theme-konektifa .label.label-primary {
  background: #7266ba;
}
.theme-konektifa .label.label-primary[href]:hover,
.theme-konektifa .label.label-primary[href]:focus {
  background-color: #564aa3;
}
.theme-konektifa .label.label-primary.label-tag {
  border: 1px solid #7266ba;
}
.theme-konektifa .label.label-primary.label-tag:before {
  border-color: transparent #7266ba transparent transparent;
}
.theme-konektifa .label.label-primary.label-tag[href]:hover,
.theme-konektifa .label.label-primary.label-tag[href]:focus {
  border-color: #564aa3;
}
.theme-konektifa .label.label-primary.label-tag[href]:hover:before,
.theme-konektifa .label.label-primary.label-tag[href]:focus:before {
  border-color: transparent #564aa3 transparent transparent;
}
.right-to-left .theme-konektifa .label.label-primary.label-tag:before {
  border-color: transparent transparent transparent #7266ba;
}
.right-to-left .theme-konektifa .label.label-primary.label-tag[href]:hover:before,
.right-to-left .theme-konektifa .label.label-primary.label-tag[href]:focus:before {
  border-color: transparent transparent transparent #564aa3;
}
.ie8 .right-to-left .theme-konektifa .label.label-primary.label-tag:before,
.ie9 .right-to-left .theme-konektifa .label.label-primary.label-tag:before {
  border-color: transparent #7266ba transparent transparent;
}
.ie8 .right-to-left .theme-konektifa .label.label-primary.label-tag[href]:hover:before,
.ie8 .right-to-left .theme-konektifa .label.label-primary.label-tag[href]:focus:before,
.ie9 .right-to-left .theme-konektifa .label.label-primary.label-tag[href]:hover:before,
.ie9 .right-to-left .theme-konektifa .label.label-primary.label-tag[href]:focus:before {
  border-color: transparent #564aa3 transparent transparent;
}
.theme-konektifa.right-to-left .label.label-primary.label-tag:before {
  border-color: transparent transparent transparent #7266ba;
}
.theme-konektifa.right-to-left .label.label-primary.label-tag:hover:before,
.theme-konektifa.right-to-left .label.label-primary.label-tag:focus:before {
  border-color: transparent transparent transparent #564aa3;
}
.ie8 .theme-konektifa.right-to-left .label.label-primary.label-tag:before,
.ie9 .theme-konektifa.right-to-left .label.label-primary.label-tag:before {
  border-color: transparent #7266ba transparent transparent;
}
.ie8 .theme-konektifa.right-to-left .label.label-primary.label-tag:hover:before,
.ie8 .theme-konektifa.right-to-left .label.label-primary.label-tag:focus:before,
.ie9 .theme-konektifa.right-to-left .label.label-primary.label-tag:hover:before,
.ie9 .theme-konektifa.right-to-left .label.label-primary.label-tag:focus:before {
  border-color: transparent #564aa3 transparent transparent;
}
.theme-konektifa .badge.badge-primary {
  background: #7266ba;
  border: 1px solid #7266ba;
}
.theme-konektifa .badge.badge-primary[href]:hover,
.theme-konektifa .badge.badge-primary[href]:focus {
  background-color: #564aa3;
  border-color: #564aa3;
}
.theme-konektifa .badge.badge-primary {
  background: #7266ba;
  border: 1px solid #7266ba;
}
.theme-konektifa .badge.badge-primary[href]:hover,
.theme-konektifa .badge.badge-primary[href]:focus {
  background-color: #564aa3;
  border-color: #564aa3;
}
.theme-konektifa .table-primary table {
  border-top-color: #5f588b !important;
}
.theme-konektifa .table-primary thead,
.theme-konektifa .table-primary thead tr,
.theme-konektifa .table-primary thead th,
.theme-konektifa .table-primary .table-header {
  border-color: #5f588b !important;
  color: #fff;
}
.theme-konektifa .table-primary thead tr,
.theme-konektifa .table-primary thead th {
  background: #665ba6;
}
.theme-konektifa .table-primary .table-header {
  background: #7266ba;
}
.theme-konektifa .nav-tabs > li.active > a,
.theme-konektifa .nav-tabs > li.active > a:hover,
.theme-konektifa .nav-tabs > li.active > a:focus {
  background: #7266ba;
  border-bottom: 2px solid #6254b2;
}
.theme-konektifa .nav-tabs .nav-tabs.nav-justified > .active > a {
  border-bottom-color: #6254b2;
}
.theme-konektifa .nav-pills > li.active > a,
.theme-konektifa .nav-pills > li.active > a:hover,
.theme-konektifa .nav-pills > li.active > a:focus {
  background: #7266ba;
}
.theme-konektifa .dropdown-menu > li > a:hover,
.theme-konektifa .dropdown-menu > li.active > a {
  background: #7266ba;
}
.theme-konektifa .dropdown-menu > li > a:hover {
  color: #363f44;
}
.theme-konektifa .btn-group .btn-primary,
.theme-konektifa .btn-group .btn-primary:focus,
.theme-konektifa .input-group-btn .btn-primary,
.theme-konektifa .input-group-btn .btn-primary:focus {
  border-left-color: #5a4daa;
  border-right-color: #5a4daa;
}
.theme-konektifa .btn-group .btn-primary:hover,
.theme-konektifa .btn-group.open .btn-primary.dropdown-toggle,
.theme-konektifa .input-group-btn .btn-primary:hover,
.theme-konektifa .input-group-btn.open .btn-primary.dropdown-toggle {
  border-color: #4f4395;
}
.theme-konektifa .btn-group .btn-primary:active,
.theme-konektifa .btn-group .btn-primary.active,
.theme-konektifa .input-group-btn .btn-primary:active,
.theme-konektifa .input-group-btn .btn-primary.active {
  border-left-color: #4f4395;
  border-right-color: #4f4395;
}
.theme-konektifa .pagination > li > a:hover {
  border-color: #7266ba;
}
.theme-konektifa .pagination > li.active > a,
.theme-konektifa .pagination > li.active > a:hover,
.theme-konektifa .pagination > li.active > a:focus,
.theme-konektifa .pagination > li.active > span {
  background: #7266ba;
  border-color: #7266ba;
}
.theme-konektifa .pager li > a:hover {
  border-color: #7266ba;
}
.theme-konektifa a.list-group-item.active {
  background: #7266ba;
  border-color: #7266ba;
}
.theme-konektifa a.list-group-item.active .list-group-item-text {
  color: #f4f3f9;
}
.theme-konektifa .form-control:focus,
.theme-konektifa .form-control.focus {
  border-color: #7266ba;
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.gt-ie8 .theme-konektifa input.px:focus + .lbl:before,
.gt-ie8 .theme-konektifa input.px + .lbl:hover:before {
  border-color: #7266ba;
}
.gt-ie8 .theme-konektifa input[type="checkbox"].px + .lbl:after {
  color: #7266ba;
}
.gt-ie8 .theme-konektifa input[type="radio"].px + .lbl:after {
  background: #7266ba;
}
.theme-konektifa .text-primary,
.theme-konektifa .text-primary:hover,
.theme-konektifa .text-primary:active,
.theme-konektifa .text-primary:focus,
.theme-konektifa .text-primary a,
.theme-konektifa .text-primary a:focus {
  color: #7266ba;
}
.theme-konektifa .text-primary a:hover,
.theme-konektifa .text-primary a:active {
  color: #4d4292;
}
.theme-konektifa .bg-primary {
  background: #7266ba !important;
}
.theme-konektifa .bg-primary[href]:hover {
  background: #6d63ae !important;
}
.theme-konektifa .bg-primary.darken {
  background: #6d63ae !important;
}
.theme-konektifa .bg-primary.darken[href]:hover {
  background: #6458a9 !important;
}
.theme-konektifa .bg-primary.darker {
  background: #6458a9 !important;
}
.theme-konektifa .bg-primary.darker[href]:hover {
  background: #5e53a0 !important;
}
.theme-konektifa .bg-primary,
.theme-konektifa .bg-primary:hover,
.theme-konektifa .bg-primary:active,
.theme-konektifa .bg-primary:focus,
.theme-konektifa .bg-primary a,
.theme-konektifa .bg-primary a:focus {
  color: #fff;
}
.theme-konektifa .bg-primary a:hover,
.theme-konektifa .bg-primary a:active {
  color: #d9d9d9;
}
.theme-konektifa .bg-primary[href]:hover,
.theme-konektifa .bg-primary [href]:hover {
  color: #fff;
}
.theme-konektifa .bg-primary * {
  border-color: #897fc5;
}
.theme-konektifa .ui-slider-handle.ui-state-active:before {
  background: #7266ba;
}
.theme-konektifa .ui-slider-range {
  background: #7266ba;
}
.theme-konektifa .ui-autocomplete > li > a:hover,
.theme-konektifa .ui-autocompletev > li.active > a {
  background: #7266ba;
}
.theme-konektifa .ui-autocomplete > li > a:hover {
  color: #fff;
}
.theme-konektifa .ui-autocomplete .ui-state-focus {
  background: #7266ba;
  color: #fff;
}
.theme-konektifa .ui-menu .ui-menu-item a:hover,
.theme-konektifa .ui-menu .ui-menu-item a.ui-state-focus,
.theme-konektifa .ui-menu .ui-menu-item a.ui-state-active {
  background: #7266ba;
}
.theme-konektifa .ui-progressbar-value {
  background: #7266ba;
  border-color: #5f51b0;
  background-color: #7266ba;
  background-image: -webkit-gradient(linear, 0 100%, 100% 0, color-stop(0.25, rgba(255, 255, 255, 0.1)), color-stop(0.25, transparent), color-stop(0.5, transparent), color-stop(0.5, rgba(255, 255, 255, 0.1)), color-stop(0.75, rgba(255, 255, 255, 0.1)), color-stop(0.75, transparent), to(transparent));
  background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.1) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.1) 50%, rgba(255, 255, 255, 0.1) 75%, transparent 75%, transparent);
  background-image: -moz-linear-gradient(45deg, rgba(255, 255, 255, 0.1) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.1) 50%, rgba(255, 255, 255, 0.1) 75%, transparent 75%, transparent);
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.1) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.1) 50%, rgba(255, 255, 255, 0.1) 75%, transparent 75%, transparent);
  background-size: 10px 10px;
}
.theme-konektifa .ui-tabs-nav > .ui-tabs-active > a,
.theme-konektifa .ui-tabs-nav > .ui-tabs-active > a:hover,
.theme-konektifa .ui-tabs-nav > .ui-tabs-active > a:focus {
  color: #fff;
  background: #7266ba;
  border-bottom: 2px solid #6254b2;
}
.theme-konektifa .ui-datepicker .ui-state-active {
  background: #7266ba !important;
}
.theme-konektifa .ui-datepicker-title {
  background: #7266ba;
}
.theme-konektifa .ui-datepicker th {
  background: #736d99;
}
.theme-konektifa .ui-datepicker-buttonpane button.ui-priority-primary {
  color: #fff;
}
.theme-konektifa .ui-datepicker-buttonpane button.ui-priority-primary,
.theme-konektifa .ui-datepicker-buttonpane button.ui-priority-primary:focus {
  border-color: #6254b2;
  border-bottom-color: #5548a0;
  background: #7266ba;
  background-image: -webkit-linear-gradient(top, #756abc 0%, #6254b2 100%);
  background-image: linear-gradient(to bottom, #756abc 0%, #6254b2 100%);
  background-repeat: repeat-x;
}
.theme-konektifa .ui-datepicker-buttonpane button.ui-priority-primary:hover {
  color: #fff;
  border-color: #5a4daa;
  border-bottom-color: #483d87;
  background: #6254b2 !important;
  background-image: -webkit-linear-gradient(top, #6c5fb7 0%, #5a4daa 100%) !important;
  background-image: linear-gradient(to bottom, #6c5fb7 0%, #5a4daa 100%) !important;
  background-repeat: repeat-x;
}
.theme-konektifa .ui-datepicker-buttonpane button.ui-priority-primary:active,
.theme-konektifa .ui-datepicker-buttonpane button.ui-priority-primary.active,
.open .theme-konektifa .ui-datepicker-buttonpane button.ui-priority-primary.dropdown-toggle {
  background: none;
  background: #6558b4 !important;
  border-color: #4f4395;
  border-bottom-color: #4f4395 !important;
  color: #fff;
  -webkit-box-shadow: 0 0 12px rgba(0, 0, 0, 0.1) inset;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.1) inset;
}
.theme-konektifa .ui-datepicker-buttonpane button.ui-priority-primary.btn-flat {
  background: #685bb5;
}
.theme-konektifa .ui-datepicker-buttonpane button.ui-priority-primary.btn-flat:hover,
.theme-konektifa .ui-datepicker-buttonpane button.ui-priority-primary.btn-flat:active,
.theme-konektifa .ui-datepicker-buttonpane button.ui-priority-primary.btn-flat.active,
.open .theme-konektifa .ui-datepicker-buttonpane button.ui-priority-primary.btn-flat.dropdown-toggle {
  background: #685bb5 !important;
  color: #fff;
}
.theme-konektifa .ui-datepicker-buttonpane button.ui-priority-primary.btn-outline:hover,
.theme-konektifa .ui-datepicker-buttonpane button.ui-priority-primary.btn-outline.btn-flat:hover {
  color: #fff;
  border-color: #5a4daa;
  border-bottom-color: #483d87;
}
.theme-konektifa .ui-datepicker-buttonpane button.ui-priority-primary.btn-outline:active,
.theme-konektifa .ui-datepicker-buttonpane button.ui-priority-primary.btn-outline.btn-flat:active {
  border-color: #4f4395;
}
.btn-group .theme-konektifa .ui-datepicker-buttonpane button.ui-priority-primary,
.btn-group .theme-konektifa .ui-datepicker-buttonpane button.ui-priority-primary:focus,
.input-group-btn .theme-konektifa .ui-datepicker-buttonpane button.ui-priority-primary,
.input-group-btn .theme-konektifa .ui-datepicker-buttonpane button.ui-priority-primary:focus {
  border-left-color: #5a4daa;
  border-right-color: #5a4daa;
}
.btn-group .theme-konektifa .ui-datepicker-buttonpane button.ui-priority-primary:hover,
.btn-group.open .theme-konektifa .ui-datepicker-buttonpane button.ui-priority-primary.dropdown-toggle,
.input-group-btn .theme-konektifa .ui-datepicker-buttonpane button.ui-priority-primary:hover,
.input-group-btn.open .theme-konektifa .ui-datepicker-buttonpane button.ui-priority-primary.dropdown-toggle {
  border-color: #5a4daa;
  border-bottom-color: #483d87;
}
.btn-group .theme-konektifa .ui-datepicker-buttonpane button.ui-priority-primary:active,
.btn-group .theme-konektifa .ui-datepicker-buttonpane button.ui-priority-primary.active,
.input-group-btn .theme-konektifa .ui-datepicker-buttonpane button.ui-priority-primary:active,
.input-group-btn .theme-konektifa .ui-datepicker-buttonpane button.ui-priority-primary.active {
  border-left-color: #4f4395;
  border-right-color: #4f4395;
}
.theme-konektifa .select2-drop-active,
.theme-konektifa .select2-container-multi.select2-container-active .select2-choices,
.theme-konektifa .select2-container-multi.select2-dropdown-open .select2-choices {
  border: 1px solid #7266ba;
}
.theme-konektifa .select2-drop.select2-drop-above.select2-drop-active {
  border-top: 1px solid #7266ba;
}
.theme-konektifa .select2-container-active .select2-choice,
.theme-konektifa .select2-container-active .select2-choices {
  border: 1px solid #7266ba;
}
.theme-konektifa .select2-dropdown-open .select2-drop-above .select2-choice,
.theme-konektifa .select2-dropdown-open .select2-drop-above .select2-choices {
  border: 1px solid #7266ba;
  border-top-color: #aaa;
}
.theme-konektifa .select2-results .select2-highlighted {
  background: #7266ba;
}
.theme-konektifa .select2-primary .select2-container-multi .select2-choices .select2-search-choice {
  background: #7266ba;
}
.theme-konektifa .select2-primary .select2-container-multi .select2-choices .select2-search-choice-focus {
  background: #564aa3;
}
.theme-konektifa .select2-primary .select2-container-multi.select2-container-disabled .select2-choices .select2-search-choice {
  background: #7266ba !important;
}
.theme-konektifa .switcher-primary .switcher-state-on {
  background: #7266ba !important;
}
.theme-konektifa .pixel-file-input:hover {
  border-color: #7266ba;
}
.theme-konektifa .datepicker table tr td.selected,
.theme-konektifa .datepicker table tr td.selected:hover,
.theme-konektifa .datepicker table tr td.selected.disabled,
.theme-konektifa .datepicker table tr td.selected.disabled:hover {
  background: #7266ba;
}
.theme-konektifa .datepicker table tr td.active,
.theme-konektifa .datepicker table tr td.active:hover,
.theme-konektifa .datepicker table tr td.active.disabled,
.theme-konektifa .datepicker table tr td.active.disabled:hover {
  background: #7266ba;
}
.theme-konektifa .datepicker table tr td span.active,
.theme-konektifa .datepicker table tr td span.active:hover,
.theme-konektifa .datepicker table tr td span.active.disabled,
.theme-konektifa .datepicker table tr td span.active.disabled:hover {
  background: #7266ba;
}
.theme-konektifa .datepicker thead tr {
  background: #736d99;
}
.theme-konektifa .datepicker thead tr:first-child {
  background: #7266ba !important;
  border-bottom-color: #6b697a !important;
}
.theme-konektifa .datepicker thead th.prev,
.theme-konektifa .datepicker thead th.next {
  background: #7266ba;
}
.theme-konektifa .datepicker thead th.prev {
  border-color: #6b697a;
}
.theme-konektifa .datepicker thead th.next {
  border-color: #6b697a;
}
.theme-konektifa .datepicker > .datepicker-days thead tr:first-child th.cw + .prev {
  border-color: #6b697a;
}
.theme-konektifa .datepicker > .datepicker-days thead tr:last-child th.cw + th {
  border-color: #6b697a;
}
.theme-konektifa .bootstrap-timepicker-widget table td a:hover {
  background: #7266ba;
  border-color: #7266ba;
}
.theme-konektifa .pagination a.dp-today,
.theme-konektifa .pagination a.dp-today:hover {
  background: #9289ca;
  border-color: #8278c2;
}
.theme-konektifa .pagination a.dp-today:hover {
  border-color: #7266ba !important;
}
.theme-konektifa .pagination a.dp-selected {
  background: #7266ba !important;
  border-color: #7266ba !important;
}
.theme-konektifa .tt-suggestion.tt-is-under-cursor {
  background: #7266ba;
}
.theme-konektifa .table-primary .dataTables_wrapper .DT-per-page {
  border-color: #5f588b !important;
}
.theme-konektifa .dropzone-box.dz-drag-hover {
  border-color: #7266ba;
}
.theme-konektifa .dropzone-box:hover .fa.fa-cloud-upload {
  background: #7266ba;
}
.theme-konektifa .md-editor.active {
  border-color: #7266ba;
}
.theme-konektifa .widget-rating .active a {
  color: #7266ba;
}
