.page-invoice .invoice {
  padding: 0 20px;
}
.page-invoice .invoice hr {
  border: none;
  border-bottom: 3px solid #f1f1f1;
  margin: 0 -20px;
}
.page-invoice .invoice-header {
  background: #f7f7f7;
  border-bottom: 8px solid #f1f1f1;
  margin: 0 -20px;
  padding: 20px;
  position: relative;
}
.page-invoice .invoice-header:before,
.page-invoice .invoice-header:after {
  content: " ";
  display: table;
}
.page-invoice .invoice-header:after {
  clear: both;
}
.page-invoice .invoice-header h3 {
  font-size: 19px;
  font-weight: 600;
  height: 55px;
  line-height: 23px;
  margin: 0 0 0 40px;
  padding: 0;
  word-wrap: none;
}
.page-invoice .invoice-header small {
  color: #a0a0a0;
  font-size: 12px;
}
.page-invoice .invoice-header .invoice-logo {
  display: block;
  height: 26px;
  width: 26px;
  margin: 12px 0 0 -40px;
  position: absolute;
}
.page-invoice .invoice-header address {
  border-left: 5px solid #e8e8e8;
  color: #959595;
  display: block;
  font-size: 12px;
  line-height: 15px;
  padding-left: 10px;
  margin-top: 5px;
}
.page-invoice .invoice-date {
  font-size: 16px;
  font-weight: 600;
  margin-top: -5px;
}
.page-invoice .invoice-info,
.page-invoice .invoice-table {
  padding: 30px 0;
}
.page-invoice .invoice-info:before,
.page-invoice .invoice-table:before,
.page-invoice .invoice-info:after,
.page-invoice .invoice-table:after {
  content: " ";
  display: table;
}
.page-invoice .invoice-info:after,
.page-invoice .invoice-table:after {
  clear: both;
}
.page-invoice .invoice-recipient {
  font-size: 14px;
  line-height: 19px;
  height: 60px;
  padding-left: 70px;
}
.page-invoice .invoice-recipient:before {
  background: #f7f7f7;
  border-radius: 999px;
  color: #888888;
  content: "TO";
  display: block;
  font-size: 14px;
  font-weight: 600;
  height: 52px;
  line-height: 52px;
  margin: 4px 0 0 -70px;
  position: absolute;
  text-align: center;
  width: 52px;
}
.page-invoice .invoice-total {
  background: #f7f7f7;
  color: #888888;
  font-weight: 600;
  height: 60px;
  line-height: 60px;
  padding: 0 25px;
  white-space: nowrap;
  margin-top: 20px;
}
.page-invoice .invoice-total span {
  display: block;
  float: right;
  color: #555;
  font-size: 24px;
}
.page-invoice .invoice-description {
  color: #aaa;
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  padding-top: 4px;
}
.page-invoice table {
  width: 100%;
}
.page-invoice table thead {
  background: #f7f7f7;
  color: #888;
  font-size: 12px;
}
.page-invoice table tbody tr {
  font-size: 14px;
}
.page-invoice table tbody tr + tr {
  border-top: 1px solid #f1f1f1;
}
.page-invoice table th,
.page-invoice table td {
  border-left: 1px solid #fff;
  font-weight: 600;
  padding: 7px 0 7px 15px;
  text-align: left;
  vertical-align: top;
}
.page-invoice table td {
  padding-bottom: 20px;
  padding-top: 20px;
}
@media (min-width: 768px) {
  .page-invoice .invoice {
    padding: 0 40px;
  }
  .page-invoice .invoice hr {
    border: none;
    border-bottom: 3px solid #f1f1f1;
    margin: 0 -40px;
  }
  .page-invoice .invoice-header {
    padding: 40px;
    margin: 0 -40px;
  }
  .page-invoice .invoice-header h3 {
    float: left;
  }
  .page-invoice .invoice-header address {
    margin: 6px 0 0 30px;
    float: left;
  }
  .page-invoice .invoice-date {
    float: right;
    margin-top: 6px;
  }
  .page-invoice .invoice-recipient,
  .page-invoice .invoice-total {
    width: 50%;
  }
  .page-invoice .invoice-recipient {
    float: left;
  }
  .page-invoice .invoice-total {
    float: right;
    margin-top: 0;
  }
}
body.page-invoice.page-invoice-print,
body.page-invoice.page-invoice-print * {
  color: #000000 !important;
}
.page-invoice.page-invoice-print .invoice {
  min-width: 768px;
  padding: 0 40px;
}
.page-invoice.page-invoice-print .invoice hr {
  border-bottom: 1px solid #aaa;
  margin: 0 -40px;
}
.page-invoice.page-invoice-print .invoice-header {
  padding: 40px;
  margin: 0 -40px;
  background: none;
  border-bottom: 2px solid #aaa;
}
.page-invoice.page-invoice-print .invoice-header h3 {
  float: left;
  margin: 0 !important;
}
.page-invoice.page-invoice-print .invoice-header address {
  margin: 6px 0 0 30px;
  float: left;
}
.page-invoice.page-invoice-print .invoice-header .invoice-logo {
  display: none !important;
}
.page-invoice.page-invoice-print .invoice-date {
  float: right;
  margin-top: 6px;
}
.page-invoice.page-invoice-print .invoice-recipient,
.page-invoice.page-invoice-print .invoice-total {
  width: 50%;
}
.page-invoice.page-invoice-print .invoice-recipient {
  float: left;
}
.page-invoice.page-invoice-print .invoice-recipient:before {
  color: #000;
  background: none;
}
.page-invoice.page-invoice-print .invoice-total {
  float: right;
  margin-top: 0;
  background: none;
  text-align: right;
}
.page-invoice.page-invoice-print .invoice-total span {
  margin-left: 15px;
}
.page-invoice.page-invoice-print table thead {
  background: none;
  border-bottom: 1px solid #aaa;
}
.page-pricing .page-pricing-header {
  margin: -22px -22px 22px -22px;
  overflow: hidden;
  position: relative;
  text-align: center;
}
.page-pricing .page-pricing-header img {
  left: 0;
  position: absolute;
  width: 100%;
}
.page-pricing .page-pricing-header .bg-overlay {
  opacity: .5;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.page-pricing .page-pricing-header .page-pricing-header-content {
  position: relative;
}
.page-pricing .page-pricing-header .page-pricing-header-content h1.page-title {
  padding: 40px 0 0 0;
  color: #fff;
  margin: 0 0 -40px 0;
}
.page-pricing .page-pricing-header .page-pricing-header-content .slogan {
  padding: 60px 0 40px 0;
  color: #fff;
}
.page-pricing .page-pricing-header .page-pricing-header-content .slogan h3,
.page-pricing .page-pricing-header .page-pricing-header-content .slogan h4 {
  padding: 0;
  margin: 0;
}
.page-pricing .page-pricing-header .page-pricing-header-content .slogan h3 {
  line-height: 30px;
  font-weight: 600;
}
.page-pricing .page-pricing-header .page-pricing-header-content .slogan h4 {
  padding-top: 10px;
  font-weight: 300;
}
.page-pricing .plans-panel {
  margin: 0 auto;
}
.page-pricing .plans-container {
  position: relative;
  margin: -1px;
}
.page-pricing .plans-container:before,
.page-pricing .plans-container:after {
  content: " ";
  display: table;
}
.page-pricing .plans-container:after {
  clear: both;
}
.page-pricing .plan-col {
  text-align: center;
  padding: 0;
  position: relative;
  margin-bottom: 22px;
}
.page-pricing .plan-header {
  font-size: 15px;
  font-weight: 700;
  overflow: hidden;
  line-height: 50px;
  white-space: nowrap;
  position: relative;
}
.page-pricing .plan-pricing {
  white-space: nowrap;
  padding: 20px 0;
}
.page-pricing .plan-currency {
  font-size: 24px;
  font-weight: 300;
}
.page-pricing .plan-value {
  font-size: 44px;
  font-weight: 300;
}
.page-pricing .plan-period {
  font-size: 18px;
  display: inline-block;
  margin-left: 5px;
  font-weight: 300;
}
.page-pricing .plan-features {
  padding: 0;
  margin: 0;
  border-top: none;
}
.page-pricing .plan-features > li,
.page-pricing .plan-features > a {
  margin: 0;
  padding: 16px 0;
  font-size: 14px;
  font-weight: 300;
  display: block;
}
.page-pricing .plan-features > li {
  background: #fff;
  border: 1px solid #e4e4e4;
  border-top: 0;
}
.page-pricing .plan-features > a {
  margin-top: -1px;
  font-size: 13px;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}
.page-pricing .trial {
  padding: 20px 0;
  text-align: center;
  font-size: 14px;
  color: #888;
  line-height: 23px;
}
.page-pricing .trial a {
  font-size: 15px;
}
.ie8 .page-pricing .page-pricing-header .bg-overlay {
  display: none !important;
}
@media (min-width: 768px) {
  .page-pricing .plans-panel {
    background: #fafafa;
    border: 1px solid #e4e4e4;
    margin-bottom: 22px;
  }
  .page-pricing .plan-col {
    margin-bottom: 0;
  }
  .page-pricing .plan-col + .plan-col .plan-features > li {
    border-left-width: 0;
    -webkit-box-shadow: -1px 0 0 #e4e4e4;
    box-shadow: -1px 0 0 #e4e4e4;
  }
  .page-pricing .plan-col + .plan-col .plan-features > a {
    margin-left: -1px;
  }
}
html body.page-signin {
  background: #666 !important;
}
#page-signin-bg {
  height: 100%;
  left: 0;
  overflow: hidden;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 20;
}
#page-signin-bg img {
  width: 100%;
}
#page-signin-bg .overlay {
  background: rgba(0, 0, 0, 0.5);
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}
.page-signin .signin-container {
  border-radius: 2px;
  display: block;
  margin: 20px 14px;
  position: relative;
  z-index: 999;
}
.page-signin .signin-info {
  background: #444;
  display: block;
  overflow: hidden;
  padding: 20px 30px;
  text-align: center;
}
.page-signin .signin-info .logo {
  color: #fff;
  font-size: 22px;
  font-weight: 700;
  opacity: 1;
}
.page-signin .signin-info .logo[href]:hover {
  opacity: .7;
}
.page-signin .signin-info .slogan {
  color: #fff;
  font-size: 16px;
  font-weight: 300;
  margin-top: 5px;
}
.page-signin .signin-info ul {
  display: none;
}
.page-signin .signin-form {
  background: #fff;
  display: block;
  padding: 30px;
  position: relative;
}
.page-signin .password-reset-form {
  background: #fff;
  bottom: 0;
  display: none;
  left: 0;
  padding: 30px;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1020;
}
.page-signin .password-reset-form .header {
  background: #f4f4f4;
  border-bottom: 1px solid #ddd;
  margin: -30px -30px 30px -30px;
  padding: 20px 20px;
}
.page-signin .password-reset-form .header .signin-text {
  margin: 0;
}
.page-signin .password-reset-form .header .signin-text span {
  background: #f4f4f4;
}
.page-signin .password-reset-form .header .signin-text:before {
  display: none;
}
.page-signin .password-reset-form .header .close {
  margin-top: 2px;
}
.page-signin .password-reset-form .form-group.w-icon .signin-form-icon {
  z-index: 1025;
}
.page-signin .form-actions {
  margin-top: 15px;
}
.page-signin .form-actions .signin-btn {
  border: none;
  border-radius: 999999px;
  font-size: 13px;
  font-weight: 600;
  line-height: 19px;
  outline: 0 !important;
  padding: 10px 0;
  text-align: center;
  width: 100%;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.page-signin .form-actions .signin-btn:hover {
  background: #444 !important;
}
.page-signin .form-actions a.forgot-password {
  border-bottom: 1px dashed #bbb;
  color: #999;
  display: inline-block;
  font-size: 11px;
  margin-left: 10px;
  padding-bottom: 1px;
}
.page-signin .form-actions a.forgot-password:hover {
  color: #555;
  text-decoration: none;
}
.page-signin .form-group {
  margin: 0;
  position: relative;
}
.page-signin .form-group + .form-group {
  margin: 10px 0 0 0;
}
.page-signin .form-group.w-icon .signin-form-icon {
  border-right: 1px solid #d6d6d6;
  bottom: 9px;
  color: #d6d6d6;
  display: block;
  font-size: 14px;
  left: 0;
  line-height: 27px;
  max-height: 27px;
  position: absolute;
  text-align: center;
  top: 9px;
  width: 40px;
  z-index: 1002;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.page-signin .form-group.w-icon input[type="text"],
.page-signin .form-group.w-icon input[type="password"] {
  font-size: 15px;
  padding-left: 56px;
}
.page-signin .form-group.w-icon input[type="text"]:focus + .signin-form-icon,
.page-signin .form-group.w-icon input[type="password"]:focus + .signin-form-icon {
  color: #888;
}
.page-signin .signin-text {
  color: #777;
  display: block;
  font-size: 15px;
  font-style: italic;
  margin-bottom: 25px;
  position: relative;
  text-align: center;
}
.page-signin .signin-text span {
  background: #fff;
  display: inline-block;
  padding: 0 20px;
  position: relative;
}
.page-signin .signin-text:before {
  background: #e4e4e4;
  content: "";
  display: block;
  height: 1px;
  left: 0;
  position: absolute;
  right: 0;
  top: 50%;
}
.page-signin .signin-with {
  background: #f4f4f4;
  border-top: 1px solid #ddd;
  margin: 30px -30px -30px -30px;
  padding: 20px 30px 20px 30px;
}
.page-signin .signin-with-btn {
  border-radius: 2px;
  color: #fff;
  display: block;
  font-weight: 300;
  padding: 10px 0;
  text-align: center;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.page-signin .signin-with-btn span {
  font-weight: 600;
}
.page-signin .signin-with-btn + .signin-with-btn {
  margin-top: 10px;
}
.page-signin .signin-with-btn:hover {
  background: #444 !important;
}
.page-signin .not-a-member {
  color: #fff;
  display: block;
  font-size: 14px;
  margin: -20px 0 0 0;
  padding: 20px 0;
  position: relative;
  text-align: center;
  z-index: 999;
}
.page-signin .not-a-member a {
  color: #fff;
  text-decoration: underline;
  transition: all .3s;
}
.page-signin .not-a-member a:hover {
  opacity: .6;
}
@media (min-width: 480px) {
  .page-signin .form-actions {
    margin-top: 15px;
  }
  .page-signin .form-actions .signin-btn {
    padding-left: 40px;
    padding-right: 40px;
    width: auto;
  }
}
@media (min-width: 768px) {
  .page-signin .signin-container {
    display: table;
    margin: 120px auto;
    width: 650px;
    table-layout: fixed;
  }
  .page-signin .signin-info {
    display: table-cell;
    padding: 30px;
    padding-top: 70px;
    text-align: left;
    vertical-align: top;
    width: 40%;
  }
  .page-signin .signin-info ul {
    color: #fff;
    display: block;
    font-size: 13px;
    font-weight: 300;
    margin: 30px 0 0 0;
    padding: 0;
  }
  .page-signin .signin-info ul li {
    list-style: none;
    margin: 0;
    padding: 7px 0;
  }
  .page-signin .signin-info ul .signin-icon {
    display: block;
    float: left;
    font-size: 14px;
    line-height: 18px;
    margin-right: 6px;
    text-align: center;
    width: 24px;
  }
  .page-signin .signin-form {
    display: table-cell;
    vertical-align: top;
    width: 60%;
  }
  .page-signin .not-a-member {
    margin: -80px 0 80px;
  }
}
html body.page-signup {
  background: #666 !important;
}
#page-signup-bg {
  height: 100%;
  left: 0;
  overflow: hidden;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 20;
}
#page-signup-bg img {
  width: 100%;
}
#page-signup-bg .overlay {
  background: rgba(0, 0, 0, 0.5);
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}
.page-signup .signup-container {
  border-radius: 2px;
  display: block;
  margin: 20px 14px;
  position: relative;
  z-index: 999;
}
.page-signup .signup-header {
  background: #444;
  display: block;
  overflow: hidden;
  padding: 20px 30px;
  text-align: center;
}
.page-signup .signup-header .logo {
  color: #fff;
  font-size: 22px;
  font-weight: 700;
  opacity: 1;
}
.page-signup .signup-header .logo[href]:hover {
  opacity: .7;
}
.page-signup .signup-header .slogan {
  color: #fff;
  font-size: 16px;
  font-weight: 300;
  margin-top: 5px;
}
.page-signup .signup-form {
  background: #fff;
  display: block;
  padding: 30px 20px;
  position: relative;
}
.page-signup .form-actions {
  margin-top: 15px;
}
.page-signup .form-actions .signup-btn {
  border: none;
  border-radius: 999999px;
  font-size: 13px;
  font-weight: 600;
  line-height: 19px;
  outline: 0 !important;
  padding: 10px 0;
  text-align: center;
  width: 100%;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.page-signup .form-actions .signup-btn:hover {
  background: #444 !important;
}
.page-signup .form-actions a.forgot-password {
  border-bottom: 1px dashed #bbb;
  color: #999;
  display: inline-block;
  font-size: 11px;
  margin-left: 10px;
  padding-bottom: 1px;
}
.page-signup .form-actions a.forgot-password:hover {
  color: #555;
  text-decoration: none;
}
.page-signup .form-group {
  margin: 0;
  position: relative;
}
.page-signup .form-group + .form-group {
  margin: 10px 0 0 0;
}
.page-signup .form-group.w-icon .signup-form-icon {
  border-right: 1px solid #d6d6d6;
  bottom: 9px;
  color: #d6d6d6;
  display: block;
  font-size: 14px;
  left: 0;
  line-height: 27px;
  position: absolute;
  text-align: center;
  top: 9px;
  width: 40px;
  z-index: 1002;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.page-signup .form-group.w-icon input[type="text"],
.page-signup .form-group.w-icon input[type="password"] {
  font-size: 15px;
  padding-left: 56px;
}
.page-signup .form-group.w-icon input[type="text"]:focus + .signup-form-icon,
.page-signup .form-group.w-icon input[type="password"]:focus + .signup-form-icon {
  color: #888;
}
.page-signup .signup-text {
  color: #777;
  display: block;
  font-size: 15px;
  font-style: italic;
  margin-bottom: 25px;
  position: relative;
  text-align: center;
}
.page-signup .signup-text span {
  background: #fff;
  display: inline-block;
  padding: 0 20px;
  position: relative;
}
.page-signup .signup-text:before {
  background: #e4e4e4;
  content: "";
  display: block;
  height: 1px;
  left: 0;
  position: absolute;
  right: 0;
  top: 50%;
}
.page-signup .signup-with {
  background: #f4f4f4;
  border-top: 1px solid #ddd;
  margin: 30px -20px -30px -20px;
  padding: 20px 20px 20px 20px;
}
.page-signup .signup-with-btn {
  border-radius: 2px;
  color: #fff;
  display: block;
  font-weight: 300;
  padding: 10px 0;
  text-align: center;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.page-signup .signup-with-btn span {
  font-weight: 600;
}
.page-signup .signup-with-btn + .signup-with-btn {
  margin-top: 10px;
}
.page-signup .signup-with-btn:hover {
  background: #444 !important;
}
.page-signup .have-account {
  color: #fff;
  display: block;
  font-size: 14px;
  margin: -20px 0 0 0;
  padding: 20px 0;
  position: relative;
  text-align: center;
  z-index: 999;
}
.page-signup .have-account a {
  color: #fff;
  text-decoration: underline;
  transition: all .3s;
}
.page-signup .have-account a:hover {
  opacity: .6;
}
@media (min-width: 480px) {
  .page-signup .signup-container {
    margin-left: auto;
    margin-right: auto;
    width: 380px;
    margin: 80px auto;
  }
  .page-signup .signup-form {
    padding: 30px;
  }
  .page-signup .signup-with {
    margin-left: -30px;
    margin-right: -30px;
    padding-left: 30px;
    padding-right: 30px;
  }
  .page-signup .have-account {
    margin: -80px 0 20px;
  }
}
.page-signin-alt {
  padding-bottom: 40px;
}
.page-signin-alt .signin-header {
  background: #fff;
  padding: 16px 0 16px 16px;
  position: relative;
  -webkit-box-shadow: 0 2px 2px rgba(0,0,0,0.05),0 1px 0 rgba(0,0,0,0.05);
  box-shadow: 0 2px 2px rgba(0,0,0,0.05),0 1px 0 rgba(0,0,0,0.05);
}
.page-signin-alt .signin-header .logo {
  color: #555;
  font-size: 16px;
  height: 24px;
  line-height: 24px;
  width: 24px;
  opacity: 1;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.page-signin-alt .signin-header .logo[href]:hover {
  opacity: .6;
}
.page-signin-alt .signin-header .btn {
  position: absolute;
  right: 16px;
  top: 12px;
}
.page-signin-alt .signin-header .demo-logo {
  display: inline-block;
  height: 24px;
  line-height: 24px;
}
.page-signin-alt .form-header {
  font-size: 22px;
  font-weight: 300;
  text-align: center;
  margin: 40px 0;
}
.page-signin-alt .panel {
  margin: 0 16px;
  padding: 20px;
}
.page-signin-alt .signin-password {
  position: relative;
}
.page-signin-alt .signin-password input {
  padding-right: 70px;
}
.page-signin-alt .signin-password .forgot {
  position: absolute;
  right: 10px;
  top: 10px;
  color: #555;
  background: #f1f1f1;
  border-radius: 2px;
  line-height: 25px;
  padding: 0 7px;
  font-size: 12px;
}
.page-signin-alt .signin-password .forgot:hover {
  text-decoration: underline;
}
.page-signin-alt .signin-with {
  margin: 0 14px;
  padding: 20px;
  text-align: center;
}
.page-signin-alt .signin-with .header {
  font-size: 16px;
  font-weight: 300;
  text-align: center;
  margin: 0 0 20px 0;
}
.page-signin-alt .signin-with .btn {
  padding-left: 0;
  padding-right: 0;
  width: 40px;
  text-align: center;
}
@media (min-width: 480px) {
  .page-signin-alt .signin-header {
    padding-left: 0;
    text-align: center;
  }
  .page-signin-alt .panel {
    width: 360px;
    margin: 0 auto;
    padding: 27px;
  }
  .page-signin-alt .signin-with {
    width: 360px;
    margin: 0 auto;
  }
}
.page-signup-alt {
  padding-bottom: 40px;
}
.page-signup-alt .signup-header {
  background: #fff;
  padding: 16px 0 16px 16px;
  position: relative;
  -webkit-box-shadow: 0 2px 2px rgba(0,0,0,0.05),0 1px 0 rgba(0,0,0,0.05);
  box-shadow: 0 2px 2px rgba(0,0,0,0.05),0 1px 0 rgba(0,0,0,0.05);
}
.page-signup-alt .signup-header .logo {
  color: #555;
  font-size: 16px;
  height: 24px;
  line-height: 24px;
  width: 24px;
  opacity: 1;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.page-signup-alt .signup-header .logo[href]:hover {
  opacity: .6;
}
.page-signup-alt .signup-header .btn {
  position: absolute;
  right: 16px;
  top: 12px;
}
.page-signup-alt .signup-header .demo-logo {
  display: inline-block;
  height: 24px;
  line-height: 24px;
}
.page-signup-alt .form-header {
  font-size: 22px;
  font-weight: 300;
  text-align: center;
  margin: 40px 0;
}
.page-signup-alt .panel {
  margin: 0 16px;
  padding: 20px;
}
.page-signup-alt .signup-with {
  margin: 0 14px;
  padding: 20px;
  text-align: center;
}
.page-signup-alt .signup-with .header {
  font-size: 16px;
  font-weight: 300;
  text-align: center;
  margin: 0 0 20px 0;
}
.page-signup-alt .signup-with .btn {
  padding-left: 0;
  padding-right: 0;
  width: 40px;
  text-align: center;
}
@media (min-width: 480px) {
  .page-signup-alt .signup-header {
    padding-left: 0;
    text-align: center;
  }
  .page-signup-alt .panel {
    width: 360px;
    margin: 0 auto;
    padding: 27px;
  }
  .page-signup-alt .signup-with {
    width: 360px;
    margin: 0 auto;
  }
}
.page-search .search-text {
  color: #888;
  font-size: 15px;
  font-weight: 300;
  line-height: 36px;
  margin-bottom: 22px;
}
.page-search .search-tabs {
  margin-bottom: -2px;
}
.page-search .search-tabs:before,
.page-search .search-tabs:after {
  content: " ";
  display: table;
}
.page-search .search-tabs:after {
  clear: both;
}
.page-search .search-form {
  background: #fafafa;
  margin: -1px;
  padding: 16px 20px;
  position: relative;
}
.page-search .search-form .input-group-addon {
  border-bottom-width: 0;
  border-left-width: 0;
  border-top-width: 0;
}
.page-search .search-form input[type="text"] {
  border-bottom-width: 0;
  border-top-width: 0;
}
.page-search .search-form button {
  border-bottom-width: 0;
  border-right-width: 0;
  border-top-width: 0;
}
.page-search .search-classic {
  margin: -20px;
  padding: 0;
}
.page-search .search-classic li {
  list-style: none;
  margin: 0;
  padding: 20px;
}
.page-search .search-classic li > * + * {
  margin-top: 10px;
}
.page-search .search-classic li + li {
  border-top: 1px solid #e4e4e4;
}
.page-search .search-classic .search-title,
.page-search .search-classic .search-tags,
.page-search .search-classic .search-content,
.page-search .search-classic .search-url {
  display: block;
}
.page-search .search-classic .search-title {
  font-size: 15px;
  font-weight: 600;
}
.page-search .search-classic .search-tags:before,
.page-search .search-classic .search-tags:after {
  content: " ";
  display: table;
}
.page-search .search-classic .search-tags:after {
  clear: both;
}
.page-search .search-classic .search-tags span,
.page-search .search-classic .search-tags a {
  display: block;
  float: left;
  line-height: 17px;
}
.page-search .search-classic .search-tags .search-tags-text {
  color: #aaa;
  font-size: 10px;
  font-weight: 600;
  margin-right: 10px;
  text-transform: uppercase;
}
.page-search .search-classic .search-tags .label,
.page-search .search-classic .search-tags .badge {
  font-size: 10px;
}
.page-search .search-classic .search-tags .label + .label,
.page-search .search-classic .search-tags .badge + .label,
.page-search .search-classic .search-tags .label + .badge,
.page-search .search-classic .search-tags .badge + .badge {
  margin-left: 5px;
}
.page-search .search-classic .search-url {
  color: #3a8b54;
  font-size: 11px;
  margin-top: 4px;
}
.page-search .search-users {
  margin: -20px;
}
.page-search .search-users table {
  margin: 0;
}
.page-search .search-users table th,
.page-search .search-users table td {
  padding-bottom: 14px;
  padding-top: 14px;
  vertical-align: middle;
}
.page-search .search-users .avatar {
  border-radius: 999999px;
  height: 30px;
  width: 30px;
}
.page-search .search-messages {
  margin-bottom: -20px;
  margin-top: -20px;
}
.page-search .search-messages .message {
  padding-bottom: 12px;
  padding-top: 12px;
}
.page-search .search-messages .message .date {
  top: 12px;
}
.page-404 {
  background: #f4b04f !important;
}
.page-500 {
  background: #e66454 !important;
}
.page-500 .error-text {
  margin-bottom: 80px !important;
}
.page-404,
.page-500 {
  text-align: center;
}
.page-404 .header,
.page-500 .header {
  background: #fff;
  padding: 20px 0;
  -webkit-box-shadow: 0 4px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 4px 0 rgba(0, 0, 0, 0.1);
}
.page-404 .header .logo,
.page-500 .header .logo {
  color: #555;
  font-size: 16px;
  height: 24px;
  line-height: 24px;
  width: 24px;
  opacity: .6;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.page-404 .header .logo[href]:hover,
.page-500 .header .logo[href]:hover {
  opacity: 1;
}
.page-404 .header .demo-logo,
.page-500 .header .demo-logo {
  display: inline-block;
  height: 24px;
  line-height: 24px;
  background: #555 !important;
}
.page-404 .error-code,
.page-500 .error-code {
  color: #fff;
  font-size: 120px;
  font-weight: 700;
  line-height: 140px;
  margin-top: 60px;
  text-shadow: 0 4px 0 rgba(0, 0, 0, 0.1);
}
.page-404 .error-text,
.page-500 .error-text {
  color: #fff;
  color: rgba(0, 0, 0, 0.5);
  font-size: 20px;
  font-weight: 600;
  padding: 0 20px;
}
.page-404 .error-text .oops,
.page-500 .error-text .oops {
  font-size: 50px;
}
.page-404 .error-text .hr,
.page-500 .error-text .hr {
  background: #fff;
  background: rgba(0, 0, 0, 0.2);
  display: inline-block;
  height: 5px;
  margin: 20px 0;
  width: 40px;
}
.page-404 .error-text .solve,
.page-500 .error-text .solve {
  display: inline-block;
  font-size: 16px;
  font-weight: 400;
  margin-top: 15px;
}
.page-404 .search-form,
.page-500 .search-form {
  display: inline-block;
  margin: 50px 0 80px 0;
}
.page-404 .search-form .search-input,
.page-500 .search-form .search-input,
.page-404 .search-form .search-btn,
.page-500 .search-form .search-btn {
  border: none;
  border-radius: 3px;
  display: block;
  float: left;
  font-size: 15px;
  height: 46px;
  line-height: 20px;
  padding: 13px 0;
}
.page-404 .search-form .search-input,
.page-500 .search-form .search-input,
.page-404 .search-form .search-btn,
.page-500 .search-form .search-btn,
.page-404 .search-form .search-input:active,
.page-500 .search-form .search-input:active,
.page-404 .search-form .search-btn:active,
.page-500 .search-form .search-btn:active,
.page-404 .search-form .search-input:focus,
.page-500 .search-form .search-input:focus,
.page-404 .search-form .search-btn:focus,
.page-500 .search-form .search-btn:focus {
  outline: 0 !important;
  outline-offset: 0 !important;
}
.page-404 .search-form .search-input,
.page-500 .search-form .search-input {
  background: #fff;
  color: #555;
  padding-left: 12px;
  padding-right: 12px;
  width: 220px;
}
.page-404 .search-form .search-btn,
.page-500 .search-form .search-btn {
  background: #444;
  background: rgba(0, 0, 0, 0.2);
  color: #fff;
  font-size: 13px;
  margin-left: 10px;
  padding-left: 12px;
  padding-right: 12px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.page-404 .search-form .search-btn:hover,
.page-500 .search-form .search-btn:hover {
  background: rgba(0, 0, 0, 0.5);
}
.page-404 .search-form .search-btn:active,
.page-500 .search-form .search-btn:active {
  background: rgba(0, 0, 0, 0.7);
}
.timeline {
  position: relative;
  padding-bottom: 30px;
  margin-bottom: 20px;
}
.timeline:before,
.timeline:after {
  content: "";
  display: block;
  position: absolute;
  left: 30px;
  bottom: 0;
}
.timeline:before {
  width: 4px;
  top: 0;
  margin-left: -2px;
}
.timeline:after {
  width: 12px;
  height: 12px;
  margin-left: -6px;
  border-radius: 999999px;
}
.tl-header {
  position: relative;
  width: 140px;
  padding: 8px 0;
  text-align: center;
  left: 0;
  margin-left: 0;
  margin-top: 40px;
  margin-bottom: 40px;
  font-size: 14px;
  border-radius: 2px;
}
.tl-header.now {
  margin-top: 0;
}
.tl-entry {
  margin-left: 30px;
  margin-right: 0;
  padding-left: 36px;
  padding-right: 0;
  position: relative;
  margin-bottom: 40px;
}
.tl-entry:before,
.tl-entry:after {
  content: " ";
  display: table;
}
.tl-entry:after {
  clear: both;
}
.tl-entry:before {
  content: "";
  display: block;
  position: absolute;
  width: 6px;
  height: 6px;
  left: -3px;
  right: auto;
  border-radius: 999999px;
  top: 20px;
}
.tl-body {
  position: relative;
  padding: 15px;
  margin: 0;
}
.tl-body:before {
  content: "";
  display: block;
  width: 0px;
  height: 0px;
  border-style: solid;
  border-width: 8px 9px 8px 0;
  position: absolute;
  left: -9px;
  right: auto;
  top: 15px;
}
.tl-body:after {
  content: "";
  display: block;
  width: 0px;
  height: 0px;
  border-style: solid;
  border-width: 7px 8px 7px 0;
  border-color: transparent #fff transparent transparent;
  position: absolute;
  left: -8px;
  right: auto;
  top: 16px;
}
.tl-time {
  position: absolute;
  left: auto;
  right: auto;
  margin-left: 0;
  margin-right: 0;
  text-align: right;
  white-space: nowrap;
  color: #888;
  top: -22px;
  font-size: 13px;
}
.tl-icon {
  display: block;
  position: absolute;
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 999999px;
  left: -20px;
  right: auto;
  top: 4px;
  text-align: center;
  font-size: 14px;
  overflow: hidden;
}
.tl-icon img {
  height: 100%;
  display: block;
}
.tl-wide {
  margin: 0 -15px;
}
@media (min-width: 768px) {
  .timeline:before,
  .timeline:after {
    left: 100px;
  }
  .tl-header {
    left: 100px;
    margin-left: -70px;
  }
  .tl-entry {
    margin-left: 100px;
    margin-bottom: 20px;
  }
  .tl-time {
    left: auto;
    right: 100%;
    margin-left: 0;
    margin-right: 32px;
    top: 14px;
  }
  .timeline.centered:before,
  .timeline.centered:after {
    left: 50%;
    right: auto;
  }
  .timeline.centered .tl-header {
    left: 50%;
    right: auto;
  }
  .timeline.centered .tl-entry {
    margin-left: 50%;
    margin-right: 0;
  }
  .timeline.centered .tl-entry.left {
    margin-left: 0;
    margin-right: 50%;
    padding-left: 0;
    padding-right: 36px;
  }
  .timeline.centered .tl-entry.left:before {
    left: auto;
    right: -3px;
  }
  .timeline.centered .tl-entry.left .tl-body:before {
    border-width: 8px 0 8px 9px;
    left: auto;
    right: -9px;
  }
  .timeline.centered .tl-entry.left .tl-body:after {
    border-width: 7px 0 7px 8px;
    border-color: transparent transparent transparent #fff;
    left: auto;
    right: -8px;
  }
  .timeline.centered .tl-entry.left .tl-time {
    left: 100%;
    right: auto;
    margin-left: 32px;
    margin-right: 0;
  }
  .timeline.centered .tl-entry.left .tl-icon {
    left: auto;
    right: -20px;
  }
}
.page-mail {
  background: #fff !important;
}
.page-mail #content-wrapper {
  padding: 0;
}
.page-mail .mail-nav {
  border: 0px solid;
  border-bottom-width: 1px;
}
.page-mail .mail-nav .navigation {
  overflow: hidden;
  position: relative;
  padding-top: 46px;
  height: 46px;
}
.page-mail .mail-nav .navigation.open {
  height: auto;
}
.page-mail .mail-nav .navigation.open li.active:after {
  -ms-transform: rotate(90deg);
  /* IE 9 */
  -webkit-transform: rotate(90deg);
  /* Chrome, Safari, Opera */
  transform: rotate(90deg);
}
.page-mail .mail-nav .sections {
  margin: 10px 0;
  padding: 0;
}
.page-mail .mail-nav .sections li {
  list-style: none;
  margin: 0;
  padding: 0;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}
.page-mail .mail-nav .sections li.active {
  position: absolute;
  top: 0;
  width: 100%;
  border-bottom: 1px solid;
}
.page-mail .mail-nav .sections li.active a {
  line-height: 46px;
  font-size: 15px;
}
.page-mail .mail-nav .sections li.active:after {
  content: "\f105";
  font-family: FontAwesome;
  position: absolute;
  display: block;
  right: 16px;
  top: 8px;
  font-size: 20px;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}
.page-mail .mail-nav .sections li.active .label,
.page-mail .mail-nav .sections li.active .badge {
  margin-top: 13px;
  margin-right: 36px;
}
.page-mail .mail-nav .sections .label,
.page-mail .mail-nav .sections .badge {
  margin-top: 8px;
  margin-right: 16px;
}
.page-mail .mail-nav .sections a {
  display: block;
  padding: 0 0 0 16px;
  line-height: 36px;
  font-size: 14px;
}
.page-mail .compose-btn {
  margin-top: 46px;
  border-bottom: 1px solid;
  padding: 16px;
}
.page-mail .mail-select-folder {
  display: none;
}
.page-mail .mail-select-folder.active {
  display: block;
}
.page-mail .m-nav-icon {
  width: 16px;
  margin-right: 10px;
  text-align: center;
  display: inline-block;
}
.page-mail .mail-nav-header {
  margin-top: 25px;
  font-size: 11px;
  text-transform: uppercase;
  padding-left: 16px;
  font-weight: 600;
}
.page-mail .mail-nav-lbl {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 3px;
  margin-right: 10px;
}
.page-mail .mail-nav .add-more a {
  font-size: 12px;
}
.page-mail .mail-nav li.divider {
  padding: 0;
  height: 1px;
  margin: 9px 0 3px 0;
}
.page-mail .mail-controls {
  border-bottom: 1px solid #efefef;
  padding: 12px 16px;
}
.page-mail .mail-controls .btn-group + .btn-group {
  margin-left: 12px;
}
.page-mail .mail-controls .btn {
  font-size: 14px;
  line-height: 14px;
}
.page-mail .mail-controls .btn .fa-caret-down {
  position: relative;
  top: -1px;
}
.page-mail .mail-controls .pages {
  line-height: 30px;
  margin-right: 13px;
  color: #bbb;
}
@media (min-width: 992px) {
  .page-mail .mail-controls .wide-btns .btn {
    width: 60px;
    text-align: center;
    display: inline-block;
  }
}
.page-mail .mail-container-header {
  display: none;
  border-bottom: 1px solid #efefef;
  line-height: 35px;
  padding: 14px 16px;
  font-size: 20px;
  font-weight: 300;
}
.page-mail .mail-container-header.show {
  display: block;
}
@media (min-width: 992px) {
  .page-mail #content-wrapper {
    position: static;
  }
  .page-mail .mail-nav {
    width: 200px;
    border-bottom: 0;
    position: absolute;
    height: auto;
    min-height: 100%;
    border-right-width: 1px;
  }
  .page-mail .mail-nav.fixed {
    position: fixed;
  }
  .page-mail .mail-nav.fixed:before {
    display: none !important;
  }
  .page-mail .mail-nav:before {
    content: "";
    display: block;
    top: 0;
    bottom: 0;
    position: fixed;
    width: 200px;
    border-right: 1px solid;
    z-index: -1;
  }
  .page-mail .mail-nav .navigation {
    height: auto !important;
    padding-top: 0;
  }
  .page-mail .mail-nav .navigation .label {
    font-size: 10px;
    line-height: 16px;
    padding: 0 4px;
    margin-top: 11px !important;
    margin-right: 16px !important;
  }
  .page-mail .mail-nav .navigation li:after {
    display: none !important;
  }
  .page-mail .mail-nav .sections li.active {
    position: static;
    top: auto;
    border: none;
  }
  .page-mail .mail-nav .sections a,
  .page-mail .mail-nav .sections li.active a {
    font-size: 13px;
    line-height: 36px;
  }
  .page-mail .mail-select-folder {
    display: none !important;
  }
  .page-mail .mail-container {
    margin-left: 200px;
    min-height: 400px;
  }
  .page-mail .mail-container-header {
    display: block;
    margin-top: 46px;
  }
}
.page-mail .mail-list {
  margin: 16px;
  padding: 0;
}
.page-mail .mail-item {
  margin: 0;
  list-style: none;
  position: relative;
  padding: 12px 14px 12px 46px;
}
.page-mail .mail-item:nth-child(2n-1) {
  background: #f6f6f6;
}
.page-mail .m-chck,
.page-mail .m-star {
  position: absolute;
}
.page-mail .m-chck {
  left: 15px;
  top: 14px;
}
.page-mail .m-star {
  left: 15px;
  top: 35px;
}
.page-mail .m-star a {
  font-size: 15px;
  color: #bbb;
}
.page-mail .m-star a:before {
  content: "\f006";
  font-family: FontAwesome;
}
.page-mail .m-star a:hover {
  color: #ffab00;
}
.page-mail .m-from,
.page-mail .m-subject {
  margin-right: 80px;
}
.page-mail .m-from a {
  color: #444;
}
.page-mail .m-subject {
  margin-top: 5px;
}
.page-mail .m-subject a {
  color: #777;
}
.page-mail .m-date {
  position: absolute;
  right: 15px;
  top: 12px;
  width: 80px;
  text-align: right;
  color: #aaa;
}
.page-mail .mail-item.starred .m-star a:before {
  content: "\f005";
  color: #ffab00;
}
.page-mail .unread .m-from,
.page-mail .unread .m-subject {
  font-weight: 700;
}
@media (min-width: 992px) {
  .page-mail .mail-item {
    padding-left: 220px;
    padding-right: 100px;
  }
  .page-mail .m-star {
    left: 40px;
    top: 11px;
  }
  .page-mail .m-from {
    position: absolute;
    overflow: hidden;
    width: 140px;
    left: 70px;
  }
  .page-mail .m-subject {
    margin-top: 0;
    overflow: hidden;
  }
  .page-mail .m-date {
    width: 150px;
  }
}
.page-mail .m-details-star {
  color: #ffab00;
  margin-right: 12px;
  position: relative;
  display: block;
  float: left;
  top: 1px;
}
.page-mail .m-details-star,
.page-mail .m-details-star > * {
  font-size: 15px;
}
.page-mail .m-details-star > * {
  line-height: 20px;
}
.page-mail .label {
  position: relative;
  vertical-align: middle;
  top: -1px;
}
.page-mail .mail-info {
  padding: 14px 16px;
  border-bottom: 1px solid #efefef;
}
.page-mail .mail-info:before,
.page-mail .mail-info:after {
  content: " ";
  display: table;
}
.page-mail .mail-info:after {
  clear: both;
}
.page-mail .mail-info .avatar {
  display: block;
  width: 40px;
  height: 40px;
  border-radius: 999999px;
  float: left;
}
.page-mail .mail-info .from {
  display: block;
  float: left;
  margin-left: 10px;
}
.page-mail .mail-info .name,
.page-mail .mail-info .name a {
  color: #555;
  font-size: 14px;
}
.page-mail .mail-info .email,
.page-mail .mail-info .email a {
  color: #aaa;
}
.page-mail .mail-info .date {
  display: block;
  float: right;
  font-size: 14px;
  color: #aaa;
  margin-top: 9px;
}
.page-mail .mail-message-body {
  font-size: 14px;
  line-height: 24px;
  padding: 20px 16px;
}
.page-mail .mail-attachments {
  padding: 20px 16px;
  margin: 20px 0;
  border-top: 1px solid #efefef;
  border-bottom: 1px solid #efefef;
}
.page-mail .mail-attachments li {
  list-style: none;
  margin: 0;
  padding: 10px;
}
.page-mail .mail-attachments li:nth-child(2n-1) {
  background: #f6f6f6;
}
.page-mail .message-details-reply {
  margin: 0 16px 50px 16px;
}
.page-mail .new-mail-form {
  margin: 0 0 50px 0;
  padding: 20px 16px;
}
.page-faq .accordion-toggle {
  color: #555;
}
.page-faq .form-faq {
  margin-top: 18px;
}
.page-profile .profile-full-name {
  font-size: 20px;
  font-weight: 300;
  line-height: 40px;
  padding-top: 15px;
  padding-bottom: 20px;
  position: relative;
  border-bottom-width: 2px;
  margin-bottom: 0;
  text-align: center;
}
.page-profile .profile-content .tab-content {
  background: #fff;
}
.page-profile .profile-block {
  margin-bottom: 18px;
  text-align: center;
}
.page-profile .profile-photo {
  padding: 6px;
  display: inline-block;
  margin-bottom: 10px;
  border-radius: 999999px;
}
.page-profile .profile-photo img {
  display: block;
  max-width: 100%;
  border-radius: 999999px;
}
.page-profile .left-col > .panel .panel-heading {
  padding-left: 10px;
  padding-right: 10px;
}
.page-profile .left-col > .panel .panel-body {
  padding: 10px;
  padding-bottom: 0;
}
.page-profile .left-col .list-group-item {
  background: none !important;
  border: none;
  padding-bottom: 0;
  margin-top: 7px;
  padding-left: 10px;
  padding-right: 10px;
}
.page-profile .profile-skills .label {
  display: block;
  float: left;
  margin-right: 4px;
  margin-bottom: 4px;
}
.page-profile .profile-list-icon {
  width: 24px;
  text-align: center;
  font-size: 14px;
}
.page-profile .tl-header,
.page-profile .tl-icon,
.page-profile .tl-entry:before {
  box-shadow: 0 0 0 4px #fff !important;
}
.page-profile .widget-followers {
  margin: -20px;
}
.page-profile .widget-followers .follower {
  padding: 13px 20px;
}
.page-profile .profile-content-hr {
  margin-top: 30px;
  margin-bottom: 30px;
}
@media (min-width: 768px) {
  .page-profile .left-col {
    float: left;
    width: 220px;
  }
  .page-profile .right-col {
    overflow: hidden;
    padding-left: 20px;
  }
  .page-profile .profile-content {
    margin-top: 0;
  }
  .page-profile .profile-full-name {
    padding-left: 240px;
    padding-bottom: 50px;
    text-align: left;
  }
  .page-profile .profile-row {
    margin-top: -36px;
  }
  .page-profile .profile-block {
    margin-top: -70px;
  }
  .profile-content-hr {
    display: none;
  }
}
