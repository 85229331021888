//
// Themes / Purple Hills / Main menu
// --------------------------------------------------
@main-menu-link: #b0b8be;
@main-menu-link-active: #fff;
@main-menu-link-hover: #fff;

#main-menu-bg {
  background-color: @brand-dark;
}

#main-menu {
  padding-top: 30px;
  .color-variant(@brand-dark, 5%, 10%, 5%, 10%);
  .font-variant(@brand-dark);

  .menu-logo {
    background: darken(@primary-color, 5%);
    padding: 10px 0;
  }

  .navigation a,
  .mmc-dropdown-open-ul a {
    font-family: @font-family-heading;
    color: @main-menu-link;
    position: relative;
    .transition(background-color .2s ease-in-out 0s);

    &:after {
      content: "";
      top: 0;
      bottom: 0;
      width: 1px;
      position: absolute;
      right: auto;
      background: @primary-color;
      display: none;
      left: 0;
    }

    &:hover {
      color: @main-menu-link-hover;

      &:after {
        display: block !important;
      }
    }
  }

  .navigation {
    .box-shadow(0 1px 0 @brand-dark);
    border: none;

    li {
      border-bottom: 1px solid darken(@brand-dark, 5%);
      border-top: 1px solid lighten(@brand-dark, 3%);

      ul > li {
        border-top: 1px solid darken(@brand-dark, 2%);
        border-bottom: none;
      }

      a {
        &:hover {
          background: none;
        }
      }

      // Active nav item
      &.active {
        background: none !important;

        & > a {
          color: @main-menu-link-active;
          border: none;

          &:after {
            display: block;
          }
        }
        & > a,
        & > a:hover {
          color: @main-menu-link-active !important;
        }
      }
    }
  }

  .mm-dropdown.active {
    & > a {
      background: none;
    }

    & > a:after {
      display: none;
    }
  }

  .mm-dropdown > ul,
  .mmc-dropdown-open-ul {
    background: lighten(@brand-dark, 3%);
  }

  .mmc-dropdown-open-ul .mmc-title {
    background: darken(@brand-dark, 5%);
    color: @main-menu-link-active;
  }

  .mmc-dropdown-open-ul li {
    border-bottom: 1px solid darken(@brand-dark, 2%);
  }

  .mmc-dropdown-open {
    > a {
      background: darken(@brand-dark, 5%) !important;

      &:hover:after {
        display: none !important;
      }
    }
  }

  // Main menu content blocks
  .menu-content {
    border-color: lighten(@brand-dark, 5%);

    .btn-outline {
      border-color: lighten(@brand-dark, 5%);
      color: lighten(@brand-dark, 40%);

      &:hover {
        color: @main-menu-link-hover;
      }
    }
  }
}

// Dropdown highlights
//

// Highlight opened dropdown
.mmc-dropdown-open .mm-dropdown.open > a,
&.mme #main-menu .mm-dropdown.open > a {
  color: @main-menu-link-active;
}

@media (min-width: @screen-tablet) {
  // Highlight expanded submenus
  #main-menu .mm-dropdown.open > a {
    color: @main-menu-link;
  }

  // Unhighlight expanded submenus in collapsed mode
  &.mmc #main-menu .navigation > .mm-dropdown.open > a {
    color: lighten(@brand-dark, 40%);
  }
}

&.right-to-left {
  #main-menu .navigation a:after {
    left: auto;
    right: 0;
  }
}