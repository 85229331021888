//
// Themes / Purple Hills / Base
// --------------------------------------------------

& {
  background: @body-color;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 13px;
  color: #6a6c6f;
  margin: 0;
  padding: 0;
  height: 100%
}

#flash-message {
  margin: 0;
  padding: 0;
  .alert.alert-page {
    margin: -18px -18px;
    display: block;
    height: 72px;
  }
}

// Panels
//
#content-wrapper {
  min-height: 100vh;
  padding-top: 18px;

  &.light {
    background: #FFF;
  }

}

.page-header {
  padding: 10px 20px 10px;
  margin-bottom: 0;
  display: block;

  h1 {
    font-size: 16px;
  }
}

.page-navs {
  .nav-tabs {
    display: block;
    margin: 0 -18px;
    padding: 0 20px;
    background: #fff;
    border-top: none;
    border-bottom: 1px solid @page-header-border-color;

    > li {
      margin-right: 20px;
      min-height: 40px;
    }

    > li.table-action {
      margin: 0;
      padding: 0;
      padding-top: 8px;
      float: right;
    }

    > li > a {
      text-transform: uppercase;
      font-size: 11px;
      font-weight: 600;
      padding: 0;
      line-height: 40px;
      letter-spacing: 1.2px;
      border-bottom-color: transparent;
    }

    > li.active > a {
      font-weight: 800;
      border-bottom-color: transparent;

      &:hover {
        border-bottom-color: transparent;
      }
    }
  }
}

.page-body {
  &.no-padding {
    margin: 0 -18px 18px -18px;
  }
}

.page-tables {
  margin: 0 -18px 18px -18px;

}

// Change border-color

.table,
.panel,
hr {
  border-color: @theme-border-color;
}

.panel {
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
}

.panel-heading {
  .pixel-gradient(#f4f4f4, #efefef, f7f7f7);
  border-bottom: 1px solid @theme-border-color;
}

.panel-footer {
  border-color: @theme-border-color;
}

.bordered {
  border-color: @theme-border-color;
}

// Fix coloured borders

.bordered[class*='bg-'],
[class*='bg-'] .border-t {
  border-color: #fff;
  border-color: rgba(0, 0, 0, .2);
}

.panel.panel-transparent > .panel-heading {
  border-bottom-color: @theme-border-color !important;
}

// Page-wide breadcrimb
.breadcrumb-page {
  background: @page-header-color;
  border-bottom: 1px solid @page-header-border-color;
  padding-bottom: 6px;
  padding-top: 6px;
}

@media (max-width: @screen-small) {
  .page-header {
    padding-top: 20px;
    border-top-width: 1px;
  }
}

@media (min-width: @screen-small) and (max-width: @screen-tablet) {
  .page-header {
    padding-top: 20px;
    border-top-width: 1px;
  }
}
