.dropdown-menu {
  overflow: hidden;
  border-radius: @border-radius-base;
  .box-shadow(0 2px 6px rgba(0, 0, 0, 0.1));
  border: 1px solid @border-color;
  border: 1px solid rgba(0, 0, 0, 0.1);
  &.pull-left {
    left: 100%;
  }

  > .panel {
    border: none;
    margin: -5px 0;
  }
  > li > a {
    padding: 5px 15px;
  }
  > li > a:hover,
  > li > a:focus,
  > .active > a,
  > .active > a:hover,
  > .active > a:focus {
    background-image: none;
    filter: none;
    background-color: @brand-light !important;
    color: @link-hover-color;
  }
}

.dropdown-header {
  padding: 5px 15px;
}

.dropdown-submenu {
  position: relative;
  &:hover,
  &:focus {
    > a {
      background-color: @brand-light !important;
      color: @text-color;
    }
    > .dropdown-menu {
      display: block;
    }
  }
  &.pull-left {
    float: none !important;
    > .dropdown-menu {
      left: -100%;
      margin-left: 10px;
    }
  }
  .dropdown-menu {
    left: 100%;
    top: 0;
    margin-top: -6px;
    margin-left: -1px
  }

  .dropup & {
    > .dropdown-menu {
      top: auto;
      bottom: 0;
    }
  }
}

.btn-group > .btn {
  margin-left: -1px;
}