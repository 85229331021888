//
// Pages / Plans and Pricing
//
// --------------------------------------------------


// Mobile first
//

.page-pricing {

  // Page heading
  //

  .page-pricing-header {
    margin: -@grid-gutter-width -@grid-gutter-width @grid-gutter-width -@grid-gutter-width;
    overflow: hidden;
    position: relative;
    text-align: center;

    // Background image
    img {
      left: 0;
      position: absolute;
      width: 100%;
    }

    // Background overlay
    .bg-overlay {
      opacity: .5;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }

    // Heading text
    .page-pricing-header-content {
      position: relative;

      h1.page-title {
        padding: 40px 0 0 0;
        color: #fff;
        margin: 0 0 -40px 0;
      }

      .slogan {
        padding: 60px 0 40px 0;
        color: #fff;

        h3,
        h4 {
          padding: 0;
          margin: 0;
        }

        h3 {
          line-height: 30px;
          font-weight: 600;
        }

        h4 {
          padding-top: 10px;
          font-weight: 300;
        }
      }
    }
  }


  // Plans and pricing
  //

  .plans-panel {
    margin: 0 auto;
  }

  .plans-container {
    position: relative;
    margin: -1px;
    .clearfix();
  }

  // Plan column
  .plan-col {
    text-align: center;
    padding: 0;
    position: relative;
    margin-bottom: @grid-gutter-width;
  }


  // Plan header
  //

  .plan-header {
    font-size: 15px;
    font-weight: 700;
    overflow: hidden;
    white-space: nowrap;
    line-height: 50px;
    white-space: nowrap;
    position: relative;
  }


  // Plan pricing
  //

  .plan-pricing {
    white-space: nowrap;
    padding: 20px 0;
  }

  .plan-currency {
    font-size: 24px;
    font-weight: 300;
  }

  .plan-value {
    font-size: 44px;
    font-weight: 300;
  }

  .plan-period {
    font-size: 18px;
    display: inline-block;
    margin-left: 5px;
    font-weight: 300;
  }


  // Plan features
  //

  .plan-features {
    padding: 0;
    margin: 0;
    border-top: none;

    > li,
    > a {
      margin: 0;
      padding: 16px 0;
      font-size: 14px;
      font-weight: 300;
      display: block;
    }

    > li {
      background: #fff;
      border: 1px solid @table-border-color;
      border-top: 0;
    }

    > a {
      margin-top: -1px;
      font-size: 13px;
      .transition(all .2s);
    }
  }

  
  // Trial text
  //

  .trial {
    padding: 20px 0;
    text-align: center;
    font-size: 14px;
    color: #888;
    line-height: 23px;

      a {
      font-size: 15px;
    }
  }
}

.ie8 .page-pricing .page-pricing-header .bg-overlay {
  display: none !important;
}


// Desktops
//

@media (min-width: @screen-tablet) {
  .page-pricing {
    .plans-panel {
      background: #fafafa;
      border: 1px solid @table-border-color;
      margin-bottom: @grid-gutter-width;
    }

    .plan-col {
      margin-bottom: 0;
    }

    .plan-col + .plan-col .plan-features {
      > li {
        border-left-width: 0;
        .box-shadow(-1px 0 0 @table-border-color);
      }

      > a {
        margin-left: -1px;
      }
    }
  }
}