//
// Themes / Purple Hills / Main navbar
// --------------------------------------------------


// ************************************************************************* //
//**   Small screens (mobile first)                                        **//

#main-navbar {
  border-top: 4px solid #333;
  border-bottom: 1px solid #023a65;

  .navbar-header {
    background: @navbar-color;

    a {
      color: #fff;
    }
  }

  // Navbar header
  //

  .navbar-brand {
    color: #fff;
  }


  // Navbar buttons
  //

  .navbar-toggle,
  #main-menu-toggle {
    color: #fff;
  }

  #main-menu-toggle {
    background: @navbar-color;

    .fa:before {
      color: #fff;
    }
  }


  // Collapse
  //

  .navbar-collapse {
    border-top: 1px solid lighten(@navbar-color, 6%);
    background: @navbar-color;
    .box-shadow(none);
  }


  // Navbar Items
  //

  .dropdown > a,
  .dropdown-menu > li > a,
  .nav > li > a {
    color: lighten(@navbar-color, 50%);

    &:hover {
      color: #FFF;
    }
  }



  li + li,
  .nav + .nav,
  .nav + .right {
    border-top: 1px solid lighten(@navbar-color, 6%);
  }


  // Dropdowns
  //

  .dropdown.open > a {
    background: lighten(@navbar-color, 6%);
    border-bottom: 1px solid lighten(@navbar-color, 10%);
    border-top: 1px solid lighten(@navbar-color, 15%);
    margin-top: -1px;
    color: #fff;
  }


  // Navbar forms
  //

  form.navbar-form {
    .box-shadow(none);

    .form-control {
      background: #fff;
      background: rgba(255, 255, 255, 0.05);
      border: none;
      color: @input-color-placeholder;

      &:focus {
        background: #fff;
        color: @text-color;
        .box-shadow(none);
      }
    }
  }
}


// ************************************************************************* //
//**   Desktops                                                            **//

@media (min-width: @screen-tablet) {
  #main-navbar {

    // Navbar button
    #main-menu-toggle {
      background: darken(@header-color, 5%);
    }

    // Navbar header
    //

    .navbar-header {
      background: @header-color;
    }

    // Navbar collapse
    //

    .navbar-collapse {
      background: @navbar-color;
      border: none;

      > div > .navbar-nav {
        border-left: 1px solid darken(@navbar-color, 4%);
        .box-shadow(1px 0 0 rgba(255, 255, 255, .03) inset);
      }
    }

    .right > .navbar-nav > li {
      border-left: 1px solid darken(@navbar-color, 4%);
      border-right: none;
      .box-shadow(1px 0 0 rgba(255, 255, 255, .03) inset);
    }

    .navbar-nav > li {
      border-right: 1px solid darken(@navbar-color, 4%);
      .box-shadow(1px 0 0 rgba(255, 255, 255, .03));

      > a {
        color: lighten(@navbar-color, 50%);
      }

      > a:hover,
      &.active > a,
      &.dropdown.open > a {
        border-bottom: 3px solid @header-color;
        color: #fff;
      }
    }

    
    // Navbar Items
    //

    li + li,
    .nav + .nav,
    .nav + .right {
      border-top: none;
    }


    // Navbar dropdowns
    //

    .dropdown.open > a {
      margin: 0;
      border-top: none;
    }

    .dropdown-menu {
      background: @navbar-color;
      border: none;
      border-top: 1px solid @header-color;
      padding: 5px 0;

      .divider {
        background-color: darken(@navbar-color, 4%);
      }

      & > li > a {
        color: #fff;

        &:hover {
          background: lighten(@navbar-color, 4%);
        }
      }
    }


    // Notifications widget
    //

    .widget-notifications {
      a:hover {
        color: #fff !important;
      }

      .notification,
      .notifications-link {
        border-color: darken(@navbar-color, 4%);
        .box-shadow(0 1px 0 rgba(255, 255, 255, 0.05) inset);
        .border-bottom-radius(3px);
      }

      .notification-description {
        color: #888;
      }

      .notification-ago {
        color: #666;
      }

      .notifications-link:hover {
        background: lighten(@navbar-color, 6%);
      }
    }


    // Alt messages widget
    //

    .widget-messages-alt {
      a:hover {
        color: #fff !important;
      }

      .message,
      .messages-link {
        border-color: darken(@navbar-color, 4%);
        .box-shadow(0 1px 0 rgba(255, 255, 255, 0.05) inset);
        .border-bottom-radius(3px);
      }

      .message-subject {
        color: #bbb;
      }

      .message-description {
        color: #666;

        a {
          color: #888;
        }
      }

      .messages-link:hover {
        background: lighten(@navbar-color, 6%);
      }
    }
  }

  &.main-menu-right #main-navbar .navbar-header,
  &.mmc #main-navbar .navbar-header,
  &.no-main-menu #main-navbar .navbar-header {
    background: @navbar-color;
  }

  &.main-menu-right #main-navbar .navbar-header {
    border-right: 1px solid darken(@navbar-color, 4%);
  }
}



// RTL
// --------------------------------------------------

@media (min-width: @screen-tablet) {
  &.right-to-left #main-navbar {    
    .right > .navbar-nav > li {
      border-right: 1px solid darken(@navbar-color, 4%);
      border-left: none;
      .box-shadow(1px 0 0 rgba(255, 255, 255, .03));
    }
  }

  &.right-to-left.mmc #main-navbar .navbar-header {
    .box-shadow(1px 0 0 rgba(255, 255, 255, .03) inset);
  }
}


