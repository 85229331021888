//
// Pages / Search results
//
// --------------------------------------------------


// Mobile first
//

.page-search {

  // Text
  //

  .search-text {
    color: #888;
    font-size: 15px;
    font-weight: 300;
    line-height: 36px;
    margin-bottom: @grid-gutter-width;
  }
  
  // Tabs
  //

  .search-tabs {
    margin-bottom: -2px;
    .clearfix();
  }

  // Form
  //

  .search-form {
    background: @panel-heading-bg;
    margin: -1px;
    padding: 16px @panel-content-padding;
    position: relative;

    .input-group-addon {
      border-bottom-width: 0;
      border-left-width: 0;
      border-top-width: 0;
    }

    input[type="text"] {
      border-bottom-width: 0;
      border-top-width: 0;
    }

    button {
      border-bottom-width: 0;
      border-right-width: 0;
      border-top-width: 0;
    }
  }

  // Classic search
  //

  .search-classic {
    margin: -@panel-content-padding;
    padding: 0;

    li {
      list-style: none;
      margin: 0;
      padding: @panel-content-padding;

      > * + * {
        margin-top: 10px;
      }
    }

    li + li {
      border-top: 1px solid @table-border-color;
    }

    .search-title,
    .search-tags,
    .search-content,
    .search-url {
      display: block;
    }

    .search-title {
      font-size: 15px;
      font-weight: 600;
    }

    .search-tags {
      .clearfix();

      span,
      a {
        display: block;
        float: left;
        line-height: 17px;
      }

      .search-tags-text {
        color: #aaa;
        font-size: 10px;
        font-weight: 600;
        margin-right: 10px;
        text-transform: uppercase;
      }

      .label,
      .badge {
        font-size: 10px;

        & + .label,
        & + .badge {
          margin-left: 5px;
        }
      }
    }

    .search-url {
      color: #3a8b54;
      font-size: 11px;
      margin-top: 4px;
    }
  }

  // Users search
  //

  .search-users {
    margin: -@panel-content-padding;

    table {
      margin: 0;

      th,
      td {
        padding-bottom: 14px;
        padding-top: 14px;
        vertical-align: middle;
      }
    }

    .avatar {
      border-radius: 999999px;
      height: 30px;
      width: 30px;
    }
  }

  // Messages search
  //

  .search-messages {
    margin-bottom: -@panel-content-padding;
    margin-top: -@panel-content-padding;

    .message {
      padding-bottom: 12px;
      padding-top: 12px;

      .date {
        top: 12px;
      }
    }
  }
}
