// Tables
//
.table {
  // Cells
  thead,
  tbody,
  tfoot {
    > tr > th,
    > tr > td {
      color: @text-color;

      a {
        color: @text-color;
        font-weight: 600;

        &:hover {
          text-decoration: underline;
        }
      }

      .btn-group {

        ul.dropdown-menu {
          a {
            font-weight: normal !important;
            &:hover {
              text-decoration: none !important;
            }

            i {
              margin-right: 5px;
            }
          }
        }
      }

    }

    > tr > td {
      border-top-color: @table-border-color;
      padding-top: 10px;
      padding-bottom: 10px;
      vertical-align: middle;

      &:first-child {
        padding-left: 20px;
      }

      &:last-child {
        padding-right: 20px;
      }
    }

    > tr > th {
      font-weight: 600;
      background: #f6f8f8;
      color: @text-color;
      font-size: 11px;
      line-height: 20px;
      text-shadow: 0 2px 2px #ffffff;
      border-bottom: none;

      &.clean {
        font-weight: 600;
        .pixel-gradient(#f4f4f4, #efefef, #f7f7f7);
        color: #9b9b9b;
        font-size: 11px;
        line-height: 20px;
      }
    }

    .px-single {
      vertical-align: middle;
      display: block;
      padding-top: 2px;
    }
  }

  // Account for multiple tbody instances
  tbody + tbody {
    border: 1px solid @table-border-color;
  }

  thead {
    > tr > th {
      box-shadow: 0 2px 2px rgba(0, 0, 0, 0.05), 0 1px 0 rgba(0, 0, 0, 0.05);
    }
  }
}

.table-hover {
  > tbody > tr {
    > td,
    > th {
      background: transparent;
      transition-property: background;
      transition-duration: 0.2s;
      transition-timing-function: linear;
    }
  }

  > tbody > tr:hover {
    > td,
    > th {
      background: #f6f8f8;
    }
  }
}

.table-bordered {
  border-color: @table-border-color;
  border-top: none;

  > thead,
  > tbody,
  > tfoot {
    > tr > th,
    > tr > td {
      border-color: @table-border-color;
    }

    > tr > th {
      border-top: none;
      border-bottom: 1px solid @table-border-color;
    }

    > tr > th:first-child,
    > tr > td:first-child {
      border-left: none;
    }

    > tr > td:first-child {
      padding-left: 10px;
    }

    > tr > th:last-child,
    > tr > td:last-child {
      border-right: none;
    }

    > tr:last-child {
      border-bottom: none;
    }
    > tr:last-child > td {
      border-bottom: none;
    }
  }

  > tfoot > th, > tfoot > td, > tfoot > tr {
    border: none;
    padding: 0;
  }
}

.table.table-input {
  border-top: 1px solid #c4c4c4;
  background: #FFF;

  > thead,
  > tbody,
  > tfoot {
    > tr > th,
    > tr > td {
      border: 1px solid #c4c4c4;
    }

  }

  thead, tfoot {
    tr {
      th {
        background-color: #f7f7f7;
        background-image: linear-gradient(#f7f7f7, #eee);
        color: #9b9b9b;
        font-size: 11px;
        line-height: 20px;
        font-weight: normal;
        padding-left: 20px;
        border-left: none;
        border-right: none;
        box-shadow: none;
      }
    }
  }

  tfoot {
    tr {
      th {
        background: #f7f7f7;
      }
    }
  }

  tbody {
    tr {
      td.opacity {
        opacity: 0.9;
      }
      td {
        background: #fff;
        padding: 0;
        height: 40px;
        vertical-align: middle;

        &.has-error {
          border-color: @danger-color !important;
          color: @danger-color !important;
          position: relative;

          p.help-block {
            position: absolute;
            top: 40px;
            z-index: 23;
          }
        }

        span {
          padding-left: 20px;
        }

        input, .form-control {
          background: none !important;
          height: 40px;
          padding: 0;
          padding-left: 20px !important;
          border: none;
        }

        input:-moz-placeholder,
        input::-webkit-input-placeholder {
          color: #c4c4c4;
        }

        > a {
          margin: 0 !important;
          padding: 0 !important;
          max-height: 40px;
          line-height: 20px;
          display: inline-block;
          vertical-align: middle;
          overflow: hidden;

          i {
            display: block;
            vertical-align: middle;
            margin-top: -5px;
          }

          &.btn {
            margin: 0 !important;
            padding: 0 !important;
            max-height: 40px !important;
            display: block !important;
            vertical-align: middle !important;
            overflow: hidden;

            i {
              display: block !important;
              vertical-align: middle !important;
              padding-top: 19px !important;
            }
          }
        }

        .btn-group {

          ul.dropdown-menu {
            a {
              font-weight: normal !important;
              &:hover {
                text-decoration: none !important;
              }
            }
          }
        }

        .input-notes {
          position: relative;
          width: 100%;
          display: block;
          height: auto;
          padding: 0 18px;

          a {
            position: absolute;
            top: -15px;
            right: 5px;
          }

          &.in {
            position: relative !important;
            top: 0;
          }

          input.input-xs {
            height: 23px !important;
            line-height: 23px;
            margin: 0 0 5px 0 !important;
            padding: 0 !important;
            width: 80%;
            border: 1px solid #fff;
            font-size: 11px !important;
            color: #c0c0c0;
            font-style: italic;

            &:focus {
              border: 1px solid @brand-info;
            }
          }
        }

        > .btn {
          border: none;
          display: block;
          min-height: 40px;
          vertical-align: middle;
          text-align: center;
          margin: 0;
          padding: 0;
          width: 100%;
          height: 100%;

          &.bordered {
            border-left: 1px solid #c4c4c4;
          }
        }
      }
    }
  }

}

.table.loading {
  position: relative;

  tbody > tr > td {
    opacity: 0.5;
  }

}

// NG-tables
//
.ng-table th {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.ng-table th.sortable {
  cursor: pointer;
  text-align: left;
}

.ng-table th.sortable div {
  padding-right: 18px;
  position: relative;
}

.ng-table th.sortable div:after,
.ng-table th.sortable div:before {
  content: "";
  border-width: 0 4px 4px;
  border-style: solid;
  border-color: #000 transparent;
  visibility: visible;
  right: 8px;
  top: 50%;
  position: absolute;
  opacity: .3;
  margin-top: -4px;
}

.ng-table th.sortable div:before {
  margin-top: 2px;
  border-bottom: none;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid #000;
}

.ng-table th.sortable div:hover:after,
.ng-table th.sortable div:hover:before {
  opacity: 1;
  visibility: visible;
}

.ng-table th.sortable.sort-desc,
.ng-table th.sortable.sort-asc {
  background-color: #edf1f2;
  text-shadow: 0 1px 1px rgba(255, 255, 255, 0.75);
}

.ng-table th.sortable.sort-desc div:after,
.ng-table th.sortable.sort-asc div:after {
  margin-top: -2px;
}

.ng-table th.sortable.sort-desc div:before,
.ng-table th.sortable.sort-asc div:before {
  visibility: hidden;
}

.ng-table th.sortable.sort-asc div:after,
.ng-table th.sortable.sort-asc div:hover:after {
  visibility: visible;
  filter: alpha(opacity=60);
  -khtml-opacity: 0.6;
  -moz-opacity: 0.6;
  opacity: 0.6;
}

.ng-table th.sortable.sort-desc div:after {
  border-bottom: none;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid #000;
  visibility: visible;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  filter: alpha(opacity=60);
  -khtml-opacity: 0.6;
  -moz-opacity: 0.6;
  opacity: 0.6;
}
