//
// Themes / Purple Hills
// --------------------------------------------------

.theme-konektifa {

  // Imports
  //
  @import './_mixin.less';
  @import './_variables.less';
  @import './colors.less';
  @import './layout.less';
  @import './layout_sidebar.less';
  @import './content.less';
  @import './panel.less';
  @import './table.less';
  @import './form.less';
  @import './utils.less';
  @import './pages.less';
  @import './dropdown.less';
  @import './popovers.less';

  //@import './main-navbar.less';
  //@import './main-menu.less';
  //@import './demo.less';
  //@import './pages.less';
  //@import './tables.less';
  //@import './content.less';
  //@import './utils.less';
  //@import './form.less';

  // Styles
  // see {PixelAdmin}/styles/pixel-admin-less/mixins.less
  //
  // Navbar icon buttons
  .nav-icon-btn-theme(@primary-color);

  // Buttons
  .buttons-theme(@primary-color);

  // Progress bars
  .progress-bars-theme(@primary-color);

  // Labels
  .labels-theme(@primary-color);

  // Badges
  .badges-theme(@primary-color);

  // Tables
  .tables-theme(@primary-color);

  // Tabs
  .tabs-theme(@primary-color);

  // Pills
  .pills-theme(@primary-color);

  // Dropdowns
  .dropdowns-theme(@primary-color, @link-color);

  // Button groups
  .button-groups-theme(@primary-color);

  // Pagination
  .pagination-theme(@primary-color);

  // List groups
  .list-groups-theme(@primary-color);

  // Forms theme
  .forms-theme(@primary-color);

  // Text color
  .text-primary {
    .pixel-text-color(@primary-color);
  }

  // Background color
  .bg-primary {
    .pixel-bg-color(@primary-color);
  }

  // Plugins
  //
  // jQuery UI
  .ui-slider-theme(@primary-color);
  .ui-autocomplete-theme(@primary-color, #fff);
  .ui-menu-theme(@primary-color);
  .ui-progressbar-theme(@primary-color);
  .ui-tabs-theme(@primary-color);
  .ui-datepicker-theme(@primary-color);

  // Plugins
  .select2-theme(@primary-color);
  .switcher-theme(@primary-color);
  .pixel-file-input-theme(@primary-color);
  .bootstrap-datepicker-theme(@primary-color);
  .bootstrap-timepicker-theme(@primary-color);
  .datepaginator-theme(@primary-color);
  .editable-typeahead-theme(@primary-color);
  .data-table-theme(@primary-color);
  .dropzone-theme(@primary-color);
  .md-editor-theme(@primary-color);

  // Widgets
  .widget-rating-theme(@primary-color);
}
