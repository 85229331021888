//
// Widgets / Chat
// --------------------------------------------------

.widget-chat {
  .message + .message {
    padding-top: 15px;
  }

  .message-avatar {
    display: block;
    float: left;
    height: 40px;
    width: 40px;
    border-radius: @border-radius-base;
  }

  .message-body {
    margin-left: 55px;
  }

  .message-heading {
    font-size: 12px;
    color: #aaa;
  }

  .message-text {
    background: #f4f4f4;
    margin-top: 4px;
    padding: 10px 15px 12px 15px;
    border-radius: @border-radius-base;
    position: relative;

    // Arrow
    &:before {
      border-color: transparent #f4f4f4 transparent transparent;
      border-style: solid;
      border-width: 6px 6px 6px 0;
      content: "";
      display: block;
      height: 0px;
      left: -6px;
      position: absolute;
      top: 6px;
      width: 0px;
    }
  }

  .message.right {
    .message-avatar {
      float: right;
    }

    .message-body {
      margin-left: 0;
      margin-right: 55px;
    }

    .message-text:before {
      border-color: transparent transparent transparent #f4f4f4;
      border-width: 6px 0 6px 6px;
      left: auto;
      right: -6px;
    }
  }

  .chat-controls {
    width: 100%;
    position: relative;

    .chat-controls-input {
      margin-right: 90px;
    }

    .chat-controls-btn {
      position: absolute;
      right: @panel-content-padding;
      width: 80px;
      top: 10px;
    }
  }
}

.panel.widget-chat,
.panel .widget-chat {
  .message {
    margin: 0 -@panel-content-padding;
    padding-left: @panel-content-padding;
    padding-right: @panel-content-padding;
  }
}
