
#content-wrapper {
  color: @text-color;
  min-height: 100vh;
  padding-top: 18px;
  background: @body-bg;

  .page-header {
    padding: 12px 20px;
    margin-bottom: 0;
    display: block;
    background: #F6F8F8;
    border-color: @theme-border-color;

    h1 {
      font-size: 16px;
      line-height: 35px;
      color: @text-color !important;
      padding-bottom: 0;
      margin-bottom: 0;
    }
  }

  .page-navs {
    .nav-tabs {
      display: block;
      margin: 0 -18px;
      padding: 0 20px;
      border-top: none;
      border-bottom: 1px solid @theme-border-color;
      background-color: @body-bg;

      > li {
        margin-right: 10px;
        min-height: 40px;
        border-right: 1px solid @theme-border-color;
        padding-right: 10px;
      }

      > li.table-action {
        margin: 0;
        padding: 0;
        padding-top: 8px;
        float: right;
        border-right: none;
      }

      > li > a {
        text-transform: uppercase;
        font-size: 11px;
        font-weight: 600;
        padding: 0;
        line-height: 40px;
        letter-spacing: 1.2px;
        border-bottom-color: transparent;
      }

      > li.active > a {
        font-weight: 800;
        border-bottom-color: transparent;

        &:hover {
          border-bottom-color: transparent;
        }
      }
    }
  }

  .page-body {

  }

  &.light {
    background: #fff;

    .page-body {
      margin: 0 -18px;
    }
  }

  &.dashboard {
    padding-right: 0px;
    display: block;
    position: relative;

    .dashboard-body {

    }

    .dashboard-aside {
      background: #fff;
      padding: 0;
      margin: 0;
      padding-top: 18px;
      height: 100vh;
      display: block;
      border-left: 1px solid @border-color;
    }
  }
}