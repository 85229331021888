//
// Panels
// --------------------------------------------------

.panel,
.panel-default {
  background: @panel-bg;
  border-color: @panel-border;
  border-radius: 0 0 @panel-border-radius @panel-border-radius;
  box-shadow: 0 1px 1px rgba(0, 0, 0, .05);
}

.panel-heading {
  background: @panel-heading-bg;
  border-bottom: 1px solid @panel-heading-border;
}

// Body
.panel-body {
  background: @panel-bg;
}

// Footer
.panel-footer {
  background: @panel-footer-bg;
  border-top: 1px solid @panel-heading-border;
}

.panel-title {
  color: @text-color;
  font-family: @font-family-heading;
}